import { useEffect, useContext, ComponentProps } from 'react'

import { useHistory, useParams } from 'react-router-dom'

import { isSidepanel } from '_pages/sidebar'

import { IconButton } from '_shared/buttons'
import Page from '_shared/Page'

import ManageTagsConfirmDialog from '_core/components/dialogs/ManageTagsConfirm'
import { Narrow } from '_core/components/layout'
import ManageTags, { CategoryTagsPair } from '_core/components/ManageTags'
import Topbar from '_core/components/Topbar'

import useDialog from '_core/hooks/useDialog'
import { RemoteTagsListItem, TaggableType } from '_core/hooks/useLookup'
import useSidepanelClose from '_core/hooks/useSidepanelClose'
import useTagsManager from '_core/hooks/useTagsManager'

import DynamicEntity from '_core/DynamicEntity'
import { getSkeletonSize } from '_core/helpers/skeleton'

import { mergeUrlWithParams } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

const sidepanel = isSidepanel()

const Component = (props: { items: RemoteTagsListItem[]; loading: boolean }) => {
  const { taggableType } = useParams<{ taggableType: keyof typeof TaggableType }>()

  const {
    tags,
    initialTagsMap,
    isDirty,
    isValid,
    isSaving,
    removeCategory,
    editCategory,
    createCategoryTagPair,
    removeTag,
    editTag,
    addTag,
    resetChanges,
    creations,
    removals,
    renames,
    saveTags,
    duplicatedCategories
  } = useTagsManager(props.items, props.loading)
  const history = useHistory()
  const { historyIndex } = useContext(LayoutContext)

  const { dialogContentProps, openDialog, closeDialog } =
    useDialog<Pick<ComponentProps<typeof ManageTagsConfirmDialog>, 'creations' | 'removals' | 'renames'>>()

  const closeSidepanel = useSidepanelClose()

  const handleSummaryDialogOpen = () => {
    openDialog({ creations, removals, renames })
  }

  const handleSubmit = async () => {
    closeDialog()
    await saveTags(taggableType)
    if (!sidepanel || historyIndex > 0) {
      history.goBack()
    } else {
      closeSidepanel()
    }
  }

  return (
    <>
      <Topbar action={<IconButton icon={['far', 'plus']} onClick={createCategoryTagPair} size="small" hint="Add tag" />} nativeBack />
      <ManageTags
        isTagsFormDirty={isDirty}
        isTagsFormValid={isValid}
        onSubmit={handleSummaryDialogOpen}
        handleReset={resetChanges}
        onClose={closeSidepanel}
        createCategory={createCategoryTagPair}
        loading={props.loading || isSaving}
        hasInitTagsSpecified={!!props.items.length}
        showEmpty={Object.keys(tags || {}).length === 0}
      >
        {(tags || getSkeletonSize(1, {})).map(({ categoryKey = '', categoryName = '', tagNames = [] }) => {
          return (
            <CategoryTagsPair
              key={categoryKey}
              categoryKey={categoryKey}
              editTag={editTag}
              isCategoryNew={!initialTagsMap?.[categoryKey]}
              isCategoryDuplicated={duplicatedCategories.includes(categoryName.toLowerCase())}
              categoryName={categoryName}
              tagNames={tagNames}
              disabled={props.loading || isSaving}
              removeCategory={removeCategory}
              editCategory={editCategory}
              addTag={addTag}
              removeTag={removeTag}
            />
          )
        })}
      </ManageTags>
      <ManageTagsConfirmDialog {...(dialogContentProps || {})} submit={handleSubmit} close={closeDialog} />
    </>
  )
}

const TagsPage = () => {
  const { setMobileHeader } = useContext(LayoutContext)
  const { taggableType } = useParams<{ taggableType: keyof typeof TaggableType }>()

  useEffect(() => {
    setMobileHeader('Manage tags')
  }, [setMobileHeader])

  return (
    <Page>
      <Narrow>
        <DynamicEntity
          url={mergeUrlWithParams('/tags/tags', {
            taggableType: TaggableType[taggableType]
          })}
          id="tags"
          component={Component}
          list
          keepMounted
        />
      </Narrow>
    </Page>
  )
}

export default TagsPage
