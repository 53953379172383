import React, { useContext, useEffect, useMemo } from 'react'

import { useParams } from 'react-router-dom'

import Page from '_shared/Page'

import Assignee from '_core/components/introductions/Assignee'
import { Narrow } from '_core/components/layout'
import Topbar from '_core/components/Topbar'

import DynamicEntity from '_core/DynamicEntity'

import { LayoutContext } from 'Layout/LayoutContextProvider'

const RequestAssigneePage = () => {
  const { id } = useParams<{ id: string }>()
  const { setSubHeader } = useContext(LayoutContext)

  useEffect(() => {
    setSubHeader('Assignee')
    return () => setSubHeader('')
  }, [setSubHeader])

  const memoUrls = useMemo(
    () => [
      { key: 'request', url: `/prospecting/plandetail?planUid=${id}` },
      { key: 'managers', url: '/prospecting/managers?role=ProspectingManager' }
    ],
    [id]
  )

  return (
    <Page>
      <Narrow>
        <Topbar nativeBack />
        <DynamicEntity urls={memoUrls} id="request_assignee" component={Assignee} keepMounted />
      </Narrow>
    </Page>
  )
}

export default RequestAssigneePage
