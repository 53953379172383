import { useEffect } from 'react'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import useNavBarMenuItemsShown from '_core/hooks/useNavBarMenuItemsShown'

export type IntroductionVisibility = 'Off' | 'AdminsAndNetworkTeam' | 'AllUsers'
export type IntroductionAccessData = [{ visibility: IntroductionVisibility }, ProfileType, { emailAddress: string }[]]

export const useIntroductionsAccessData = () => {
  const { fetchWithAbort, result, setResult, reset } = useAbortableFetch<IntroductionAccessData>()
  const [userSettings, profile, managers] = result || []

  const checkWhetherVisible = (visibilityLevel: IntroductionVisibility) => {
    if (visibilityLevel === 'Off') {
      return false
    }

    const { IsAdmin, UserKey: userEmail } = profile || {}

    const isNetworkManager = !!managers?.find((manager) => manager.emailAddress === userEmail)
    return visibilityLevel === 'AllUsers' || (visibilityLevel === 'AdminsAndNetworkTeam' && (IsAdmin || isNetworkManager))
  }

  const userAccess = userSettings ? checkWhetherVisible(userSettings.visibility) : userSettings

  const updateUserIntroductionsAccess = (introductionsAccessUpdateData: IntroductionAccessData) => {
    setResult(introductionsAccessUpdateData)
  }

  const checkAccess = async () => {
    reset()
    fetchWithAbort([{ url: '/usersettings/introductions' }, { url: '/me/profile' }, { url: '/prospecting/managers?role=ProspectingManager' }])
  }

  return {
    checkAccess,
    introductionsAccessResult: result,
    isUserIntroductionsVisible: userAccess,
    updateUserIntroductionsAccess,
    resetIntroductionsAccess: reset
  }
}

const useIntroductionsAccess = (refetchDeps: (string | boolean)[] = []) => {
  const { updateUserIntroductionsAccess } = useNavBarMenuItemsShown()
  const { checkAccess, ...introductionAccess } = useIntroductionsAccessData()
  const { introductionsAccessResult, isUserIntroductionsVisible } = introductionAccess

  const loading = typeof isUserIntroductionsVisible === 'undefined'

  useEffect(() => {
    checkAccess()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refetchDeps)

  useEffect(() => {
    if (!loading) {
      if (introductionsAccessResult) {
        updateUserIntroductionsAccess(introductionsAccessResult)
      }

      return () => {
        if (introductionsAccessResult) {
          updateUserIntroductionsAccess(introductionsAccessResult)
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return introductionAccess
}

export default useIntroductionsAccess
