import React, { useState, useEffect, useMemo, SyntheticEvent } from 'react'

import { Box } from '@mui/material'
import { Link } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import RemoveTeamDialog from '_core/components/dialogs/RemoveTeam'
import TeamModal from '_core/components/dialogs/Team'
import { useSidepanelWide } from '_core/components/layout'
import SharingBar from '_core/components/team/SharingBar'
import MembersWidget, { MembersWidgetProps } from '_core/components/team/TeamMembersWidget'

import useDialog from '_core/hooks/useDialog'

import DynamicEntity from '_core/DynamicEntity'

import Paths from 'Paths'

const useStyles = makeStyles<{ indexing?: boolean; color?: ColorType }>()((theme, { indexing, color }) => ({
  cardHeading: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  cardHeading__preview: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: indexing ? color?.secondary : theme.palette.background.darker
  },
  cardHeading__content: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'space-between'
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '62px'
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '70%'
      },
      [theme.breakpoints.up('md')]: {
        width: '55%'
      }
    },
    '& > button': {
      [theme.breakpoints.up('md')]: {
        alignSelf: 'end'
      }
    }
  },
  cardAvatar: {
    width: 42,
    minWidth: 42,
    height: 42,
    fontSize: 23,
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(2),
    borderRadius: '5px',
    backgroundColor: indexing ? color?.main : theme.palette.text.secondary
  },
  cardAvatarSkeleton: {
    marginRight: theme.spacing(2)
  },
  teamCard: {
    height: '100%',
    display: 'flex',
    backgroundColor: theme.palette.background.light,
    boxShadow: '0px 5px 20px rgba(221, 221, 221, 0.4)',
    borderRadius: theme.spacing(0.5),
    '& > div': {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '100%',
      boxSizing: 'border-box'
    }
  },
  partialCard: {
    display: 'flex',
    backgroundColor: theme.palette.background.light,
    padding: theme.spacing(2)
  },
  cardContent: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  cardContent_item: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: theme.spacing(2),
    '& > h6': {
      marginBottom: theme.spacing(1)
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
      '& > h6': {
        marginBottom: 0
      }
    }
  },
  cardTitle__wrapper: {
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'space-between',
    gridTemplateColumns: 'repeat(auto-fit, minmax(0px, max-content))'
  },
  button: {
    margin: `0 ${theme.spacing(2)}`,
    [theme.breakpoints.up('sm')]: {
      margin: `0 0 0 ${theme.spacing(2)}`
    }
  },
  font: {
    fontSize: 12
  },
  title: {
    lineHeight: 1.2
  }
}))

type TeamCardProps = {
  team?: Team
  userRelations?: UserTeamRelations[]
  admin?: boolean
  partial?: boolean
  reload?: any
  loading?: boolean
  isContributor?: boolean
}

type ColorType = { id: number; main: string; secondary: string }

const colors = [
  { id: 1, main: '#2348B4', secondary: '#F8FAFD' },
  { id: 2, main: '#6279B8', secondary: '#FAFBFD' },
  { id: 3, main: '#7B69A9', secondary: '#FBFBFC' },
  { id: 4, main: '#56C5B7', secondary: '#FAFDFD' },
  { id: 5, main: '#80DAA5', secondary: '#FBFEFC' },
  { id: 6, main: '#7ECAEF', secondary: '#FBFDFF' },
  { id: 7, main: '#E69847', secondary: '#FEFCF9' },
  { id: 8, main: '#1B95BB', secondary: '#F8FCFD' }
]

const TeamCard = ({ team, userRelations, admin, reload, loading, isContributor, ...props }: TeamCardProps) => {
  const { dialogContentProps: openedDialog, openDialog, closeDialog } = useDialog<'remove' | 'update'>()
  const [color, setColor] = useState<ColorType>()
  const [sharing, setSharing] = useState<SharingData | undefined>()
  const [defaultSharing, setDefaultSharing] = useState<any>(team?.defaultSharingOptions)
  const [teamName, setTeamName] = useState<any>(team?.name)
  const [reloadMembers, setReloadMembers] = useState<boolean>(false)
  const wide = useSidepanelWide()
  const { classes } = useStyles({ color, indexing: team?.isActive })

  const getColor = (teamId: number) => {
    if (teamId < 10) {
      const color = colors.find((el) => el.id === teamId)
      if (color) {
        setColor(color)
      } else {
        if (teamId === 9) getColor(5)
        if (teamId === 0) getColor(6)
      }
    } else {
      const teamIdLastNumber = parseInt(teamId.toString().split('').pop() as string)
      getColor(teamIdLastNumber)
    }
  }

  useEffect(() => {
    if (team?.id) getColor(team.id)
  }, [team?.id])

  useEffect(() => {
    if (team?.membersTotal) setReloadMembers(true)
  }, [team?.membersTotal])

  useEffect(() => {
    if (reloadMembers) setReloadMembers(false)
  }, [reloadMembers])

  useEffect(() => {
    if (team) {
      setDefaultSharing(team.defaultSharingOptions)
      setTeamName(team.name)
    }
  }, [team?.defaultSharingOptions, team?.name])

  const userMembership: any = userRelations && team?.id ? userRelations.find((relation) => team && relation.teamNumber === team.id) : false

  useEffect(() => {
    if (!userRelations) return undefined
    return setSharing(
      userMembership
        ? {
            isSharing: userMembership.isSharing,
            isSharingContactInfo: userMembership.isSharingContactInfo,
            isSharingInteractionStats: userMembership.isSharingInteractionStats,
            isSharingContactInfoWithAdminsAndCurators: userMembership.isSharingContactInfoWithAdminsAndCurators
          }
        : undefined
    )
  }, [userRelations, userMembership])

  const handleUpdateOpen = (event: SyntheticEvent) => {
    event.preventDefault()
    openDialog('update')
  }

  const handleRemoveOpen = (event: SyntheticEvent) => {
    event.preventDefault()
    openDialog('remove')
  }

  const handleClose = (event: SyntheticEvent) => {
    event.preventDefault()
    closeDialog()
  }

  const membersUrl = useMemo(
    () =>
      team?.id && !reloadMembers
        ? `${Paths._teams}/${team.id}/members?roleTypes=Member&credentialTypes=ActiveDirectoryTeamMember&userTypes=StandardUser&Take=10`
        : null,
    [team?.id, reloadMembers]
  )

  const form = props.partial ? (
    <Box className={classes.partialCard}>
      <Box className={classes.cardContent}>
        <div className={classes.cardHeading}>
          <Box className={classes.cardHeading__content}>
            <div>
              <Skeleton condition={loading || !color} width="42px" height="42px" variant="rounded" classes={{ root: classes.cardAvatarSkeleton }}>
                <div className={classes.cardAvatar}>{team?.id}</div>
              </Skeleton>
              <Box maxWidth="100%">
                <Skeleton condition={loading || !color} width="100px" height="25px" variant="text">
                  <Typography variant="h3" className={classes.title} semiBold noWrap>
                    {teamName}
                  </Typography>
                </Skeleton>
                {!loading && !team?.isActive && (
                  <Typography color="text.secondary" className={classes.title}>
                    Not activated
                  </Typography>
                )}
              </Box>
            </div>
            {admin && (
              <Box display="flex" alignItems="center" justifyContent={wide ? 'flex-end' : 'center'} mt={2}>
                <TeamModal.TriggerUpdateEl open={handleUpdateOpen} loading={!!loading} className={classes.button} />
                <RemoveTeamDialog.TriggerEl open={handleRemoveOpen} loading={!!loading} className={classes.button} />
              </Box>
            )}
          </Box>
        </div>
      </Box>
    </Box>
  ) : (
    <Box className={classes.teamCard}>
      <Box>
        <div className={classes.cardHeading__preview}>
          <>
            <Skeleton condition={loading || !color} width="42px" height="42px" variant="rounded" classes={{ root: classes.cardAvatarSkeleton }}>
              <div className={classes.cardAvatar}>{team?.id}</div>
            </Skeleton>
            <Box className={classes.cardTitle__wrapper}>
              <Box>
                <Skeleton condition={loading || !color} width="100px" height="25px" variant="text">
                  <Typography title={teamName} className={classes.title} variant="h3" semiBold noWrap>
                    {teamName}
                  </Typography>
                </Skeleton>
                {!loading && !team?.isActive && (
                  <Typography color="text.secondary" className={classes.title}>
                    Not activated1
                  </Typography>
                )}
              </Box>
            </Box>
          </>
        </div>
        {isContributor && (
          <Box className={classes.cardContent_item}>
            <Typography color="text.secondary">Your sharing level</Typography>
            <Skeleton condition={loading} variant="text">
              <SharingBar userSharing={sharing} className={classes.font} />
            </Skeleton>
          </Box>
        )}
        <Box className={classes.cardContent_item}>
          <Typography color="text.secondary">Contributors</Typography>
          {team && (
            <DynamicEntity<{ extraProps: { addprops: Pick<MembersWidgetProps, 'teamId' | 'variant'> } }>
              url={membersUrl}
              component={MembersWidget}
              list={true}
              addprops={{ teamId: team.id, variant: 'groupAvatars' }}
              id="team_members"
            />
          )}
        </Box>

        <Box p={2}>
          {team && (
            <Button<typeof Link> variant="widget" component={Link} to={Paths._teamProfile.replace(':id', '' + team.id)} fullWidth>
              Open
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )

  return (
    <>
      {form}
      {team && (
        <TeamModal
          isOpen={openedDialog === 'update'}
          close={handleClose}
          team={team}
          reload={reload}
          userRelations={userRelations}
          userMembership={{
            ...userMembership,
            ...defaultSharing
          }}
          mode="updating"
        />
      )}
      {team && <RemoveTeamDialog isOpen={openedDialog === 'remove'} close={handleClose} team={team} />}
    </>
  )
}

export default TeamCard
