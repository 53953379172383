import React, { useEffect } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { Button, IconButton } from '_shared/buttons'
import Dialog, { DialogTitle, DialogActions, DialogContent } from '_shared/Dialog'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import useAbortableFetch from '_core/hooks/useAbortableFetch'

import { formatDate, formatDateTime } from 'utils/Utils'

type IndexingResp = {
  prohibitAppAccess: true
  emailAddress: string
  name: string
  userKeyPlainText: string
  userKeyMd5: string
  teamName: string
  teamNumber: number
  credentials: Array<{
    teamNumber: number
    contributorKey: string
    credentialId: string
    credentialName: string
    credentialType: 'OwnsEmailAddress'
    credentialTypeId: number
    lastAdministration: string
    lastSyncTime: string
    teamRoleType: 'Member'
    prohibitAppAccess: string
    ensureAppAccess: string
    isAccessProhibited: boolean
    teamRoleTypeId: number
  }>
  indexingEmailMessagesOldestUtc: string
  indexingEmailMessagesNewestUtc: string
  indexingEmailMessagesRetrievedAllItems: boolean
  indexingEmailMessagesRetrievedAllItemsEver: boolean
  indexingCalendarEntriesOldestUtc: string
  indexingCalendarEntriesNewestUtc: string
  indexingCalendarEntriesRetrievedAllItems: boolean
  indexingCalendarEntriesRetrievedAllItemsEver: boolean
  indexingContactCardsOldestUtc: string
  indexingContactCardsNewestUtc: string
  indexingContactCardsRetrievedAllItems: boolean
  indexingContactCardsRetrievedAllItemsEver: boolean
  analyzeAndPushLastTryStart: string
  analyzeAndPushLastSuccess: string
  importProcessingLastTryStart: string
  importProcessingLastSuccess: string
  partition1ComputeStarted: string
  partition1ComputeAndPushCompleted: string
  partition2ComputeStarted: string
  partition2ComputeAndPushCompleted: string
  pushUserContactScoreCumMainPushNumRoots: number
  pushUserContactScoreMaxPushedLastModified: string
  tallyEmailMessageCount: number
  tallyEmailMessageMinTime: string
  tallyEmailMessageMaxTime: string
  tallyCalendarEventCount: number
  tallyCalendarEventMinTime: string
  tallyCalendarEventMaxTime: string
  tallyCalendarMeetingCount: number
  tallyCalendarMeetingMinTime: string
  tallyCalendarMeetingMaxTime: string
  tallyContactCardCount: number
  tallyContactCardMinTime: string
  tallyContactCardMaxTime: string
  fileSources: Array<{
    fileKey: string
    fileName: string
    uploadTimeUtc: string
    indexingComplete: boolean
    count: number
    uploadedBy: string
    userKey: string
    uploadedFileType: 'LinkedIn'
    description: string
    storedAt: string
    storedAs: string
    label: string
    rowNumber: number
    totalItemCount: number
    errorMessage: string
  }>
} & SharingData

const useStyles = makeStyles()((theme) => ({
  icon: {
    borderRadius: '100%',
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.main,
    width: theme.spacing(3),
    height: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1)
  },
  heading: {
    display: 'flex',
    lineHeight: '24px'
  },
  date: {
    marginLeft: theme.spacing(4)
  },
  wrapper: {
    marginTop: theme.spacing(1),
    margiBottom: theme.spacing(2)
  }
}))

const TriggerEl = ({ open }: { open: () => void }) => (
  <IconButton
    style={{ fontSize: '17px', width: '40px', height: '40px' }}
    icon={['far', 'clipboard-list-check']}
    hint="View indexing information"
    onClick={open}
  />
)

const Item = ({
  loading,
  icon,
  label,
  completed,
  count,
  minTime,
  maxTime
}: {
  loading: boolean
  icon: IconProp
  completed: boolean
  label: string
  count?: number
  minTime?: string
  maxTime?: string
}) => {
  const { classes } = useStyles()

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.heading}>
        <Box className={classes.icon}>
          <FontAwesomeIcon size="sm" icon={icon} />
        </Box>
        <Typography variant="h4">{label}&nbsp;</Typography>
        <Skeleton condition={loading} width="120px">
          <Typography variant="h4" component="span">
            {completed ? 'completed' : 'in progress'} {typeof count === 'number' ? `(${count})` : ''}
          </Typography>
        </Skeleton>
      </Box>
      <Box className={classes.date}>
        <Skeleton condition={loading} width="200px">
          <Typography variant="body1" color="text.secondary">
            {minTime && `${formatDate(minTime)} - `}
            {maxTime ? `${formatDate(maxTime)}` : 'no date'}
          </Typography>
        </Skeleton>
      </Box>
    </Box>
  )
}

const IndexingDialog = (props: { close: () => void; name?: string; teamId?: string; id?: string }) => {
  const { fetchWithAbort, result: stats, loading } = useAbortableFetch<IndexingResp>()

  useEffect(() => {
    if (props.id) {
      fetchWithAbort({ url: `/teams/${props.teamId}/members/${props.id}` })
    }
  }, [props.id, props.teamId])

  return (
    <Dialog open={!!props.id} onClose={props.close} title={<DialogTitle title={props.name || ''} />}>
      <DialogContent>
        <Typography variant="h4" semiBold>
          Last pushed
        </Typography>

        <Box mb={2}>
          <Skeleton condition={loading}>
            <Typography variant="body1" color="text.secondary">
              {stats?.analyzeAndPushLastSuccess ? formatDateTime(stats.analyzeAndPushLastSuccess) : 'no date'}
            </Typography>
          </Skeleton>
        </Box>

        <Typography variant="h4" semiBold>
          Status
        </Typography>

        <Item
          loading={loading}
          icon={['far', 'envelope']}
          label="Emails"
          completed={!!stats?.indexingEmailMessagesRetrievedAllItemsEver}
          count={stats?.tallyEmailMessageCount}
          minTime={stats?.tallyEmailMessageMinTime}
          maxTime={stats?.tallyEmailMessageMaxTime}
        />

        <Item
          loading={loading}
          icon={['far', 'calendar-alt']}
          label="Calendar events"
          completed={!!stats?.indexingCalendarEntriesRetrievedAllItemsEver}
          count={stats?.tallyCalendarMeetingCount}
          minTime={stats?.tallyCalendarMeetingMinTime}
          maxTime={stats?.tallyCalendarMeetingMaxTime}
        />

        <Item
          loading={loading}
          icon={['far', 'address-card']}
          label="Contact cards"
          completed={!!stats?.indexingContactCardsRetrievedAllItemsEver}
          count={stats?.tallyContactCardCount}
          minTime={stats?.tallyContactCardMinTime}
          maxTime={stats?.tallyContactCardMaxTime}
        />

        {stats?.fileSources && (
          <>
            <Typography variant="h4" semiBold>
              LinkedIn
            </Typography>
            {stats.fileSources.map((fileSource, i: number) => (
              <Item
                key={i}
                loading={loading}
                icon={['fab', 'linkedin']}
                label={fileSource.fileName}
                completed={fileSource.indexingComplete}
                maxTime={fileSource.uploadTimeUtc}
              />
            ))}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={props.close} color="secondary" disablePR>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

IndexingDialog.TriggerEl = TriggerEl

export default IndexingDialog
