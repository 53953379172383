import React, { ComponentProps, ReactElement, useContext, useEffect, useMemo } from 'react'

import { Box, SelectChangeEvent } from '@mui/material'
import { Link, matchPath, Route, Switch, useLocation, useParams } from 'react-router-dom'

import RelationshipActivitiesPage from '_pages/relationships/[userId]/people/[personId]/activities'

import { TeamContext } from '_core/context/TeamContext'

import Avatar from '_shared/Avatar'
import { IconButton } from '_shared/buttons'
import Select from '_shared/forms/Select'
import Typography from '_shared/Typography'

import ActivitiesStatsCard from '_core/components/ActivitiesStatsCard'
import { PickAContributorRelationshipDialog, PickAContactRelationshipDialog } from '_core/components/dialogs/RelationshipPicker'
import Empty from '_core/components/Empty'
import Heading from '_core/components/Heading'
import { Column, Columns, Narrow, useWide, Wide } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import { MDByLine, MDPersonByLine2 } from '_core/components/MarketDataSummary'
import PrivateRoute from '_core/components/PrivateRoute'
import ProfileSummary from '_core/components/ProfileSummary'
import RelationshipScore from '_core/components/RelationshipScore'
import TitleDescription from '_core/components/TitleDescription'
import TouchpointRelations from '_core/components/TouchpointRelations'
import Widget from '_core/components/Widget'

import useActivitiesAccess from '_core/hooks/useActivitiesAccess'
import useActivitiesStats from '_core/hooks/useActivitiesStats'
import useActivitiesStatsUserSettings, { withinOptions } from '_core/hooks/useActivitiesStatsUserSettings'
import useContactActivitiesPayloads from '_core/hooks/useContactActivitiesPayloads'
import useDialog from '_core/hooks/useDialog'
import { usePersonMarketData } from '_core/hooks/usePersonMarketData'

import DynamicEntity from '_core/DynamicEntity'
import { stringifyUrl } from '_core/helpers/browser'
import UserSettings from '_core/UserSettings'

import { getLocal, dateFormatURLQuery } from 'utils/Utils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const RelationHeader = (props: Partial<UserPersonType> & { loading: boolean; enabledMarketData: boolean }) => {
  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue
  const wide = useWide()
  const { dialogContentProps, openDialog, closeDialog } = useDialog<{ type: 'user' | 'contact' }>()
  const { type } = dialogContentProps || {}

  const {
    IntroducerBestJobTitleText,
    IntroducerBestJobMatchedUrlText,
    IntroducerBestJobMatchedCompanyName,
    IntroducerBestJobCorpLevelCompanyName,
    IntroducerBestJobCompanyMd5,
    IntroducerBestJobCurrentAsOf,
    IntroducerBestJobIsFormer,
    IntroducerBestPhoneText
  } = props.Introducers?.data[0] || {}

  const { JobIsFormer: BestJobIsFormer, JobCurrentAsOf: BestJobCurrentAsOf } = props.Jobs?.data?.[0] || {}

  const { marketData: userMarketData, getMarketData: getUserMarketData } = usePersonMarketData(
    props.UserFullName || '',
    props.UserBestEmailAddress,
    IntroducerBestJobMatchedCompanyName || IntroducerBestJobCorpLevelCompanyName,
    true
  )

  const { marketData: contactMarketData, getMarketData: getContactMarketData } = usePersonMarketData(
    props.PersonNameText || '',
    props.BestEmailAddrText,
    props.BestJobMatchedCompanyName || props.BestJobCorpLevelCompanyName,
    true
  )

  useEffect(() => {
    if (wide) {
      if (!props.loading && props.enabledMarketData && !userMarketData) {
        getUserMarketData(teamNumber)
      }
    }
  }, [wide, props.enabledMarketData, userMarketData, props.loading, teamNumber])

  useEffect(() => {
    if (wide) {
      if (!props.loading && props.enabledMarketData && !contactMarketData) {
        getContactMarketData(teamNumber)
      }
    }
  }, [wide, props.enabledMarketData, contactMarketData, props.loading, teamNumber])

  const pickAContributorOpen = () => {
    openDialog({ type: 'user' })
  }

  const pickAContactOpen = () => {
    openDialog({ type: 'contact' })
  }

  return (
    <>
      <Widget scope={wide ? 'none' : 'default'}>
        <Box display="flex" justifyContent="space-between">
          <Wide>
            <ProfileSummary
              title={props.UserFullName || ''}
              loading={props.loading}
              userKey={props.UserBestEmailAddress}
              profileLink={`${Paths._people}/${props?.UserKeyMd5}`}
              introducers={null}
              actions={[
                (props.loading || (!props.loading && IntroducerBestPhoneText)) && (
                  <IconButton
                    icon={['far', 'phone']}
                    color="primary"
                    hint={`Make a call to ${IntroducerBestPhoneText}`}
                    disablePadding
                    component="a"
                    href={`tel:${IntroducerBestPhoneText?.substring(2)}`}
                    loading={!IntroducerBestPhoneText}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="small"
                  />
                ),
                (props.loading || (!props.loading && props.UserBestEmailAddress)) && (
                  <IconButton
                    icon={['far', 'envelope']}
                    color="primary"
                    hint={`Send an email to ${props.UserBestEmailAddress}`}
                    disablePadding
                    component="a"
                    href={`mailto:${props.UserBestEmailAddress}`}
                    loading={!props.UserBestEmailAddress}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="small"
                  />
                ),
                (props.loading || (!props.loading && props.UserFullName)) && (
                  <IconButton
                    icon={['fab', 'linkedin']}
                    color="primary"
                    hint="Show Linkedin profile"
                    component="a"
                    disablePadding
                    href={
                      userMarketData?.linkedin_url
                        ? `https://${userMarketData?.linkedin_url}`
                        : `https://www.linkedin.com/search/results/people/?keywords=${props.UserFullName}`
                    }
                    loading={!props.UserFullName}
                    rel="noopener noreferrer"
                    target="_blank"
                    size="small"
                  />
                ),
                ((props.enabledMarketData && !userMarketData) || (!props.loading && userMarketData?.github_url)) && (
                  <IconButton
                    icon={['fab', 'github']}
                    color="primary"
                    hint="Show Github profile"
                    component="a"
                    disablePadding
                    href={`https://${userMarketData?.github_url}`}
                    loading={!userMarketData?.github_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="small"
                  />
                )
              ].filter((action): action is ReactElement => !!action)}
              {...(props.enabledMarketData
                ? {
                    enabledMarketData: true,
                    byline: <MDByLine marketData={!!userMarketData} locationName={userMarketData?.location_name} />,
                    byline2: (
                      <MDPersonByLine2
                        marketData={userMarketData}
                        bestJobMatchedUrlText={IntroducerBestJobMatchedUrlText}
                        internalCompanyName={IntroducerBestJobMatchedCompanyName || IntroducerBestJobCorpLevelCompanyName}
                        bestJobTitleText={IntroducerBestJobTitleText}
                        bestJobCompanyMd5={IntroducerBestJobCompanyMd5}
                        bestJobCurrentAsOf={IntroducerBestJobCurrentAsOf}
                        bestJobIsFormer={IntroducerBestJobIsFormer}
                      />
                    )
                  }
                : {
                    enabledMarketData: false,
                    byline: IntroducerBestJobTitleText,
                    byline2: (
                      <Typography variant="body1" noWrap color="primary.main">
                        <Link to={`${Paths._companies}/${IntroducerBestJobCompanyMd5}`}>
                          {IntroducerBestJobMatchedCompanyName || IntroducerBestJobCorpLevelCompanyName}
                        </Link>
                      </Typography>
                    )
                  })}
            />
          </Wide>
          <Narrow>
            <Avatar name={props.UserFullName || ''} size="md" link={`${Paths._people}/${props?.UserKeyMd5}`} userKey={props.UserBestEmailAddress} />
          </Narrow>
          <Box display="flex" alignItems="center" p={2}>
            <PickAContributorRelationshipDialog.TriggerEl disabled={props.loading} open={pickAContributorOpen} />
          </Box>
          <RelationshipScore score={props.Score} />
          <Box display="flex" alignItems="center" p={2}>
            <PickAContactRelationshipDialog.TriggerEl disabled={props.loading} open={pickAContactOpen} />
          </Box>
          <Wide>
            <ProfileSummary
              flip
              userKey={props.MyUserKeyMd5 && props.BestEmailAddrText}
              title={props.PersonNameText || ''}
              loading={props.loading}
              introducers={null}
              profileLink={`${Paths._people}/${props.MyUserKeyMd5 || props.PersonMd5}`}
              actions={[
                (props.loading || (!props.loading && props.BestPhoneText)) && (
                  <IconButton
                    icon={['far', 'phone']}
                    color="primary"
                    hint={`Make a call to ${props.BestPhoneText}`}
                    disablePadding
                    component="a"
                    href={`tel:${props.BestPhoneText?.substring(2)}`}
                    loading={!props.BestPhoneText}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="small"
                  />
                ),
                (props.loading || (!props.loading && props.BestEmailAddrText)) && (
                  <IconButton
                    icon={['far', 'envelope']}
                    color="primary"
                    hint={`Send an email to ${props.BestEmailAddrText}`}
                    disablePadding
                    component="a"
                    href={`mailto:${props.BestEmailAddrText}`}
                    loading={!props.BestEmailAddrText}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="small"
                  />
                ),
                (props.loading || (!props.loading && props.PersonNameText)) && (
                  <IconButton
                    icon={['fab', 'linkedin']}
                    color="primary"
                    hint="Show Linkedin profile"
                    component="a"
                    disablePadding
                    href={
                      contactMarketData?.linkedin_url
                        ? `https://${contactMarketData?.linkedin_url}`
                        : `https://www.linkedin.com/search/results/people/?keywords=${props.PersonNameText}`
                    }
                    loading={!props.PersonNameText}
                    rel="noopener noreferrer"
                    target="_blank"
                    size="small"
                  />
                ),
                ((props.enabledMarketData && !contactMarketData) || (!props.loading && contactMarketData?.github_url)) && (
                  <IconButton
                    icon={['fab', 'github']}
                    color="primary"
                    hint="Show Github profile"
                    component="a"
                    disablePadding
                    href={`https://${contactMarketData?.github_url}`}
                    loading={!contactMarketData?.github_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="small"
                  />
                )
              ].filter((action): action is ReactElement => !!action)}
              {...(props.enabledMarketData
                ? {
                    enabledMarketData: true,
                    byline: <MDByLine marketData={!!contactMarketData} locationName={contactMarketData?.location_name} />,
                    byline2: (
                      <MDPersonByLine2
                        marketData={contactMarketData}
                        bestJobMatchedUrlText={props.BestJobMatchedUrlText}
                        internalCompanyName={props.BestJobMatchedCompanyName || props.BestJobCorpLevelCompanyName}
                        bestJobTitleText={props.BestJobTitleText}
                        bestJobCompanyMd5={props.BestJobCompanyMd5}
                        bestJobCurrentAsOf={BestJobCurrentAsOf}
                        bestJobIsFormer={BestJobIsFormer}
                      />
                    )
                  }
                : {
                    enabledMarketData: false,
                    byline: props.BestJobTitleText,
                    byline2: (
                      <Typography variant="body1" noWrap color="primary.main">
                        <Link to={`${Paths._companies}/${props.BestJobCompanyMd5}`}>
                          {props.BestJobMatchedCompanyName || props.BestJobCorpLevelCompanyName}
                        </Link>
                      </Typography>
                    )
                  })}
            />
          </Wide>
          <Narrow>
            <Avatar
              name={props.PersonNameText || ''}
              size="md"
              link={`${Paths._people}/${props.MyUserKeyMd5 || props.PersonMd5}`}
              userKey={props.MyUserKeyMd5 || props.BestEmailAddrText}
            />
          </Narrow>
        </Box>
      </Widget>
      <PickAContributorRelationshipDialog isOpened={type === 'user'} close={closeDialog} />
      <PickAContactRelationshipDialog isOpened={type === 'contact'} close={closeDialog} />
    </>
  )
}

const ActivityStats = ({
  loading,
  isDetailedActivityFromStatsWidgetVisible,
  introducerMd5,
  contactMd5,
  userName,
  contactName
}: {
  loading: boolean
  isDetailedActivityFromStatsWidgetVisible: boolean
  introducerMd5: string
  contactMd5: string
  userName: string
  contactName: string
}) => {
  const { from, to } = useParams<{ from: string; to: string }>()
  const { period: withinValue, setInitial, handleChange } = useActivitiesStatsUserSettings()

  const rangeMap: { [key in (typeof withinOptions)[number]['value']]: string } = {
    this_month: getLocal().startOf('month').format(dateFormatURLQuery),
    this_quarter: getLocal().startOf('quarter').format(dateFormatURLQuery),
    this_year: getLocal().startOf('year').format(dateFormatURLQuery)
  }

  const statsPayloads = useContactActivitiesPayloads(contactMd5, introducerMd5)
  const fromDate = withinValue ? rangeMap[withinValue] : withinValue
  const toDate = getLocal().format(dateFormatURLQuery)

  const { stats, clearStats } = useActivitiesStats(
    introducerMd5 ? statsPayloads : null,
    useMemo(() => [contactMd5], [contactMd5]),
    fromDate,
    toDate
  )

  useEffect(() => {
    if (!introducerMd5 || !contactMd5) {
      clearStats()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [introducerMd5, contactMd5])

  const { meetings, inbound, outbound } = stats?.[0] || {}
  const count = (meetings?.count || 0) + (inbound?.count || 0) + (outbound?.count || 0)

  const handleRangeChange = (e: SelectChangeEvent<unknown>) => {
    const { value } = e.target as { value: Exclude<typeof withinValue, undefined> }
    handleChange(value)
  }

  return (
    <UserSettings endpoint="/usersettings/activitiesstatsperiod" setInitial={setInitial}>
      <Widget scope={isDetailedActivityFromStatsWidgetVisible ? 'default' : 'stack'}>
        <Heading
          title="Activity stats"
          icon={['far', 'wave-pulse']}
          count={count}
          link={
            isDetailedActivityFromStatsWidgetVisible && count
              ? stringifyUrl(`${Paths._relationships}/${from}/people/${to}/activities`, {
                  from: fromDate,
                  to: toDate,
                  name: `${userName} and ${contactName}`
                })
              : ''
          }
          sidepanel
        />
        <Box mb={2} display="flex" justifyContent="flex-end">
          <Select
            size="small"
            variant="outlined"
            value={withinValue}
            options={[...withinOptions]}
            onChange={handleRangeChange}
            disabled={loading || !stats}
          />
        </Box>
        <Columns spacing={1}>
          {[
            { header: 'Inbound', amount: inbound?.count || 0 },
            { header: 'Outbound', amount: outbound?.count || 0 },
            { header: 'Meetings', amount: meetings?.count || 0 }
          ].map(({ header, amount }) => (
            <Column key={header} xs={4}>
              <ActivitiesStatsCard header={header} amount={amount} loading={loading || !stats} />
            </Column>
          ))}
        </Columns>
      </Widget>
    </UserSettings>
  )
}

const LatestTouchpoints = ({ loading, stats }: { loading: boolean; stats: Partial<StatsType<{ ContactMd5: string }>> }) => (
  <Widget scope="stack">
    <Heading title="Latest interactions" icon={['far', 'handshake']} />
    <Repeater component={TitleDescription} skeleton={{ size: 3, loading }} items={!loading ? TouchpointRelations(stats, 'Last') : []} />
  </Widget>
)

const RelationshipDetails = (props: {
  loading: boolean
  isStatsWidgetVisible: boolean
  isDetailedActivityFromStatsWidgetVisible: boolean
  main: { data: UserPersonType[] } | undefined
  marketDataIntegration: MarketDataIntegration
}) => {
  const mainData = props.main?.data?.[0]
  const { enabled: enabledMarketData } = props.marketDataIntegration || {}

  if (!props.loading && !mainData?.UserKeyMd5) return <Empty title="No relationship details found" />

  const { UserKeyMd5 = '', PersonNameText = '', UserFullName = '', Introducers, PersonMd5 = '' } = mainData || {}
  const { IntroducerStats, IntroducerUserKeyMd5, IntroducerName } = Introducers?.data[0] || {}

  const ContactMd5Keys: (keyof StatsType<{ ContactMd5: string }>)[] = [
    'LastInboundMsgContactMd5',
    'LastOutboundMsgContactMd5',
    'LastMeetingContactMd5'
  ]

  const ContactNameText: (keyof StatsType<{ ContactMd5: string }>)[] = [
    'LastInboundMsgContactName',
    'LastOutboundMsgContactName',
    'LastMeetingContactName'
  ]

  const UserKeys: (keyof StatsType<{ ContactMd5: string }>)[] = ['LastInboundMsgUserKeyMd5', 'LastOutboundMsgUserKeyMd5', 'LastMeetingUserKeyMd5']
  const UserNames: (keyof StatsType<{ ContactMd5: string }>)[] = ['LastInboundMsgUserName', 'LastOutboundMsgUserName', 'LastMeetingUserName']

  const stats = {
    ...IntroducerStats,
    ...UserKeys.reduce((acc, key) => ({ ...acc, [key]: IntroducerUserKeyMd5 }), {}),
    ...UserNames.reduce((acc, key) => ({ ...acc, [key]: IntroducerName }), {}),
    ...ContactMd5Keys.reduce((acc, key) => ({ ...acc, [key]: PersonMd5 }), {}),
    ...ContactNameText.reduce((acc, key) => ({ ...acc, [key]: PersonNameText }), {})
  }

  return (
    <>
      <RelationHeader {...mainData} loading={props.loading} enabledMarketData={enabledMarketData} />
      <Wide>
        <Columns>
          {props.isStatsWidgetVisible && (
            <Column md={4} style={{ height: '100%' }}>
              <ActivityStats
                loading={props.loading}
                isDetailedActivityFromStatsWidgetVisible={props.isDetailedActivityFromStatsWidgetVisible}
                introducerMd5={UserKeyMd5}
                contactMd5={PersonMd5}
                userName={UserFullName}
                contactName={PersonNameText}
              />
            </Column>
          )}
          <Column md={4}>
            <LatestTouchpoints loading={props.loading} stats={stats} />
          </Column>
        </Columns>
      </Wide>
      <Narrow>
        {props.isStatsWidgetVisible && (
          <ActivityStats
            loading={props.loading}
            isDetailedActivityFromStatsWidgetVisible={props.isDetailedActivityFromStatsWidgetVisible}
            introducerMd5={UserKeyMd5}
            contactMd5={PersonMd5}
            userName={UserFullName}
            contactName={PersonNameText}
          />
        )}
        <LatestTouchpoints loading={props.loading} stats={stats} />
      </Narrow>
    </>
  )
}

const RelationshipUserContact = () => {
  const { pathname } = useLocation()
  const { from, to } = useParams<{ from: string; to: string }>()
  const { setMobileHeader } = useContext(LayoutContext)
  const { teamContextValue } = useContext(TeamContext)
  const wide = useWide()

  const activitiesPath = `${Paths._relationships}/:from/:via/:to/activities`
  const { isExact: matchActivities = false } = matchPath(pathname, { path: activitiesPath }) || {}
  const { userActivitiesAccess } = useActivitiesAccess([from, to, matchActivities]) || {}
  const { isStatsWidgetVisible, isDetailedActivityFromStatsWidgetVisible } = userActivitiesAccess || {}

  useEffect(() => {
    setMobileHeader('Relationship Details')
  }, [setMobileHeader])

  const memoUrls = useMemo(() => {
    return [
      {
        key: 'main',
        url: `/users/${from}/people/${to}?TeamNumber=${teamContextValue.teamNumber}&Take=${wide ? 4 : 3}&IncludeIntroducerStats=True`
      },
      {
        key: 'marketDataIntegration',
        url: '/usersettings/marketDataIntegration'
      }
    ]
  }, [from, to, wide, teamContextValue.teamNumber])

  return (
    <>
      <Switch>
        <PrivateRoute
          exact
          hasAccess={isStatsWidgetVisible && isDetailedActivityFromStatsWidgetVisible}
          path={activitiesPath}
          component={RelationshipActivitiesPage}
        />
        <Route path={`${Paths._relationships}/:from/:via/:to`}>
          <DynamicEntity<{
            extraProps: {
              addprops: Pick<ComponentProps<typeof RelationshipDetails>, 'isStatsWidgetVisible' | 'isDetailedActivityFromStatsWidgetVisible'>
            }
          }>
            urls={memoUrls}
            component={RelationshipDetails}
            nativeBack={true}
            subHeader={(resp: { data: ({ main: { data: UserPersonType[] } } | undefined)[] }) => {
              const { UserFullName, PersonNameText } = resp.data[0]?.main.data[0] || {}
              const title = UserFullName && PersonNameText ? `${UserFullName} and ${PersonNameText}` : ''
              return (
                <Typography title={title} variant="body1" style={{ maxWidth: '100%' }} noWrap>
                  {title}
                </Typography>
              )
            }}
            addprops={{
              isStatsWidgetVisible: !!isStatsWidgetVisible,
              isDetailedActivityFromStatsWidgetVisible: !!isDetailedActivityFromStatsWidgetVisible
            }}
            keepMounted
            id="rel_details"
          />
        </Route>
      </Switch>
    </>
  )
}

export default RelationshipUserContact
