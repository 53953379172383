import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'

import { AvatarWrapper } from '_shared/Avatar'
import Typography from '_shared/Typography'

const MemberOption = (props: { [key: string]: any }, option: { [key: string]: any }, state: { [key: string]: any }) => (
  <Box
    display="grid"
    alignItems="center"
    gridTemplateColumns="max-content minmax(0,auto)"
    fontWeight={state.selected ? 500 : 400}
    px={2}
    py={1}
    component="li"
    {...props}
    key={option.value || option.label}
  >
    <Box mr={2}>
      {option.isGroup ? (
        <Box
          width={32}
          height={32}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="100%"
          border="1px solid #979797"
          color="#979797"
        >
          <FontAwesomeIcon icon={['far', 'users']} title="Group" style={{ fontSize: 16 }} />
        </Box>
      ) : (
        <AvatarWrapper name={option.label} userKey={option.value} logoUrl={option.url} hideName size="sm" />
      )}
    </Box>
    <Box display="flex" flexDirection="column" maxWidth="100%">
      <Typography noWrap>{option.label}</Typography>
      <Typography color="text.secondary" noWrap>
        {option.description ? (
          <Box component="span" title={option.description}>
            {option.description}
          </Box>
        ) : (
          <>{option.value}</>
        )}
      </Typography>
    </Box>
  </Box>
)

export default MemberOption
