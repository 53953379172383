import React, { useContext, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useParams } from 'react-router-dom'

import Avatar from '_shared/Avatar'
import { IconButton } from '_shared/buttons'

import Empty from '_core/components/Empty'
import Heading from '_core/components/Heading'
import Repeater from '_core/components/lists/Repeater'
import SidepanelLink from '_core/components/SidepanelLink'
import Topbar from '_core/components/Topbar'
import Widget from '_core/components/Widget'

import useAccessDetailsForm from '_core/hooks/useAccessDetailsForm'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

type ListProps = { request: IntroductionRequestResp; loading: boolean }

const CollaboratorsList = ({ request, loading }: ListProps) => {
  const { id } = useParams<{ id: string }>()
  const { setMobileHeader, setSubHeader } = useContext(LayoutContext)
  const { appUserAccesses, teamAccesses, fetching } = useAccessDetailsForm(request)

  const plan = request?.plan || {}

  useEffect(() => {
    setMobileHeader(plan.planSummary, !plan.planSummary)
  }, [setMobileHeader, plan.planSummary])

  useEffect(() => {
    setSubHeader('Collaborators')
    return () => setSubHeader('')
  }, [setSubHeader])

  const Action = (
    <SidepanelLink linkProps={{ to: `${Paths._introductions}/${id}/collaborators/add` }}>
      <IconButton icon={['far', 'edit']} hint="Edit collaborators" color="primary" size="small" />
    </SidepanelLink>
  )

  const isCreator = plan.planUid && request.queriedByAppUserKey === plan.creatorDotAppUserKey
  const load = loading || !teamAccesses || !appUserAccesses || fetching

  return (
    <>
      <Topbar nativeBack action={isCreator ? Action : null} />
      <Widget>
        <Heading underlined title="Colleagues" count={appUserAccesses?.length} icon={['far', 'user']} />
        <Repeater
          direction="horizontal"
          component={Avatar}
          empty={<Empty subTitle="No colleagues" icon={<FontAwesomeIcon size="3x" icon={['fat', 'address-book']} style={{ color: '#A7A7A7' }} />} />}
          skeleton={{ size: 3, loading: load }}
          items={
            appUserAccesses
              ? appUserAccesses.slice(0, 3).map((user) => ({
                  name: user.name,
                  userKey: user.email,
                  byline: user.email,
                  link: `${Paths._people}/${user.email}`
                }))
              : []
          }
        />
      </Widget>
      <Widget>
        <Heading underlined title="Teams" count={teamAccesses?.length} icon={['fas', 'users-class']} />
        <Repeater
          direction="horizontal"
          component={Avatar}
          empty={<Empty subTitle="No teams" icon={<FontAwesomeIcon size="3x" icon={['fat', 'users-class']} style={{ color: '#A7A7A7' }} />} />}
          skeleton={{ size: 3, loading: load }}
          items={
            teamAccesses
              ? teamAccesses.slice(0, 3).map((team) => ({
                  name: team.teamName,
                  byline: `${team.teamMembers} member${team.teamMembers > 1 ? 's' : ''}`,
                  link: `${Paths._teams}/${team.teamNumber}/members`
                }))
              : []
          }
        />
      </Widget>
    </>
  )
}

export default CollaboratorsList
