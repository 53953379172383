import { ReactElement, useContext, useEffect } from 'react'

import { Box } from '@mui/material'
import { useParams, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'

import { isSidepanel } from '_pages/sidebar'

import { TeamContext } from '_core/context/TeamContext'

import Page from '_shared/Page'

import AuditSources, { SourceFacts } from '_core/components/audit/AuditSources'
import IdentifierContributors from '_core/components/audit/IdentifierContributors'
import Empty from '_core/components/Empty'
import { Narrow } from '_core/components/layout'
import Topbar from '_core/components/Topbar'
import Widget from '_core/components/Widget'

import useAdminOrCuratorCheck from '_core/hooks/useAdminOrCuratorCheck'
import useAuditSources from '_core/hooks/useAuditSources'
import useEntityEndpoint from '_core/hooks/useEntityEndpoint'
import useSearchQuery from '_core/hooks/useSearchQuery'

import DynamicEntity from '_core/DynamicEntity'
import { checkOutlook } from '_core/helpers/outlook'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const baseMatch = `${Paths._companies}/:id/domainSources/:url`

const Sources = (props: { searchInput: ReactElement; emptyTitle: string; emptySubtitle: string; enabledOpenFacts: boolean }) => {
  const {
    key: contributor,
    type: graphSourceType,
    url: identifier
  } = useParams<{ type: Exclude<GraphSourceType, 'Manual'>; key: string; url: string }>()
  const history = useHistory()
  const match = useRouteMatch()
  const { searchInput, emptyTitle, emptySubtitle } = props

  const { teamContextValue } = useContext(TeamContext)

  const { result, loading, more, reload } = useEntityEndpoint(
    `/audit/idcontributors?teamNumber=${teamContextValue.teamNumber}&identifier=${encodeURIComponent(identifier)}&entityType=Company`,
    null,
    5,
    true,
    true
  )

  const dataLength = result?.data?.length
  const contributors = result?.data?.map((item: any) => item.userKey)

  const sources = useAuditSources({ identifier, type: 'CompanyUrl' }, contributors, loading)

  const openFacts = (data: { type: GraphSourceType; key: string }) => {
    const { key, type } = data
    history.push(`${match.url}/${key}/${type}`)
  }

  const empty = !loading && typeof dataLength === 'number' && !dataLength

  return (
    <Switch>
      <Route path={`${baseMatch}/:key/:type`}>
        <SourceFacts
          contributor={contributor}
          requestType="CompanyUrl"
          graphSourceType={graphSourceType}
          identifier={encodeURIComponent(identifier)}
        />
      </Route>
      <Route exact path={baseMatch}>
        {searchInput}
        {empty && <Empty title={emptyTitle} subTitle={emptySubtitle} />}
        {!empty && (
          <AuditSources
            items={sources?.map((source) => ({ ...source, ...(props.enabledOpenFacts && { openFacts }) }))}
            loading={loading}
            more={more}
            reload={reload}
            hasMore={!!result?.are_more}
            total={result ? dataLength : 0}
          />
        )}
      </Route>
    </Switch>
  )
}

const CompanyDomainSourcesPage = () => {
  const { setMobileHeader } = useContext(LayoutContext)
  const { teamContextValue } = useContext(TeamContext)
  const { teamNumber } = teamContextValue
  const { url: identifier } = useParams<{ url: string }>()
  const hasSidebar = isSidepanel() || checkOutlook()
  const { queryParams } = useSearchQuery<SearchParams>()
  const { isAdminOrCurator, admin } = useAdminOrCuratorCheck()

  useEffect(() => {
    setMobileHeader(identifier || '')
  }, [identifier, setMobileHeader])

  const emptyTitle = 'No domain url contributors found'
  const emptySubtitle = `No results found ${queryParams.keyword ? `for your search "${queryParams.keyword}"` : ''}`

  return (
    <Page variant="core">
      <Narrow>
        <Topbar nativeBack sub={`${isAdminOrCurator ? 'Sources' : 'Contributors'}`} />
      </Narrow>
      <Widget scope="list">
        <Box display="flex" flexDirection="column" height={`calc(100vh - ${hasSidebar ? 88 : 60}px - 8px)`}>
          {isAdminOrCurator ? (
            <Sources
              enabledOpenFacts={!!admin}
              searchInput={<IdentifierContributors.Search key={identifier} />}
              emptyTitle={emptyTitle}
              emptySubtitle={emptySubtitle}
            />
          ) : (
            <>
              <IdentifierContributors.Search key={identifier} />
              <DynamicEntity
                id="identifier_contributors"
                scrollableTarget="identifier_contributors"
                url={
                  isAdminOrCurator === false ? `/companies/idcontributors?teamNumber=${teamNumber}&identifier=${encodeURIComponent(identifier)}` : ''
                }
                pageSize={20}
                empty={emptyTitle}
                emptySubtitle={emptySubtitle}
                search
                list
                infinite
                component={IdentifierContributors}
              />
            </>
          )}
        </Box>
      </Widget>
    </Page>
  )
}

export default CompanyDomainSourcesPage
