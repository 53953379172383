import React, { useEffect, useContext, useRef, useState } from 'react'

import { useParams } from 'react-router-dom'

import Empty from '_core/components/Empty'
import Filters, { Controller } from '_core/components/filters/SimilarPeople'
import { Narrow } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import InfiniteList from '_core/components/market-data/InfiniteList'
import ProfileItem from '_core/components/ProfileItem'
import Topbar from '_core/components/Topbar'
import Widget from '_core/components/Widget'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import useSearchQuery from '_core/hooks/useSearchQuery'
import { FiltersType, PeopleType } from '_core/hooks/useSimilarPeople'

import { mergeUrlWithParams } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

interface SimilarPeopleProps {
  BestEmailAddrText: string
  BestJobTitleText: string
  marketData: { [key: string]: any } | null
  similar: PeopleType | null
  setSimilar: (val: PeopleType | null) => void
  loading: boolean
  teamNumber: number
}

const SimilarPeople = ({ marketData, similar, setSimilar, teamNumber, ...props }: SimilarPeopleProps) => {
  const { queryParams, updateQuery } = useSearchQuery<any>()
  const [openedFilters, setOpenedFilters] = useState<boolean>(false)
  const [filters, setFilters] = useState<FiltersType | null>()
  const [loading, setLoading] = useState<boolean>(false)
  const { setSubHeader } = useContext(LayoutContext)
  const { id } = useParams<{ id: string }>()
  const anchorRef = useRef<HTMLButtonElement | null>(null)
  const notAppliedFilters = useRef<boolean>(false)
  const { fetchWithAbort } = useAbortableFetch<{ [key: string]: any }>()

  const load = loading || props.loading
  const {
    job_title,
    job_title_role,
    job_title_sub_role,
    job_title_levels,
    job_company_industry,
    industry,
    location_country,
    job_company_size,
    work_email
  } = marketData || {}
  const email = work_email || props.BestEmailAddrText
  const enoughFilters = marketData && email && (job_title || props.BestJobTitleText)

  useEffect(() => {
    setSubHeader(`Similar People ${similar?.total ? `· ${similar.total}` : ''}`)
    return () => setSubHeader('')
  }, [setSubHeader, similar?.total])

  useEffect(() => {
    if (queryParams.clearSearch && setSimilar && !props.loading) {
      updateQuery({ clearSearch: null })
      setSimilar(null)
      setFilters(null)
    }
    if (queryParams.openedFilters === 'true' && !props.loading) {
      updateQuery({ openedFilters: null })
      setOpenedFilters(true)
    }
  }, [queryParams.clearSearch, queryParams.openedFilters, setSimilar, props.loading])

  useEffect(() => {
    const storedFilters = id && similar?.id && similar.id === id && !!similar?.filters
    if (!filters && storedFilters && !queryParams.clearSearch) {
      setFilters(similar.filters)
      return
    }

    if (marketData && !filters) {
      setInitialFilters()
      return
    }

    if (filters && notAppliedFilters.current && !openedFilters) {
      notAppliedFilters.current = false
      if (storedFilters) {
        setFilters(similar.filters)
        return
      }
      if (marketData) {
        setInitialFilters()
        return
      }
    }
  }, [filters, similar?.id, similar?.filters, id, marketData, queryParams.clearSearch, notAppliedFilters.current, openedFilters])

  const setNoSimilar = (persistFilters?: boolean, error?: string) =>
    setSimilar({
      id,
      email,
      data: [],
      scroll_token: '',
      total: 0,
      error: error || 'Not enough market data is available for an effective search filter',
      ...(persistFilters && filters && { filters })
    })

  const getSimilar = async (persistFilters?: boolean, loadMore?: boolean) => {
    if (filters && teamNumber) {
      try {
        setLoading(true)
        const res = await fetchWithAbort({
          url: mergeUrlWithParams('/people/similarPeople', {
            email,
            titlecase: 'true',
            teamNumber: teamNumber.toString(),
            ...(loadMore && similar?.scroll_token && { scrollToken: similar.scroll_token }),
            ...filters
          })
        })
        if (res) {
          setSimilar({
            id,
            email,
            data: loadMore && similar?.data ? [...similar.data, ...res.data] : res.data,
            scroll_token: res.scroll_token || '',
            total: res.total,
            ...(persistFilters && { filters })
          })
        }
      } catch (e: any) {
        setNoSimilar(persistFilters, e?.status === 500 ? 'No records were found' : e?.error)
        console.log('error during fetching similar people', e)
      } finally {
        if (persistFilters) {
          notAppliedFilters.current = false
        }
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    if (filters && !similar && !load) {
      enoughFilters ? getSimilar() : setNoSimilar()
    }
  }, [similar, filters, load])

  const items = similar?.data?.map((person: { [key: string]: any }) => ({
    name: person.full_name,
    score: person.personMd5 ? person.score : undefined,
    link: person.personMd5
      ? `${Paths._people}/${person.personMd5}`
      : mergeUrlWithParams(`${Paths._people}/${id}/similar/${person.full_name}`, {
          email: person.work_email,
          company: person.job_company_name
        }),
    sidepanel: true,
    byline: person.job_title || '',
    byline2: person.job_company_name || ''
  }))

  const setInitialFilters = () => {
    setFilters({
      companySize: job_company_size || '',
      jobTitles: [job_title || props.BestJobTitleText].filter(Boolean),
      jobTitleRoles: job_title_role ? [job_title_role] : [],
      jobTitleSubroles: job_title_sub_role ? [job_title_sub_role] : [],
      jobTitleLevels: job_title_levels || [],
      industries: job_company_industry || industry ? [job_company_industry || industry] : [],
      countries: enoughFilters ? [location_country || 'United States'] : []
    })
  }

  const updateFilters = (updatedFilters: FiltersType) => {
    notAppliedFilters.current = true
    setFilters(updatedFilters)
  }

  const toggleOpen = () => setOpenedFilters((prevState: boolean) => !prevState)
  const applyFilters = () => {
    const emptyFilters =
      !!filters &&
      Object.keys(filters)?.every((key) => {
        const typedKey = key as keyof FiltersType
        return Array.isArray(filters[typedKey]) ? !filters[typedKey].length : !filters[typedKey]
      })
    emptyFilters ? setNoSimilar(true) : getSimilar(true)
  }
  const loadMore = () => getSimilar(true, true)
  const reset = () => {
    notAppliedFilters.current = true
    setInitialFilters()
  }
  const hasMore = Boolean(similar?.data?.length && similar.total > similar.data.length && similar?.scroll_token)

  return (
    <Narrow>
      <Topbar nativeBack autoHideOnScroll />
      <Widget scope="list">
        <Controller opened={openedFilters} toggleOpen={toggleOpen} disabled={!filters} variant="text" ref={anchorRef} />
        <Filters
          opened={openedFilters}
          disabled={!filters}
          contentLoading={load}
          anchorEl={anchorRef.current}
          total={similar?.total || 0}
          filters={filters}
          updateFilters={updateFilters}
          reset={reset}
          applyFilters={applyFilters}
        />
        <InfiniteList loading={loading} next={loadMore} dataLength={similar?.total || 0} hasMore={hasMore}>
          <Repeater
            virtualized
            direction="vertical"
            component={ProfileItem}
            skeleton={{ size: 5, loading: !similar }}
            variant="list"
            empty={<Empty title={similar?.error || 'No records were found'} />}
            items={items || []}
          />
        </InfiniteList>
      </Widget>
    </Narrow>
  )
}

export default SimilarPeople
