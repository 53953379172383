import React, { ChangeEvent, FocusEvent, SyntheticEvent, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, CircularProgress } from '@mui/material'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'
import Combobox from '_shared/forms/Combobox'
import FormLabel from '_shared/forms/FormLabel'
import TextField from '_shared/forms/TextField'

import { Narrow } from '_core/components/layout'
import { phoneTypeIcon } from '_core/components/PhoneNumber'
import { AddLink } from '_core/components/Picker'
import Success from '_core/components/Success'
import Topbar from '_core/components/Topbar'

import usePersonForm from '_core/hooks/usePersonForm'
import usePicker from '_core/hooks/usePicker'
import useSidepanelClose from '_core/hooks/useSidepanelClose'

import { validUrl, validEmail } from '_core/helpers/string'

import { actionButtons } from 'AppTheme'
import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  actions: actionButtons(theme),
  updated: {
    background: 'rgb(248, 253, 255)'
  },
  form: {
    height: 'calc(100% - 60px)',
    marginBottom: 53
  },
  closeButton: {
    marginTop: theme.spacing(2)
  }
}))

const phoneTypeOptions: PhoneType[] = ['Work', 'Mobile', 'Home', 'Fax']

export const EditForm = (props: {
  newForm: boolean
  name: string
  email: string
  phone: string
  phoneType: PhoneType
  job: string
  company: string
  companyUrl: string
  updated: { [key in 'name' | 'email' | 'phone' | 'phoneType' | 'job' | 'company']?: boolean }
  jobs?: PersonJobsType[]
  newCompany: boolean
  nameOptions?: string[]
  emailOptions?: string[]
  phoneOptions?: string[]
  setName: (value: React.SetStateAction<string | undefined>) => void
  setEmail: (value: React.SetStateAction<string | undefined>) => void
  setCompany: (value: React.SetStateAction<string | undefined>) => void
  setCompanyUrl: (value: React.SetStateAction<string | undefined>) => void
  setNewCompany: React.Dispatch<React.SetStateAction<boolean | undefined>>
  setPhone: (value: React.SetStateAction<string | undefined>) => void
  setPhoneType: (value: React.SetStateAction<PhoneType | undefined>) => void
  setJob: (value: React.SetStateAction<string | undefined>) => void
}) => {
  const { classes, cx } = useStyles()
  const [touched, setTouched] = React.useState<{ [key in 'name' | 'email' | 'phone' | 'phoneType' | 'job' | 'company']?: boolean }>({})

  const { name, email, phone, phoneType, job, company, companyUrl, updated, jobs, newCompany, nameOptions, emailOptions, phoneOptions, newForm } =
    props
  const { setName, setEmail, setCompany, setCompanyUrl, setNewCompany, setPhone, setPhoneType, setJob } = props

  const companiesOptions = jobs ? jobs.map((item) => item.JobCompanyName || item.CorpLevelCompanyName) : []

  const touch = (name: keyof typeof touched) => {
    return setTouched((prevState) => ({ ...prevState, [name]: true }))
  }

  const handleNameChange = (e: SyntheticEvent<Element, Event>, newValue: string | null) => {
    const name = newValue === null ? '' : newValue
    setName(name)
    touch('name')
  }

  const handleEmailChange = (e: SyntheticEvent<Element, Event>, newValue: string | null) => {
    const email = newValue === null ? '' : newValue
    setEmail(email)
    touch('email')
  }

  const handlePhoneChange = (e: SyntheticEvent<Element, Event>, newValue: string | null) => {
    const phone = newValue === null ? '' : newValue
    setPhone(phone)
    touch('phone')
  }

  const handlePhoneTypeChange = (e: SyntheticEvent<Element, Event>, phoneType: PhoneType) => {
    setPhoneType(phoneType)
    touch('phoneType')
  }

  const handleJobTitleChange = (e: SyntheticEvent<Element, Event>, newValue: string | null) => {
    const job = newValue === null ? '' : newValue
    setJob(job)
    touch('phone')
  }

  const handleCompanyChange = (e: SyntheticEvent<Element, Event>, newValue: string | null) => {
    const company = newValue === null ? '' : newValue
    setNewCompany(!companiesOptions.includes(company))
    setCompany(company)
    touch('company')
  }

  const handleCompanyUrlChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e) {
      setCompanyUrl(e.target.value || '')
    }
  }

  const handleBlur = (e: FocusEvent<HTMLDivElement>) => {
    const target = e.target as HTMLInputElement
    touch(target.name as keyof typeof touched)
  }

  const { message: emailErrorMessage = '' } =
    [
      { condition: !email, message: 'This field is required' },
      { condition: !validEmail(email.trim()), message: 'This field is invalid' }
    ].find(({ condition }) => touched.email && !!condition) || {}

  const { message: urlErrorMessage = '' } =
    [
      { condition: company && !companyUrl, message: 'This field is required' },
      { condition: companyUrl && !validUrl(companyUrl.trim()), message: 'This field is invalid' }
    ].find(({ condition }) => touched.company && !!condition) || {}

  const jobsOptions = jobs ? jobs.filter(({ TitleText }) => !!TitleText).map(({ TitleText }) => TitleText) : []

  return (
    <>
      <Box p={2} className={cx({ [classes.updated]: updated.name })}>
        <Combobox
          name="name"
          label="Full name"
          icon={['far', 'user']}
          autoFocus
          freeSolo
          forcePopupIcon={!!nameOptions?.length}
          defaultValue={name}
          value={name}
          options={nameOptions || []}
          errorMessage={touched.name && !name ? 'This field is required' : ''}
          onInputChange={handleNameChange}
          onChange={handleNameChange}
          onBlur={handleBlur}
        />
      </Box>
      <Box p={2} className={cx({ [classes.updated]: updated.email })}>
        <Combobox
          name="email"
          label="Primary email address"
          icon={['far', 'envelope']}
          freeSolo
          forcePopupIcon={!!emailOptions?.length}
          defaultValue={email}
          value={email}
          options={emailOptions || []}
          errorMessage={emailErrorMessage}
          onInputChange={handleEmailChange}
          onChange={handleEmailChange}
          onBlur={handleBlur}
        />
      </Box>
      <Box p={2} className={cx({ [classes.updated]: updated.phone || updated.phoneType })}>
        <FormLabel label="Primary phone number" />
        <Box display="flex">
          <Box mr={2} width={120}>
            <Combobox
              name="phoneType"
              icon={phoneTypeIcon(phoneType)}
              disableClearable
              defaultValue={phoneType || ('Work' as PhoneType)}
              value={phoneType}
              isOptionEqualToValue={(option, value) => option === value}
              options={phoneTypeOptions}
              onChange={handlePhoneTypeChange}
              onBlur={handleBlur}
              renderOption={(props, option, state) => (
                <li {...props}>
                  <Box width={24} display="flex" justifyContent="center" alignItems="center" mr={1}>
                    <FontAwesomeIcon icon={phoneTypeIcon(option)} style={{ color: state.selected ? '#1B95BB' : '#979797' }} />
                  </Box>
                  {option}
                </li>
              )}
            />
          </Box>
          <Box flex={1}>
            <Combobox
              name="phone"
              freeSolo
              defaultValue={phone}
              forcePopupIcon={!!phoneOptions?.length}
              value={phone}
              options={phoneOptions || []}
              onInputChange={handlePhoneChange}
              onChange={handlePhoneChange}
              onBlur={handleBlur}
            />
          </Box>
        </Box>
      </Box>
      {(newForm || jobs) && (
        <Box p={2} className={cx({ [classes.updated]: updated.company })}>
          <Combobox
            name="company"
            label="Company name"
            freeSolo
            defaultValue={company}
            value={company}
            options={companiesOptions}
            forcePopupIcon={!!companiesOptions.length}
            onChange={handleCompanyChange}
            onInputChange={handleCompanyChange}
            onBlur={handleBlur}
          />
        </Box>
      )}
      {(newForm || newCompany) && (
        <Box p={2} className={cx({ [classes.updated]: updated.company })}>
          <TextField label="Company website" value={companyUrl} errorMessage={urlErrorMessage} onChange={handleCompanyUrlChange} fullWidth />
        </Box>
      )}
      <Box p={2} className={cx({ [classes.updated]: updated.job })}>
        <Combobox
          name="job"
          label="Job Title"
          freeSolo
          defaultValue={job}
          value={job}
          options={jobsOptions}
          forcePopupIcon={!!jobsOptions?.length}
          onInputChange={handleJobTitleChange}
          onChange={handleJobTitleChange}
          onBlur={handleBlur}
        />
      </Box>
    </>
  )
}

const EditPerson = (props: { newForm: boolean; loading?: boolean; person?: RemoteData<PersonType>; jobs?: { data: PersonJobsType[] } }) => {
  const { classes } = useStyles()
  const person = props.person?.data[0]
  const params = useParams<{ id: string; new?: string }>()

  const [success, setSuccess] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(!!props.loading)
  const { setMobileHeader } = React.useContext(LayoutContext)
  const handleClose = useSidepanelClose(`${Paths._people}/${params.id}`)
  const { Form, save, allowSubmit, ...formData } = usePersonForm(person)
  const { name, setName } = formData

  const getLink = (md5: string) => `${Paths._people}/${md5}/edit`

  const { Picker, PickerButton, keyword } = usePicker({ entity: 'people', addButton: AddLink, getLink, name, unsaved: allowSubmit })
  const picked = !!props.newForm || params.id

  React.useEffect(() => {
    typeof keyword === 'string' && setName(keyword)
  }, [keyword])

  const submit = async () => {
    setLoading(true)
    try {
      await save()
      setSuccess(true)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!params.id && !params.new) setSuccess(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  useEffect(() => {
    setMobileHeader(!picked ? 'Pick or add person' : params.id ? 'Edit profile' : 'Add person')
  }, [picked, params.id, setMobileHeader])

  if (success)
    return (
      <Success>
        <Box display="flex" alignItems="center" flexDirection="column">
          <Link to={'/people/add'}>
            <Button variant="outlined" startIcon={<FontAwesomeIcon icon={['far', 'user-plus']} style={{ fontSize: 14 }} />}>
              Add/Edit another person
            </Button>
          </Link>
          <Button variant="text" onClick={handleClose} color="secondary" className={classes.closeButton}>
            Close
          </Button>
        </Box>
      </Success>
    )

  return (
    <>
      {loading && (
        <Box display="flex" alignItems="center" justifyContent="center" height={320}>
          <CircularProgress />
        </Box>
      )}
      {!loading && (
        <>
          <Narrow>
            <Topbar sub={picked && name} nativeBack={true} action={params.id ? PickerButton : null} />
          </Narrow>
          <Box display="flex" flexDirection="column" style={{ height: '100%' }}>
            {!picked && Picker}
            {picked && (
              <Box className={classes.form}>
                <Form
                  jobs={props.jobs?.data}
                  {...formData}
                  newForm={!!props.newForm}
                  nameOptions={person?.Names?.data?.map((item) => item.StandardizedName).filter((name) => !!name)}
                  emailOptions={person?.Emails?.data && person.Emails?.data.map((item) => item.AddressText).filter((emailAddress) => !!emailAddress)}
                  phoneOptions={person?.Phones?.data?.map((item) => item.StandardizedPhoneNumber)}
                />
                <Box className={classes.actions}>
                  <Button variant="text" disabled={!allowSubmit} onClick={submit} disablePR>
                    Save
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </>
      )}
    </>
  )
}

export default EditPerson
