import { useContext, useEffect, useState } from 'react'

import useAbortableFetch from '_core/hooks/useAbortableFetch'

import { mergeUrlWithParams } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

export const usePersonMarketData = (name: string, email?: string, company?: string, unsettedHeader?: boolean) => {
  const { setMobileHeader } = useContext(LayoutContext)
  const [marketData, setMarketData] = useState<{ [key: string]: any } | null>(null)
  const [error, setError] = useState('')
  const { fetchWithAbort } = useAbortableFetch<{ [key: string]: any }>()

  useEffect(() => {
    if (name && !unsettedHeader) {
      setMobileHeader(name)
    }
  }, [name, unsettedHeader, setMobileHeader])

  useEffect(() => {
    if (name) {
      return () => {
        setMarketData(null)
        setError('')
      }
    }
  }, [name])

  const getMarketData = async (teamNumber: number) => {
    if (name) {
      if (email || company) {
        try {
          const res = await fetchWithAbort({
            url: mergeUrlWithParams('/people/enrich', {
              name,
              email,
              company,
              teamNumber: teamNumber.toString(),
              titlecase: 'true'
            })
          })
          if (res) setMarketData(res.data)
        } catch (e: any) {
          setMarketData({})
          setError(e?.status === 500 ? 'No data found' : e?.error)
          console.log('error during fetching person market data', e)
        }
      } else {
        setMarketData({})
      }
    }
  }

  return {
    marketData,
    setMarketData,
    getMarketData,
    error
  }
}
