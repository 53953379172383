import { CSSProperties, memo, useCallback, useEffect, useRef } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { VariableSizeList as List } from 'react-window'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'

import { ActivityDetails, TimeCard, ActivityRow } from '_core/components/ActivitiesList'
import Empty from '_core/components/Empty'
import InfiniteScroll from '_core/components/InfiniteScroll'
import Timeline, { TimeLineItem, TimelineOppositeContent } from '_core/components/lists/Timeline'

import useActivitiesGroups, { LocalActivityItem } from '_core/hooks/useActivitiesGroups'
import useActivitiesSlots, { ActivityItem } from '_core/hooks/useActivitiesSlots'
import useEntityEndpoint from '_core/hooks/useEntityEndpoint'
import useVirtualized from '_core/hooks/useVirtualized'

import { getSkeletonSize } from '_core/helpers/skeleton'

import { formatTime, getLocal } from 'utils/Utils'

import Repeater from './lists/Repeater'

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    maxWidth: 600
  },
  timeCardWrapper: {
    marginTop: theme.spacing(1),
    padding: 0,
    maxWidth: 70
  },
  card: {
    marginLeft: 0
  }
}))

const TSlot = ({ from, to, action, companies = [], detailsLink, dateTime }: LocalActivityItem & { dateTime: string }) => {
  const isFutureEvent = getLocal(dateTime).isAfter(getLocal())
  const { classes } = useStyles()

  const {
    f = [],
    t = [],
    text = '',
    icon = null
  } = [
    {
      f: from,
      t: to,
      text: isFutureEvent ? 'will meet' : 'met',
      icon: ['far', 'calendar-alt'] as IconProp,
      condition: action === 'meeting'
    },
    {
      f: to,
      t: from,
      text: 'received message from',
      icon: ['fas', 'message-arrow-down'] as IconProp,
      condition: action === 'inbound'
    },
    {
      f: from,
      t: to,
      text: 'sent message to',
      icon: ['fas', 'message-arrow-up'] as IconProp,
      condition: action === 'outbound'
    }
  ].find(({ condition }) => condition) || {}
  return (
    <ActivityDetails
      time={formatTime(dateTime)}
      from={f}
      detailsLink={detailsLink}
      actionText={text}
      icon={icon}
      to={t}
      isInternal={companies.length === 1}
      companies={companies.filter((domain) => !!domain) as string[]}
      loading={!dateTime}
      className={classes.card}
    />
  )
}

const TimeLineComponent = ({
  dateTime,
  activities,
  loading,
  setSize,
  index
}: {
  dateTime: string
  activities: LocalActivityItem[]
  loading: boolean
  setSize: (index: number, height: number) => void
  index: number
}) => {
  const rowRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (rowRef.current) {
      setSize(index, rowRef.current.getBoundingClientRect().height)
    }
  }, [index, loading])

  return (
    <div ref={rowRef}>
      <Repeater
        items={activities?.map((activity) => ({ ...activity, dateTime })) || []}
        disablePadding
        skeleton={{ loading, size: 1 }}
        component={TSlot}
      />
    </div>
  )
}

const TimeLineEl = memo(
  ({
    style,
    index,
    data: { loading, rows, setSize }
  }: {
    style: CSSProperties
    index: number
    data: { loading: boolean; rows: ActivityRow[]; setSize: (index: number, height: number) => void }
  }) => {
    const [dateTime] = Object.keys(rows[index])
    const [times] = Object.values(rows[index])
    const { classes } = useStyles()

    return (
      <TimeLineItem
        style={style}
        oppositeComponent={
          <TimelineOppositeContent classes={{ root: classes.timeCardWrapper }}>
            <TimeCard time={dateTime ? formatTime(dateTime) : dateTime} />
          </TimelineOppositeContent>
        }
        disablePadding
        component={TimeLineComponent}
        loading={loading}
        item={{ dateTime, activities: times, loading, setSize, index }}
        index={index}
      />
    )
  }
)

const ActivitiesUngrouped = ({
  width: containerWidth,
  reset,
  onLoading
}: {
  width: number
  reset: () => void
  onLoading: (loading: boolean) => void
}) => {
  const { search } = useLocation()
  const { classes } = useStyles()
  const { slots = [], hasMore, loading: slotsLoading, more, reload } = useActivitiesSlots()
  const groupLists = useActivitiesGroups()
  const { result: userKeyResult } = useEntityEndpoint<{ results: ProfileType } | null>(`/me/profile`)
  const myUserKey = userKeyResult?.results.UserKeyMd5
  const sizeMap = useRef<{ [key: number]: number }>({})
  const { outerElementType, ref } = useVirtualized<{ loading: boolean; rows: ActivityRow[] }>(true)

  useEffect(() => {
    if (ref.current) {
      ref.current.resetAfterIndex(0)
    }
  }, [ref, search])

  const loading = slotsLoading || !myUserKey

  useEffect(() => {
    onLoading(loading)
  }, [loading])

  const list = slots.map((slot) => {
    const [date] = Object.keys(slot)
    const [timeSlots] = Object.values(slot)

    return {
      [date]: timeSlots.map((timeSlot) => {
        const [time] = Object.keys(timeSlot)
        const [timeItems] = Object.values(timeSlot) as ActivityItem[][]

        const lists = myUserKey ? groupLists(timeItems, time, myUserKey) : []
        return { [time]: lists }
      })
    }
  })

  const rows = loading
    ? getSkeletonSize(7)
    : list
        .map((slot) => {
          const [timeSlots = []] = Object.values(slot)
          return timeSlots
        })
        .flat()

  const setSize = (index: number, size: number) => {
    if (ref) {
      const condition = loading ? true : (sizeMap.current[index] || 0) < size

      sizeMap.current = { ...sizeMap.current, [index]: condition ? size : sizeMap.current[index] }
      ref.current.resetAfterIndex(index)
    }
  }

  const getSize = (index: number) => sizeMap.current[index] || 50

  const innerElementType = useCallback(
    ({ children, style }) => (
      <div style={style}>
        <Timeline position="right" loading={loading}>
          {children}
        </Timeline>
      </div>
    ),
    [loading]
  )

  return (
    <>
      {!loading && list.length === 0 && (
        <Box width={containerWidth}>
          <Empty
            title="No activities were found"
            subTitle="Nothing was found for the given date and filter parameters. Try change your input data."
            icon={<FontAwesomeIcon size="5x" icon={['fat', 'calendar-clock']} style={{ color: '#A7A7A7' }} />}
            action={
              <Button variant="link" onClick={reset} color="primary" disablePT>
                clear all filters
              </Button>
            }
          />
        </Box>
      )}
      {(loading || list.length > 0) && reload && more && (
        <Box width={containerWidth} className={classes.wrapper}>
          <InfiniteScroll loading={loading} dataLength={rows.length} next={more} refreshFunction={reload} hasMore={hasMore}>
            <List
              itemCount={rows.length}
              itemSize={getSize}
              width={containerWidth}
              height={window.innerHeight}
              ref={ref}
              overscanCount={2}
              itemData={{ rows, loading, setSize }}
              innerElementType={innerElementType}
              outerElementType={outerElementType}
            >
              {TimeLineEl}
            </List>
          </InfiniteScroll>
        </Box>
      )}
    </>
  )
}

export default ActivitiesUngrouped
