import { InteractionType, PublicClientApplication, AuthenticationResult, BrowserAuthError } from '@azure/msal-browser'

import config, { apiHost } from '../config'
import { defaultSettings } from 'utils/fetchUtils'
import Paths from 'Paths'

type PromiseRes = {
  res: (val: AuthenticationResult | PromiseLike<AuthenticationResult>) => void
  rej: (resp: { type: keyof CustomErrorType; error: Error }) => void
}

const logErr = async ({ actionType, details }: { actionType: string; details: string }) => {
  await fetch(`${apiHost}/api/log`, {
    method: 'POST',
    body: JSON.stringify({ actionType, details }),
    ...defaultSettings
  })
}

let maxAttempts = 3

let attempts = 0
let activeRequest = false
let queue: { res: PromiseRes['res']; rej: PromiseRes['rej'] }[] = []

const clearQueue = () => {
  queue = []
  activeRequest = false
}

let lastTokenRefreshTime = 0;
const refreshTokenInterval = 30 * 60 * 1000; // 30 minutes in milliseconds

const requestToken = async (rs: PromiseRes['res'], rj: PromiseRes['rej']) => {
  const settings = await config()

  if (!settings) return
  const pca = await PublicClientApplication.createPublicClientApplication(settings.msalConfiguration)

  const scopes = [`${settings.clientId}/.default`]

  const loginRedirect = async (errorMessage: string) => {
    const numAttempts = +(localStorage.getItem('numAttempts') || '0')
    if (numAttempts < maxAttempts) {
      localStorage.setItem('numAttempts', `${numAttempts + 1}`)
      const resp = await pca.handleRedirectPromise()

      if (!resp?.account) {
        return pca.loginRedirect({ scopes })
      }
    }
    rj({ type: 'signInError', error: Error(errorMessage) })
    clearQueue()
  }

  const loginPopup = async (errorMessage: string) => {
    const numAttempts = +(localStorage.getItem('numAttempts') || '0')
    if (!numAttempts) {
      try {
        await pca.loginPopup({ scopes })
      } catch (error) {
        if (error instanceof BrowserAuthError && error.errorCode === 'popup_window_error') {
          rj({ type: 'popupBlockedError', error })
          clearQueue()
        } else {
          attempts += 1
          if (attempts < maxAttempts) {
            requestToken(rs, rj)
          } else {
            rj({ type: 'signInError', error: Error(errorMessage) })
            clearQueue()
          }
        }
      }
    }
  }

  if (pca.getAllAccounts().length > 0) {
    try {
      const currentTime = Date.now()
      const resp = await pca.acquireTokenSilent({
        scopes,
        account: pca.getAllAccounts()[0],
        forceRefresh: currentTime - lastTokenRefreshTime >= refreshTokenInterval
      })
      lastTokenRefreshTime = currentTime;
      rs(resp)
      queue.map(({ res }) => res(resp))
      localStorage.removeItem('numAttempts')
      return clearQueue()
    } catch (reason: any) {
      const tryAgain = async () => {
        console.log('GET_TOKEN: acquireTokenSilent', reason)
        await logErr({ actionType: 'GET_TOKEN: acquireTokenSilent', details: reason.errorMessage })
        window.top === window.self ? loginRedirect(reason.errorMessage) : loginPopup(reason.errorMessage)
      }
      return tryAgain()
    }
  }

  try {
    const resp = await pca.acquireTokenPopup({ scopes })
    rs(resp)
    queue.map(({ res }) => res(resp))
    localStorage.removeItem('numAttempts')
    return clearQueue()
  } catch (reason: any) {
    const tryAgain = async () => {
      console.log('GET_TOKEN: acquireTokenPopup', reason)
      await logErr({ actionType: 'GET_TOKEN: acquireTokenPopup', details: reason.errorMessage })
      window.top === window.self ? loginRedirect(reason.errorMessage) : loginPopup(reason.errorMessage)
    }
    return tryAgain()
  }
}

export const getToken = () => {
  return new Promise<AuthenticationResult>(async (res, rej) => {
    if (!activeRequest) {
      activeRequest = true
      return requestToken(res, rej)
    } else {
      queue.push({ res, rej })
    }
  })
}

export const getGraphToken = async () => {
  const settings = await config()
  if (!settings) return

  const pca = await PublicClientApplication.createPublicClientApplication(settings.msalConfiguration)

  return pca.acquireTokenSilent({
    scopes: [`https://graph.microsoft.com/User.Read`],
    account: pca.getAllAccounts()[0]
  })
}

export const logout = async (params?: { postLogoutRedirectUri: string }) => {
  const settings = await config()
  if (!settings) return

  const pca = await PublicClientApplication.createPublicClientApplication(settings.msalConfiguration)

  const logoutRequest = {
    account: pca.getAllAccounts()[0]
  }

  const logoutType = window.self !== window.top ? InteractionType.Popup : InteractionType.Redirect

  window.sessionStorage.removeItem('login_entry')

  if (logoutType === InteractionType.Popup) {
    await pca.logoutPopup({
      ...logoutRequest,
      postLogoutRedirectUri: `${window.location.origin}/blank.html`,
      mainWindowRedirectUri: Paths._logoutPage
    })
  } else {
    await pca.logoutRedirect({ ...logoutRequest, ...(params?.postLogoutRedirectUri ? { postLogoutRedirectUri: params.postLogoutRedirectUri } : {}) })
  }
}
