import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'

import { Button, IconButton, Select } from '_shared/buttons'
import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'

import Audit from '_core/components/Audit'
import ReportIncorrectDataDialog from '_core/components/dialogs/ReportIncorrectMarketData'
import DrawerMenu from '_core/components/DrawerMenu'
import { Narrow, useWide, Wide } from '_core/components/layout'
import Item from '_core/components/lists/Item'
import { MDByLine, MDCompanyByLine2 } from '_core/components/MarketDataSummary'
import ProfileSummary from '_core/components/ProfileSummary'
import SidepanelLink from '_core/components/SidepanelLink'
import Widget from '_core/components/Widget'

import useDialog from '_core/hooks/useDialog'
import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'

import Paths from 'Paths'

const salesForceUrl = Paths._salesforce

const CompanyHeader = ({
  isSalesforceEnabled,
  marketDataIntegration,
  ...props
}: {
  loading: boolean
  isSalesforceEnabled: boolean | undefined
  marketDataIntegration: MarketDataIntegration | undefined
  marketData: { [key: string]: any } | null
} & CompanyType) => {
  const { id } = useParams<{ id: string }>()
  const { enabled: enabledMarketData, showSimilarCompanies } = marketDataIntegration || {}

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [tagsList, setTagsList] = useState<UngroupedTag[]>()

  const renderButtons = typeof isSalesforceEnabled === 'boolean' && typeof enabledMarketData === 'boolean' && props.CompanyMd5

  const wide = useWide()
  const { isDialogOpened: openedReportDialog, openDialog: openReportDialog, closeDialog: closeReportDialog } = useDialog()

  const { payloads } = useSidepanelPayloads()

  useEffect(() => {
    const tagNames = props.Tags?.[0].TagNames
    if (!tagsList && tagNames) {
      setTagsList(
        tagNames.map(({ Category, Tag }: { Category: string; Tag: string } & { Md5: string }) => ({ categoryName: Category, tagName: Tag }))
      )
    }
  }, [props.Tags])

  useEffect(() => {
    if (payloads && payloads.action === 'UPDATE_TAGS') {
      if (Array.isArray(payloads.value)) {
        const { identifier, tags } = payloads.value[0]
        if (identifier === id) {
          setTagsList(tags)
        }
      }
    }
    if (tagsList) {
      if (payloads && payloads.action === 'SYNC_TAGS') {
        const { taggableType, categoriesRenames, tagsRenames, deprecations } = payloads.value
        if (taggableType === 'companies') {
          const nonDeprecated = tagsList.filter(
            ({ categoryName, tagName }) =>
              !deprecations.find((deprecated) => deprecated.categoryName === categoryName && deprecated.tagName === tagName)
          )
          const renamed = nonDeprecated.map(({ categoryName, tagName }) => {
            const { newCategoryName = categoryName } = categoriesRenames.find(({ oldCategoryName }) => categoryName === oldCategoryName) || {}

            const { newTagName = tagName } =
              tagsRenames.find(({ oldCategoryName, oldTagName }) => categoryName === oldCategoryName && tagName === oldTagName) || {}
            return { categoryName: newCategoryName, tagName: newTagName }
          })
          setTagsList(renamed)
        }
      }
    }
  }, [payloads])

  const marketDataActions = useMemo(
    () => [
      {
        name: 'Similar Companies',
        icon: ['fas', 'diagram-venn'],
        link: `/companies/${id}/similar?clearSearch=true&name=${props.CompanyNameText}`
      },
      {
        name: 'C Suite',
        icon: ['fas', 'user-tie'],
        link: `/companies/${id}/c-suite?name=${props.CompanyNameText}`
      },
      {
        name: 'Report incorrect data',
        icon: ['far', 'message-exclamation'],
        action: openReportDialog
      },
      {
        name: 'Learn more',
        icon: ['far', 'question-circle'],
        externalLink: 'https://help.dotalign.com/article/x8f8c6vw1i-dot-align-market-data'
      }
    ],
    [props.CompanyMd5, props.marketData?.id]
  )

  const MarketDataButton = useCallback(
    () => (
      <Select
        disableRipple
        component="span"
        color="primary"
        variant="outlined"
        endIcon={<FontAwesomeIcon style={{ width: 8 }} color="#979797" icon={['far', 'chevron-down']} />}
      >
        Market Data
      </Select>
    ),
    []
  )

  const buttonsMap = [
    {
      name: 'Edit',
      icon: ['far', 'edit'],
      link: props.CompanyMd5 ? `/companies/${props.CompanyMd5}/edit` : '',
      condition: !wide || (wide && renderButtons),
      menuItem: enabledMarketData && !wide
    },
    {
      name: 'Merge',
      icon: ['far', 'merge'],
      link: props.CompanyMd5 ? `${Paths._merge}/companies?ids=${props.CompanyMd5}` : '',
      condition: !wide || (wide && renderButtons),
      menuItem: enabledMarketData && !wide
    },
    {
      name: 'Audit',
      component: <Audit name={props.CompanyNameText} type="companies" menuItem={enabledMarketData} />,
      condition: !wide || (wide && renderButtons),
      menuItem: enabledMarketData && !wide
    },
    {
      name: 'Save to Salesforce',
      icon: ['far', 'cloud-upload-alt'],
      link: `${salesForceUrl}/account/${props.CompanyMd5}`,
      condition: isSalesforceEnabled && renderButtons && props.CompanyMd5,
      menuItem: enabledMarketData && !wide
    }
  ].filter((button) => button.condition)

  const toggleDrawer = () => setDrawerOpen((prevState: boolean) => !prevState)

  const Buttons = (
    <>
      {enabledMarketData && !showSimilarCompanies && (
        <Wide>
          <Item component={MarketDataButton} item={{ menu: { actions: marketDataActions } }} disablePadding />
        </Wide>
      )}

      {buttonsMap
        .filter((el) => !el.menuItem)
        .map((button: { [key: string]: any }) => {
          if (button.component) {
            return <Box key={button.name}>{button.component}</Box>
          }

          return (
            <Box key={button.name}>
              <SidepanelLink linkProps={{ to: button.link }} sidepanel={true}>
                <Wide>
                  <Button color="primary" startIcon={<FontAwesomeIcon icon={button.icon} style={{ fontSize: 14 }} />}>
                    {button.name}
                  </Button>
                </Wide>
                <Narrow>
                  <IconButton
                    color="primary"
                    hint={button.name}
                    loading={!props.CompanyMd5}
                    icon={button.icon}
                    style={{ fontSize: 14, maxWidth: 38 }}
                  />
                </Narrow>
              </SidepanelLink>
            </Box>
          )
        })}

      {enabledMarketData && (
        <Narrow>
          <Box mr={-1}>
            <IconButton
              color="primary"
              icon={['far', 'ellipsis-v']}
              hint="Menu"
              size="small"
              loading={!props.CompanyMd5}
              disablePX
              onClick={toggleDrawer}
            />
          </Box>
        </Narrow>
      )}
    </>
  )

  const { location, industry, employee_count, linkedin_url, facebook_url, twitter_url } = props.marketData || {}

  const actions = [
    (props.loading || (!props.loading && props.BestUrlText && !enabledMarketData)) && (
      <Skeleton condition={!props.BestUrlText} width="100px">
        <Tooltip title={`Send an email to ${props.BestUrlText}`}>
          <a href={`//${props.BestUrlText}`} target="_blank" rel="noopener noreferrer" style={{ color: 'rgba(27, 149, 187, 0.87)' }}>
            <FontAwesomeIcon icon={['far', 'external-link']} style={{ fontSize: 15, paddingRight: 4 }} color="rgba(27, 149, 187, 0.87)" />
            {props.BestUrlText}
          </a>
        </Tooltip>
      </Skeleton>
    ),
    !props.loading && props.BestUrlText && enabledMarketData && (
      <IconButton
        icon={['far', 'globe']}
        color="primary"
        hint="Show website"
        disablePadding
        component="a"
        href={`//${props.BestUrlText}`}
        loading={!props.BestUrlText}
        rel="noopener noreferrer"
        target="_blank"
        size="small"
      />
    ),
    <IconButton
      key="linkedin"
      icon={['fab', 'linkedin']}
      color="primary"
      hint="Show Linkedin profile"
      disablePadding
      component="a"
      href={linkedin_url ? `https://${linkedin_url}` : `https://www.linkedin.com/search/results/companies/?keywords=${props.CompanyNameText}`}
      loading={!props.CompanyNameText}
      rel="noopener noreferrer"
      target="_blank"
      size="small"
    />,
    ((enabledMarketData && !props.marketData) || (!props.loading && twitter_url)) && (
      <IconButton
        icon={['fab', 'twitter']}
        color="primary"
        hint="Show Twitter profile"
        component="a"
        disablePadding
        href={`https://${twitter_url}`}
        loading={!twitter_url}
        rel="noopener noreferrer"
        target="_blank"
        size="small"
      />
    ),
    ((enabledMarketData && !props.marketData) || (!props.loading && facebook_url)) && (
      <IconButton
        icon={['fab', 'facebook']}
        color="primary"
        hint="Show Facebook profile"
        component="a"
        disablePadding
        href={`https://${facebook_url}`}
        loading={!facebook_url}
        target="_blank"
        rel="noopener noreferrer"
        size="small"
      />
    )
  ].filter((action) => action)

  return (
    <>
      <Widget scope="none">
        <ProfileSummary
          title={props.CompanyNameText}
          loading={props.loading}
          url={props.BestUrlText}
          tags={tagsList}
          showAllTagsLink={`${Paths._companies}/${id}/tags?name=${props.CompanyNameText}`}
          editTagsLink={`${Paths._companies}/${id}/tags/edit?name=${props.CompanyNameText}`}
          score={props.Score}
          introducers={props.Introducers}
          buttons={Buttons}
          actions={actions}
          {...(enabledMarketData
            ? {
                enabledMarketData: true,
                byline: <MDByLine marketData={!!props.marketData} locationName={location?.name} />,
                byline2: <MDCompanyByLine2 marketData={!!props.marketData} industry={industry} employee_count={employee_count} />
              }
            : {
                enabledMarketData: false
              })}
        />
      </Widget>
      {enabledMarketData && (
        <Narrow>
          <DrawerMenu
            open={drawerOpen}
            toggleDrawer={toggleDrawer}
            items={showSimilarCompanies ? buttonsMap : [...buttonsMap, ...marketDataActions]}
          />
        </Narrow>
      )}
      <ReportIncorrectDataDialog opened={openedReportDialog} close={closeReportDialog} marketData={JSON.stringify(props.marketData)} />
    </>
  )
}

export default CompanyHeader
