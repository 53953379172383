import React, { ReactElement, useEffect, useRef } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation, Link } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import Empty from '_core/components/Empty'
import InfiniteScroll from '_core/components/InfiniteScroll'
import { Wide, Narrow, useWide } from '_core/components/layout'
import AutoHideOnScroll from '_core/components/layout/autohide-on-scroll'
import Topbar from '_core/components/Topbar'

import useEntityEndpoint from '_core/hooks/useEntityEndpoint'
import useSearchQuery from '_core/hooks/useSearchQuery'

import { updateQuerystring } from '_core/helpers/browser'
import { getSkeletonSize } from '_core/helpers/skeleton'

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    position: 'relative'
  },
  edit: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'absolute',
    right: 0,
    top: '20px'
  },
  progress: {
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center'
  },
  scrollZone: {
    overflowY: 'auto',
    height: '100%'
  }
}))

const Filter: React.FC<any> = (props: any) => (
  <div style={{ position: 'fixed', zIndex: 1, top: 0, left: 0, width: '100%' }}>
    <div style={{ height: '57px', background: '#fff', borderBottom: '8px #ECEEF0 solid' }}>
      <FontAwesomeIcon onClick={() => props.handle()} icon={['far', 'search']} />
      <FontAwesomeIcon onClick={() => props.handle()} icon={['far', 'filter']} />
      {props.open && (
        <>
          <Link to={`?SearchTerm=CEO`} style={{ color: 'blue' }}>
            Apply
          </Link>
        </>
      )}
    </div>
    {props.open && (
      <div style={{ height: 'calc(100vh - 61px)', background: '#fff', padding: '16px' }}>
        {props.schema.map((p: any) => (
          <div key={p.param}>
            <Typography variant="h4" style={{ margin: 0 }}>
              {p.label}
            </Typography>
            {p.options.map((o: any) => (
              <div key={o.value}>
                <Typography>{o.text}</Typography>
              </div>
            ))}
          </div>
        ))}
      </div>
    )}
  </div>
)

const overrideUrl = (initialUrl: any, pageSize?: number, page = 0) => {
  if (!initialUrl) return

  let url = initialUrl

  if (page && pageSize) url = updateQuerystring(url, 'Skip', (page - 1) * pageSize)

  return url
}

type URLProps = {
  url: string | null
  list?: boolean
  infinite?: boolean
  urls?: never
}

type URL = {
  url: string
  key: string
  merge?: boolean
  single?: boolean
}

type URLsProps = {
  urls: URL[] | null
  url?: never
  list?: never
  infinite?: never
}

type DefaultProps = {
  id: string
  component: React.FC<any>
  keepMounted?: boolean
  pageSize?: number
  updatePageSize?: (v: NumberToString<RowPerPageOptionsType>) => void
  filterSchema?: any
  search?: boolean | string
  subHeader?: (props: any) => string | ReactElement
  subHeaderHeight?: number
  empty?: string | ReactElement
  emptySubtitle?: string | ReactElement
  emptyIcon?: any
  nativeBack?: boolean
  scrollableTarget?: string
  autoHideOnScroll?: boolean
  forceNarrow?: boolean
  infiniteLoader?: ReactElement
  onLoading?: (isLoading: boolean, result: any) => void
  closeExtensionIfEmpty?: boolean
} & (URLsProps | URLProps)

type DynamicEntityProps<T> = T extends { extraProps: unknown } ? T['extraProps'] & DefaultProps : DefaultProps

const DynamicEntity = <T extends { extraProps?: { addprops: Record<string, unknown> } }>(props: DynamicEntityProps<T>) => {
  const { subHeader, search, nativeBack, pageSize, updatePageSize, onLoading, subHeaderHeight, closeExtensionIfEmpty } = props

  const wide = useWide()
  const { queryParams } = useSearchQuery<GridParams>()
  const { page } = queryParams

  const url = 'url' in props ? props.url : undefined
  const urls = 'urls' in props ? props.urls : undefined
  const infinite = Boolean(props.infinite && props.list && (!wide || props.forceNarrow))

  const { result, loading, more, reload, paging } = useEntityEndpoint(
    overrideUrl(url, pageSize, !infinite ? +(page || 0) : 0),
    urls,
    pageSize,
    search,
    infinite
  )

  useEffect(() => {
    onLoading && onLoading(loading, result)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  const [filterPanel, setFilterPanel] = React.useState(false)
  const dataLength = result ? (result.data || result.results)?.length : 0

  const component = Component<any>({
    ...props,
    loading,
    result,
    more,
    reload,
    paging,
    dataLength,
    updatePageSize
  })

  /* (!result.total_item_count && dataLength === 0)
      this code is added for the interactions
      if it breaks something, remove and move Empty inside interactions */
  if (props.empty && !loading && (result.total_item_count === 0 || (!result.total_item_count && dataLength === 0)))
    return (
      <Empty
        title={props.empty || ''}
        {...(props.emptySubtitle && { subTitle: props.emptySubtitle })}
        {...(props.emptyIcon && { icon: props.emptyIcon })}
        closeExtension={closeExtensionIfEmpty}
      />
    )

  return (
    <>
      <Wide>
        {props.filterSchema && <Filter schema={props.filterSchema} more={more} open={filterPanel} handle={() => setFilterPanel(!filterPanel)} />}
        {component}
      </Wide>
      <Narrow>
        {subHeader ? ( // check is temporary as only pages with subheaders (e.g: 'Companies - 800 items') use the new UI's topbar
          <Topbar
            autoHideOnScroll={props.autoHideOnScroll}
            nativeBack={nativeBack}
            height={subHeaderHeight}
            sub={
              <Skeleton condition={loading} width={96}>
                <>{result ? subHeader(result) : ''}</>
              </Skeleton>
            }
          />
        ) : null}
        {props.filterSchema && <Filter schema={props.filterSchema} more={more} open={filterPanel} handle={() => setFilterPanel(!filterPanel)} />}
        {props.autoHideOnScroll ? <AutoHideOnScroll>{component}</AutoHideOnScroll> : component}
      </Narrow>
    </>
  )
}

export default DynamicEntity

type CProps = {
  loading: boolean
  more: () => void
  reload: () => void
  paging: {
    size: number | undefined
    page: number
  }
  dataLength: number
  result: any
}

const Component = <T extends { extraProps?: { addprops: Record<string, unknown> } }>(props: T['extraProps'] & CProps & DefaultProps) => {
  const { classes, cx } = useStyles()
  const { id, list, addprops, loading, more, reload, paging, dataLength, infinite, scrollableTarget, updatePageSize, forceNarrow, infiniteLoader } =
    props
  const scrollZone = useRef<HTMLDivElement>(null)
  const location = useLocation()
  const search = updateQuerystring(location.search, 'page', '')

  React.useEffect(() => {
    scrollZone.current && scrollZone.current.scrollTo(0, 0)
  }, [search])

  const commonProps = {
    loading,
    forceNarrow,
    ...addprops
  }

  if (loading && !props.keepMounted) {
    if (!scrollableTarget) return <props.component {...commonProps} />

    return (
      <div id={scrollableTarget} ref={scrollZone} className={classes.scrollZone}>
        <props.component {...commonProps} />
      </div>
    )
  }

  const result = !loading ? props.result : {}
  const items = !loading ? result.data || result.results : []
  const total = result.total_item_count

  const listProps = {
    items,
    total,
    setPageSize: updatePageSize
  }

  if ((result.data || result.results)?.errorMessage) return <props.component error={result.data || result.results} {...commonProps} />

  if (list && infinite) {
    return (
      <>
        <Narrow forceNarrow={props.forceNarrow}>
          <div id={scrollableTarget} ref={scrollZone} className={cx({ [classes.scrollZone]: !!scrollableTarget })}>
            <InfiniteScroll
              loader={infiniteLoader}
              loading={loading}
              dataLength={dataLength}
              next={more}
              refreshFunction={reload}
              hasMore={result.are_more}
              scrollableTarget={scrollableTarget}
            >
              <div className={classes.wrapper}>
                <props.component {...commonProps} {...listProps} />
              </div>
            </InfiniteScroll>
          </div>
        </Narrow>
        <Wide forceNarrow={props.forceNarrow}>
          <div className={classes.wrapper}>{result && items && <props.component paging={paging} {...commonProps} {...listProps} />}</div>
        </Wide>
      </>
    )
  }

  if (list) return <div className={classes.wrapper}>{result && items && <props.component paging={paging} {...commonProps} {...listProps} />}</div>

  const itms = props.keepMounted && loading ? getSkeletonSize(1) : items
  return (
    <div className={classes.wrapper}>
      {result && items && itms.map((item: any, index: number) => <props.component key={`${id}__${index}`} {...item} {...commonProps} />)}
    </div>
  )
}
