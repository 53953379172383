import { useMemo } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ListItem, List, Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Avatar from '_shared/Avatar'
import Card, { CardContent } from '_shared/Card'
import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import EmptyText from '_core/components/EmptyText'
import InfiniteScroll from '_core/components/InfiniteScroll'
import Repeater from '_core/components/lists/Repeater'

import DynamicEntity from '_core/DynamicEntity'

import { mergeUrlWithParams } from 'utils/httpUtils'
import { formatDateTime } from 'utils/Utils'

const useStyles = makeStyles()((theme) => ({
  card: {
    overflow: 'unset',
    maxWidth: '100%'
  },
  heading: {
    borderBottom: `1px solid ${theme.palette.background.darker}`,
    '& > div': {
      marginBottom: 0
    }
  },
  source: {
    alignItems: 'flex-start',
    background: 'border-box'
  },
  icon: {
    color: theme.palette.text.secondary,
    width: theme.spacing(3),
    height: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1)
  },
  scrollZone: {
    overflowY: 'auto',
    height: 'calc(100% - 68.5px)'
  },
  contributor: {
    marginLeft: theme.spacing(1)
  },
  list: {
    '& > div:last-child': {
      borderRadius: '0px 0px 8px 8px'
    }
  },
  sourcesCards: {
    paddingTop: 0,
    '& > div > div:first-of-type > li': {
      paddingTop: 0
    }
  }
}))

export const sourceTypesMap: { [key in GraphSourceType]: { text: string; icon: IconProp } } = {
  CalendarEntries: {
    text: 'Calendar events',
    icon: ['far', 'calendar-alt']
  },
  ContactCards: {
    text: 'Contact cards',
    icon: ['far', 'address-card']
  },
  EmailMessages: {
    text: 'Email messages',
    icon: ['far', 'envelope']
  },
  Manual: {
    text: 'Manual',
    icon: ['far', 'envelope']
  }
}

const CardItem = (props: { title: string; upline?: string; byline?: string }) => {
  const { classes } = useStyles()
  const { title, upline, byline } = props
  const loading = !title

  return (
    <ListItem>
      <Card round variant="outlined" className={classes.card}>
        <CardContent>
          {(loading || upline) && (
            <Skeleton condition={loading} width="100%" height={21}>
              <Typography color="text.secondary">{upline}</Typography>
            </Skeleton>
          )}
          <Skeleton condition={loading} width="100%" height={21}>
            <Typography variant="h4" semiBold noWrap>
              {title}
            </Typography>
          </Skeleton>
          {(loading || byline) && (
            <Skeleton condition={loading} width="100%" height={21}>
              <Typography>{byline}</Typography>
            </Skeleton>
          )}
        </CardContent>
      </Card>
    </ListItem>
  )
}

const Meetings = (props: any) => {
  const items = props.items?.map((item: any) => ({
    title: item.Subject || '(No subject)',
    upline: formatDateTime(item.StartDateTime),
    byline: item.Participants?.data.find((p: any) => p.Role === 'From')?.DisplayName
  }))

  return (
    <List>
      <Repeater disablePadding items={items} component={CardItem} skeleton={{ size: 5, loading: props.loading }} />
    </List>
  )
}

const Contacts = (props: any) => {
  const items = props.items?.map((item: any) => ({
    title: item.PersonName || item.Emails[0].Email,
    upline: formatDateTime(item.CreatedDateTime),
    byline: item.JobTitle
  }))

  return (
    <List>
      <Repeater disablePadding items={items} component={CardItem} skeleton={{ size: 5, loading: props.loading }} />
    </List>
  )
}

const Emails = (props: any) => {
  const items = props.items?.map((item: any) => ({
    title: item.Subject || '(No subject)',
    upline: formatDateTime(item.ReceivedTime),
    byline: item.SenderValidNameText
  }))

  return (
    <List>
      <Repeater disablePadding items={items} component={CardItem} skeleton={{ size: 5, loading: props.loading }} />
    </List>
  )
}

type SourcePropsType = {
  icon?: IconProp
  text?: string
  type?: GraphSourceType
  openFacts?: (type: Exclude<GraphSourceType, 'Manual'>) => void
  date?: string
}

const Source = ({ icon, text, type, openFacts, date }: SourcePropsType) => {
  const { classes } = useStyles()

  const openSourceFacts = () => {
    if (openFacts && (type === 'CalendarEntries' || type === 'EmailMessages' || type === 'ContactCards')) {
      openFacts(type)
    }
  }

  return (
    <ListItem className={classes.source} onClick={openSourceFacts}>
      {icon && (
        <Box className={classes.icon}>
          <FontAwesomeIcon icon={icon} size="sm" />
        </Box>
      )}
      <div>
        <Skeleton condition={!text} width={150} height={21}>
          <Typography>{text}</Typography>
        </Skeleton>
        {date && (
          <Typography color="text.secondary" variant="body1">
            {formatDateTime(date)}
          </Typography>
        )}
      </div>
    </ListItem>
  )
}

const Sources = (props: {
  contributor: string
  sourceTypes: IdentifierSource[]
  openFacts?: (data: { type: GraphSourceType; key: string }) => void
}) => {
  const { classes } = useStyles()
  const { contributor, sourceTypes } = props

  const openFacts = (type: GraphSourceType) => {
    props.openFacts && props.openFacts({ type, key: contributor })
  }

  return (
    <ListItem>
      <Card round variant="outlined" className={classes.card}>
        <CardContent className={classes.heading}>
          <Skeleton condition={!contributor} width={150} height={21}>
            <Box display="flex">
              <Avatar name={contributor} userKey={contributor} size="xs" mode="dark" hideName />
              <Tooltip title={contributor}>
                <Typography semiBold className={classes.contributor} noWrap>
                  {contributor}
                </Typography>
              </Tooltip>
            </Box>
          </Skeleton>
        </CardContent>
        <List disablePadding>
          <Repeater
            disablePadding
            className={classes.list}
            items={
              sourceTypes?.map(({ graphSourceType, latest }) => ({
                type: graphSourceType,
                date: latest,
                highlightOnHover: props.openFacts && graphSourceType !== 'Manual',
                ...(props.openFacts && { openFacts }),
                ...sourceTypesMap[graphSourceType]
              })) || []
            }
            component={Source}
            skeleton={{ size: 3, loading: !sourceTypes }}
          />
        </List>
      </Card>
    </ListItem>
  )
}

type AuditSourcesProps = {
  items:
    | {
        contributor: string
        sourceTypes: IdentifierSource[]
        openFacts?: (data: { type: Exclude<GraphSourceType, 'Manual'>; key: string }) => void
      }[]
    | undefined
  loading: boolean
  more: () => void
  reload: () => void
  hasMore: boolean
  total: number
}

export const SourceFacts = (props: {
  identifier: string
  contributor: string
  requestType: AuditIdentifierSourceRequestType
  graphSourceType: Exclude<GraphSourceType, 'Manual'>
}) => {
  const { identifier, requestType, graphSourceType, contributor } = props

  const factsMap = useMemo(
    () => ({
      EmailMessages: {
        id: 'email_messages',
        scrollableTarget: 'email_messages',
        component: Emails
      },
      CalendarEntries: {
        id: 'calendar_events',
        scrollableTarget: 'calendar_events',
        component: Meetings
      },
      ContactCards: {
        id: 'contacts',
        scrollableTarget: 'contacts',
        component: Contacts
      }
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [graphSourceType]
  )

  return (
    <DynamicEntity
      {...factsMap[graphSourceType]}
      url={mergeUrlWithParams('/audit/fact', {
        Identifier: identifier,
        ContributorKey: contributor,
        RequestType: requestType,
        GraphSourceType: graphSourceType
      })}
      pageSize={20}
      list
      infinite
    />
  )
}

const AuditSources = (props: AuditSourcesProps) => {
  const { classes } = useStyles()
  const { items, loading, more, reload, hasMore, total } = props

  return (
    <div id="audit_sources" className={classes.scrollZone}>
      <InfiniteScroll loading={loading} dataLength={total} next={more} refreshFunction={reload} hasMore={hasMore} scrollableTarget="audit_sources">
        <List classes={{ root: classes.sourcesCards }}>
          <Repeater
            disablePadding
            items={items?.filter(({ sourceTypes }) => sourceTypes.length) || []}
            component={Sources}
            skeleton={{ size: 5, loading: !items }}
            empty={
              <Box px={2}>
                <EmptyText
                  text="There are no explicit contributors for this identifier, and it was likely sourced from the market data that ships with the DotAlign
                  application"
                />
              </Box>
            }
          />
        </List>
      </InfiniteScroll>
    </div>
  )
}

export default AuditSources
