import { CSSProperties } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider } from '@mui/material'
import { parsePhoneNumber } from 'libphonenumber-js'
import moment from 'moment'
import { Link, useParams } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import Avatar from '_shared/Avatar'
import { IconButton } from '_shared/buttons'
import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import ExternalTag from '_core/components/ExternalTag'
import Repeater from '_core/components/lists/Repeater'
import ProfileItem from '_core/components/ProfileItem'

import { mergeUrlWithParams } from 'utils/httpUtils'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  chip: {
    maxWidth: '100%',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    marginRight: theme.spacing(1)
  },
  byLineIcon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
    fontSize: 16,
    width: '16px'
  },
  byLine: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '22px',
    color: '#202020',
    textAlign: 'start',
    maxWidth: '100%'
  },
  space: {
    paddingTop: '3px'
  },
  iconButton: {
    paddingTop: theme.spacing(2)
  },
  accordion: {
    '&:before': {
      height: 0
    },
    '&[class*="expanded"]': {
      margin: 0
    }
  },
  summary: {
    padding: 0,
    alignItems: 'end'
  },
  accordionContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1)
  },
  accordionDetails: {
    padding: 0,
    '& > div': {
      gap: theme.spacing(1)
    }
  }
}))

interface PersonDataProps {
  urlType?: 'people' | 'companies'
  id: string
  fullName: string
  locationName: string
  twitterUrl: string
  linkedinUrl: string
  industry: string
  jobTitle: string
  jobCompanyMd5: string
  jobCompanyName: string
  jobCompanyWebsite: string
  jobCompanySize: string
  jobCompanyLocationName: string
  jobCompanyIndustry: string
  jobCompanyFacebookUrl: string
  jobCompanyTwitterUrl: string
  jobCompanyLinkedinUrl: string
  workEmail: string
  skills: string[]
  interests: string[]
  experience: { [key: string]: any }[]
  education: { [key: string]: any }[]
  phoneNumbers: string[]
  mobilePhone: string
}

const urls = {
  companies: Paths._companies,
  people: Paths._people
}

const format = 'MM/YYYY'

const PersonData = ({
  urlType,
  id,
  locationName,
  industry,
  linkedinUrl,
  twitterUrl,
  jobTitle,
  jobCompanyMd5,
  jobCompanyName,
  jobCompanyWebsite,
  jobCompanySize,
  jobCompanyLocationName,
  jobCompanyIndustry,
  jobCompanyFacebookUrl,
  jobCompanyTwitterUrl,
  jobCompanyLinkedinUrl,
  workEmail,
  skills,
  interests,
  mobilePhone,
  ...props
}: PersonDataProps) => {
  const { id: entityId } = useParams<{ id: string }>()
  const { classes, cx } = useStyles()

  const phoneNumbers = props.phoneNumbers?.filter((phone) => phone !== mobilePhone)
  const experience = props?.experience
    ?.filter((item: any) => item?.company?.name)
    ?.map((data: any) => {
      const { company, title, start_date, end_date } = data || {}
      const formattedStartDate = moment(start_date).format(format)
      const formattedEndDate = moment(end_date).format(format)

      return {
        name: company.name,
        link: company.name
          ? urlType
            ? mergeUrlWithParams(`${urls[urlType]}/${entityId}/market-data/company/${company.name}`, { website: company.website })
            : `${Paths._companies}/${company.name}/market-data`
          : '',
        logoUrl: company.website,
        byline: company.website,
        byline2: (
          <>
            {title?.name && (
              <Tooltip title={title.name}>
                <Typography className={classes.byLine} noWrap>
                  {title.name}
                </Typography>
              </Tooltip>
            )}
            {start_date && end_date && (
              <Typography color="text.secondary">
                {formattedStartDate} - {formattedEndDate}
              </Typography>
            )}
            {start_date && !end_date && <Typography color="text.secondary">from {formattedStartDate}</Typography>}
            {end_date && !start_date && <Typography color="text.secondary">until {formattedEndDate}</Typography>}
          </>
        ),
        variant: 'expandable',
        startDate: start_date ? new Date(start_date) : new Date(end_date ? 1 : 0)
      }
    })
    .sort((a: any, b: any) => b.startDate - a.startDate)

  const education = props?.education
    ?.filter((item: any) => item?.school?.name)
    ?.map((data: any) => {
      const { school, start_date, end_date, degrees, majors } = data || {}
      const formattedStartDate = moment(start_date).format(format)
      const formattedEndDate = moment(end_date).format(format)

      const details = [degrees?.[0], majors?.[0]].filter(Boolean).join(', ')

      return {
        name: school.name,
        link: school.name
          ? urlType
            ? mergeUrlWithParams(`${urls[urlType]}/${entityId}/market-data/company/${school.name}`, { website: school.website })
            : `${Paths._companies}/${school.name}/market-data`
          : '',
        logoUrl: school.website,
        byline: school.website,
        byline2: (
          <>
            {school.location?.name && (
              <Tooltip title={school.location.name}>
                <Typography className={classes.byLine} noWrap>
                  {school.location.name}
                </Typography>
              </Tooltip>
            )}
            {details && (
              <Tooltip title={details}>
                <Typography className={classes.byLine} noWrap>
                  {details}
                </Typography>
              </Tooltip>
            )}
            {start_date && end_date && (
              <Typography color="text.secondary">
                {formattedStartDate} - {formattedEndDate}
              </Typography>
            )}
            {start_date && !end_date && <Typography color="text.secondary">from {formattedStartDate}</Typography>}
            {end_date && !start_date && <Typography color="text.secondary">until {formattedEndDate}</Typography>}
          </>
        ),
        variant: 'expandable',
        startDate: start_date ? new Date(start_date) : new Date(end_date ? 1 : 0)
      }
    })
    .sort((a: any, b: any) => b.startDate - a.startDate)

  const showCompanyBlock = jobCompanyLocationName || jobCompanyIndustry || jobCompanySize || jobCompanyWebsite
  const jobCompanyLink = jobCompanyMd5
    ? `${Paths._companies}/${jobCompanyMd5}`
    : urlType
      ? mergeUrlWithParams(`${urls[urlType]}/${entityId}/market-data/company/${jobCompanyName}`, {
          website: jobCompanyWebsite
        })
      : `${Paths._companies}/${jobCompanyName}/market-data`

  return (
    <>
      {(!id || (id && jobTitle && jobCompanyName)) && (
        <Skeleton condition={!jobTitle && !jobCompanyName}>
          <Tooltip title={`${jobTitle} at ${jobCompanyName}`} placement="bottom-start">
            <Box display="flex" alignItems="start" mt={1}>
              <FontAwesomeIcon icon={['fas', 'briefcase']} className={cx(classes.byLineIcon, classes.space)} />
              <Typography>
                {`${jobTitle} at` || 'Placeholder name'}
                <Link to={jobCompanyLink}>
                  <Typography component="span" color="text.primary" semiBold>
                    &nbsp;{jobCompanyName}
                  </Typography>
                </Link>
              </Typography>
            </Box>
          </Tooltip>
        </Skeleton>
      )}
      {(!id || (id && workEmail)) && (
        <Skeleton condition={!workEmail}>
          <Tooltip title={`Mail to ${workEmail}`} placement="bottom-start">
            <Box display="flex" alignItems="start" mt={1}>
              <FontAwesomeIcon icon={['fas', 'envelope']} className={cx(classes.byLineIcon, classes.space)} />
              <Typography color="text.primary">
                <a href={`mailto:${workEmail}`}>{workEmail || 'Placeholder name'}</a>
              </Typography>
            </Box>
          </Tooltip>
        </Skeleton>
      )}
      {phoneNumbers?.length > 3 && (
        <Accordion classes={{ root: classes.accordion }}>
          <AccordionSummary
            expandIcon={<IconButton icon={['far', 'chevron-down']} size="small" color="inherit" disablePX disablePT />}
            aria-label="Expand"
            aria-controls="Phone numbers"
            id="Phone numbers"
            classes={{ root: classes.summary, content: classes.accordionContent }}
          >
            {phoneNumbers.slice(0, 3).map((phone: string) => (
              <PhoneNumber phone={phone} key={phone} />
            ))}
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.accordionDetails }}>
            <Box display="flex" flexDirection="column" alignItems="start" width="100%">
              {phoneNumbers.slice(4).map((phone: string) => (
                <PhoneNumber phone={phone} key={phone} />
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
      {phoneNumbers?.length < 3 && phoneNumbers.map((phone: string) => <PhoneNumber phone={phone} styles={{ marginTop: 8 }} key={phone} />)}
      {(!id || (id && locationName)) && (
        <Skeleton condition={!locationName}>
          <Tooltip title={locationName} placement="bottom-start">
            <Box display="flex" alignItems="start" mt={1}>
              <FontAwesomeIcon icon={['fas', 'location-dot']} className={cx(classes.byLineIcon, classes.space)} />
              <Typography>{locationName || 'Placeholder name'}</Typography>
            </Box>
          </Tooltip>
        </Skeleton>
      )}
      {(!id || (id && industry)) && (
        <Skeleton condition={!industry}>
          <Tooltip title={industry} placement="bottom-start">
            <Box display="flex" alignItems="start" mt={1}>
              <FontAwesomeIcon icon={['fas', 'industry']} className={cx(classes.byLineIcon, classes.space)} />
              <Typography>{industry || 'Placeholder name'}</Typography>
            </Box>
          </Tooltip>
        </Skeleton>
      )}
      <Box display="flex" justifyContent="center">
        {(!id || (id && twitterUrl)) && (
          <IconButton
            className={classes.iconButton}
            icon={['fab', 'twitter']}
            color="primary"
            hint="Show user profile on Twitter"
            component="a"
            href={`https://${twitterUrl}`}
            loading={!twitterUrl}
            rel="noopener noreferrer"
            target="_blank"
            size="small"
          />
        )}
        {(!id || (id && linkedinUrl)) && (
          <IconButton
            className={classes.iconButton}
            icon={['fab', 'linkedin']}
            color="primary"
            hint="Show user profile on LinkedIn"
            component="a"
            href={`https://${linkedinUrl}`}
            loading={!linkedinUrl}
            rel="noopener noreferrer"
            target="_blank"
            size="small"
          />
        )}
      </Box>

      {(!id || (id && showCompanyBlock)) && (
        <Box mt={2} mb={2}>
          <Divider />
        </Box>
      )}

      {(!id || (id && jobCompanyName && showCompanyBlock)) && (
        <Box display="flex" alignItems="center" mt={2} justifyContent="center">
          <Avatar name={jobCompanyName} logoUrl={jobCompanyWebsite} size="xs" hideName />
          <Box ml={1}>
            <Skeleton condition={!jobCompanyName}>
              <Link to={jobCompanyLink}>
                <Typography semiBold>{jobCompanyName || 'Placeholder name'}</Typography>
              </Link>
            </Skeleton>
          </Box>
        </Box>
      )}

      {(!id || (id && jobCompanyLocationName)) && (
        <Skeleton condition={!jobCompanyLocationName}>
          <Tooltip title={jobCompanyLocationName} placement="bottom-start">
            <Box display="flex" alignItems="start" mt={2}>
              <FontAwesomeIcon icon={['fas', 'location-dot']} className={cx(classes.byLineIcon, classes.space)} />
              <Typography>{jobCompanyLocationName || 'Placeholder name'}</Typography>
            </Box>
          </Tooltip>
        </Skeleton>
      )}
      {(!id || (id && jobCompanyIndustry)) && (
        <Skeleton condition={!jobCompanyIndustry}>
          <Tooltip title={jobCompanyIndustry} placement="bottom-start">
            <Box display="flex" alignItems="start" mt={1}>
              <FontAwesomeIcon icon={['fas', 'industry']} className={cx(classes.byLineIcon, classes.space)} />
              <Typography>{jobCompanyIndustry || 'Placeholder name'}</Typography>
            </Box>
          </Tooltip>
        </Skeleton>
      )}
      {(!id || (id && jobCompanySize)) && (
        <Skeleton condition={!jobCompanySize}>
          <Tooltip title={jobCompanySize} placement="bottom-start">
            <Box display="flex" alignItems="start" mt={1}>
              <FontAwesomeIcon icon={['fas', 'users']} className={cx(classes.byLineIcon, classes.space)} />
              <Typography>{`${jobCompanySize}+ employees` || 'Placeholder'}</Typography>
            </Box>
          </Tooltip>
        </Skeleton>
      )}
      <Box display="flex" justifyContent="center">
        {(!id || (id && jobCompanyFacebookUrl)) && (
          <IconButton
            className={classes.iconButton}
            icon={['fab', 'facebook']}
            color="primary"
            hint="Show company profile on Facebook"
            component="a"
            href={`https://${jobCompanyFacebookUrl}`}
            loading={!jobCompanyFacebookUrl}
            target="_blank"
            rel="noopener noreferrer"
            size="small"
          />
        )}
        {(!id || (id && jobCompanyTwitterUrl)) && (
          <IconButton
            className={classes.iconButton}
            icon={['fab', 'twitter']}
            color="primary"
            hint="Show company profile on Twitter"
            component="a"
            href={`https://${jobCompanyTwitterUrl}`}
            loading={!jobCompanyTwitterUrl}
            rel="noopener noreferrer"
            target="_blank"
            size="small"
          />
        )}
        {(!id || (id && jobCompanyLinkedinUrl)) && (
          <IconButton
            className={classes.iconButton}
            icon={['fab', 'linkedin']}
            color="primary"
            hint="Show company profile on Linkedin"
            component="a"
            href={`https://${jobCompanyLinkedinUrl}`}
            loading={!jobCompanyLinkedinUrl}
            rel="noopener noreferrer"
            target="_blank"
            size="small"
          />
        )}
        {(!id || (id && jobCompanyWebsite)) && (
          <IconButton
            className={classes.iconButton}
            icon={['far', 'globe']}
            color="primary"
            hint="Show company website"
            component="a"
            href={`https://${jobCompanyWebsite}`}
            loading={!jobCompanyWebsite}
            rel="noopener noreferrer"
            target="_blank"
            size="small"
          />
        )}
      </Box>
      {(!id || (id && showCompanyBlock)) && (
        <Box mt={2} mb={2}>
          <Divider />
        </Box>
      )}
      {(!id || (id && !!skills?.length)) && (
        <Skeleton condition={!skills?.length} width={120} height={29}>
          <Box display="flex" alignItems="center" mt={showCompanyBlock ? 0 : 2}>
            <FontAwesomeIcon icon={['fas', 'hashtag']} className={classes.byLineIcon} />
            <Typography variant="h4" semiBold>
              Skills
            </Typography>
          </Box>
          {!!skills?.length && <Box mt={1}>{skills?.map((tag: string) => <ExternalTag tagData={tag} className={classes.chip} key={tag} />)}</Box>}
        </Skeleton>
      )}
      {(!id || (id && !!interests?.length)) && (
        <Skeleton condition={!interests?.length} width={120} height={29}>
          <Box display="flex" alignItems="center" mt={2}>
            <FontAwesomeIcon icon={['fas', 'hashtag']} className={classes.byLineIcon} />
            <Typography variant="h4" semiBold>
              Interests
            </Typography>
          </Box>
          {!!interests?.length && (
            <Box mt={1}>{interests?.map((tag: string) => <ExternalTag tagData={tag} className={classes.chip} key={tag} />)}</Box>
          )}
        </Skeleton>
      )}
      {(!id || (id && !!experience?.length)) && (
        <>
          <Skeleton condition={!experience?.length} width={120} height={29}>
            <Box display="flex" alignItems="center" mt={2}>
              <FontAwesomeIcon icon={['fas', 'briefcase']} className={classes.byLineIcon} />
              <Typography variant="h4" semiBold>
                Experience
              </Typography>
            </Box>
          </Skeleton>
          <Box ml={-2} mr={-2}>
            <Repeater direction="vertical" variant="list" component={ProfileItem} skeleton={{ size: 5, loading: !id }} items={id ? experience : []} />
          </Box>
        </>
      )}
      {(!id || (id && !!education?.length)) && (
        <>
          <Skeleton condition={!education?.length} width={120} height={29}>
            <Box display="flex" alignItems="center" mt={2}>
              <FontAwesomeIcon icon={['fas', 'graduation-cap']} className={classes.byLineIcon} />
              <Typography variant="h4" semiBold>
                Education
              </Typography>
            </Box>
          </Skeleton>
          <Box ml={-2} mr={-2}>
            <Repeater direction="vertical" variant="list" component={ProfileItem} skeleton={{ size: 5, loading: !id }} items={id ? education : []} />
          </Box>
        </>
      )}
    </>
  )
}

export default PersonData

const PhoneNumber = ({ phone, styles }: { phone: string; styles?: CSSProperties }) => {
  const { classes, cx } = useStyles()
  const phoneNumber = parsePhoneNumber(phone)?.formatInternational()

  return (
    <Tooltip title={`Make a call to ${phoneNumber}`} placement="bottom-start">
      <Box display="flex" alignItems="start" style={styles}>
        <FontAwesomeIcon icon={['fas', 'phone']} className={cx(classes.byLineIcon, classes.space)} />
        <Typography color="primary">
          <a href={`tel:${phone}`}>{phoneNumber}</a>
        </Typography>
      </Box>
    </Tooltip>
  )
}
