import React, { useEffect, useState, useContext, ChangeEvent, useCallback, HTMLAttributes } from 'react'

import { Box, List, CircularProgress } from '@mui/material'
import { parsePhoneNumber } from 'libphonenumber-js'
import { useParams, useHistory } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { isSidepanel } from '_pages/sidebar'

import { TeamContext } from '_core/context/TeamContext'

import { Button, IconButton } from '_shared/buttons'
import Combobox from '_shared/forms/Combobox'
import Typography from '_shared/Typography'

import Repeater from '_core/components/lists/Repeater'
import ProfileItem from '_core/components/ProfileItem'
import ProfilePreview from '_core/components/ProfilePreview'
import Topbar from '_core/components/Topbar'

import useAsyncCombobox from '_core/hooks/useAsyncCombobox'
import { useLookUpPeople, useLookUpCompanies } from '_core/hooks/useLookup'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useSidepanelClose from '_core/hooks/useSidepanelClose'

import { mergeUrlWithParams, post } from 'utils/httpUtils'

import { actionButtons } from 'AppTheme'
import { LayoutContext } from 'Layout/LayoutContextProvider'
import TabSet from 'SharedComponents/TabSet'

import Paths from 'Paths'

import { SidepanelNarrow, Narrow, SidepanelWide, Columns, Column } from './layout'

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    display: 'flex',
    flexFlow: 'column',
    minHeight: 'calc(100vh - 140.5px)'
  },
  autoCompleteWrapper: {
    '& .MuiInputBase-input': {
      '& > div': {
        padding: '6.5px 13.5px',
        '& > p': {
          bottom: 'unset'
        }
      }
    }
  },
  profile: {
    '& > div': {
      paddingLeft: 0
    }
  },
  list: {
    width: '100%',
    height: 'calc(100% - 75px)',
    overflowY: 'auto',
    boxSizing: 'border-box'
  },
  actionButtons: actionButtons(theme),
  columns: {
    width: '100%',
    margin: 0,
    flex: 1
  },
  column: {
    padding: `0px 0px 0px ${theme.spacing(2)}`,
    height: '100%',
    boxSizing: 'border-box'
  }
}))

type OptionType = {
  md5: string
  userKey: string
  logoUrl: string
  name: string
  email: string
  phone: string
  phoneType: string
  company: { name: string; jobTitle: string }
}

const transformData = (data: any): OptionType => {
  return {
    md5: data.PersonMd5 || data.CompanyMd5,
    userKey: data.BestEmailAddrText,
    logoUrl: data.BestUrlText,
    name: data.PersonNameText || data.CompanyNameText,
    email: data.BestEmailAddrText,
    phone: data.BestPhoneText,
    phoneType: data.BestPhoneType,
    company: {
      name: data.BestJobMatchedCompanyName || data.BestJobCorpLevelCompanyName || '',
      jobTitle: data.BestJobTitleText || data.BestUrlText || ''
    }
  }
}

export type Params = {
  entity: Entities
}

const Item = ({ loading, handleDelete, ...member }: { loading: boolean; handleDelete: (value: OptionType) => void } & OptionType) => {
  const {
    classes: { profile }
  } = useStyles()

  const handleDeleteClick = () => {
    handleDelete(member)
  }

  return (
    <>
      {loading && (
        <Box className={profile}>
          <ProfileItem
            name={member.name}
            userKey={member.email}
            logoUrl={member.logoUrl}
            byline={member.email || member.logoUrl}
            byline2={member.phone}
          />
        </Box>
      )}
      {!loading && (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box className={profile} maxWidth="calc(100% - 40px)">
            <ProfileItem
              name={member.name}
              userKey={member.email}
              logoUrl={member.logoUrl}
              byline={member.email || member.logoUrl}
              byline2={member.phone}
            />
          </Box>
          <IconButton onClick={handleDeleteClick} icon={['far', 'times']} size="small" />
        </Box>
      )}
    </>
  )
}

const Picker = (props: {
  loading: boolean
  handleSelect: (e: ChangeEvent<{}>, item: OptionType[]) => void
  handleDelete: (value: OptionType) => void
  membersList: OptionType[]
}) => {
  const { loading, handleDelete, membersList } = props
  const sidepanel = isSidepanel()
  const { lookupPeople, forceAbort: forcePeopleAbort } = useLookUpPeople()
  const { lookupCompanies, forceAbort: forceCompaniesAbort } = useLookUpCompanies()

  const { entity } = useParams<{ entity: Entities; id: string }>()

  const entitiesMap = {
    people: { lookUp: lookupPeople, forceAbort: forcePeopleAbort },
    companies: { lookUp: lookupCompanies, forceAbort: forceCompaniesAbort }
  }

  const { lookUp, forceAbort } = entitiesMap[entity]

  const { inputValue, open, options, optionsLoading, handleClose, handleOpen, handleFocus, handleInputChange, filterOptions } = useAsyncCombobox<
    OptionType,
    true
  >({
    loadOptions: useCallback(
      async (inptValue: string) => {
        const items = await lookUp(
          inptValue,
          membersList.map(({ md5 }) => md5)
        )
        return items?.map((item) => transformData(item))
      },
      [lookUp, membersList]
    ),
    forceAbort
  })

  const {
    classes: { list, autoCompleteWrapper }
  } = useStyles()

  const handleSelect = (e: ChangeEvent<{}>, items: OptionType[]) => {
    props.handleSelect(e, items)
  }

  const renderOption = (props: HTMLAttributes<HTMLLIElement>, option: OptionType) => (
    <li {...props}>
      <ProfileItem
        name={option.name}
        byline={option.company.jobTitle}
        byline2={option.company.name}
        logoUrl={option.logoUrl}
        userKey={option.userKey}
      />
    </li>
  )

  const getOptionLabel = (option: OptionType) => option.name

  return (
    <Box pt={2} pb={6.5} pr={2} pl={{ xs: 2, sm: 0.5 }} height={sidepanel ? 'auto' : 'calc(100% - 93px)'} boxSizing="border-box" flex={1}>
      <Typography variant="h3" style={{ marginBottom: 24 }} semiBold>
        Pick {entity}
      </Typography>
      <Box mb={-1}>
        <Box className={autoCompleteWrapper}>
          <Combobox<OptionType, true>
            multiple
            autoFocus
            inputValue={inputValue}
            open={open}
            options={options}
            value={membersList}
            placeholder="Search"
            loading={optionsLoading}
            getOptionLabel={getOptionLabel}
            renderOption={renderOption}
            onChange={handleSelect}
            onClose={handleClose}
            onOpen={handleOpen}
            onFocus={handleFocus}
            onInputChange={handleInputChange}
            filterOptions={filterOptions}
          />
        </Box>
      </Box>
      <List className={list}>
        <Repeater
          direction="vertical"
          component={Item}
          skeleton={{ size: 1, loading }}
          items={membersList?.map((item) => ({
            ...item,
            handleDelete,
            loading
          }))}
        />
      </List>
    </Box>
  )
}

const Preview = ({ loading, membersList }: { loading: boolean; membersList: OptionType[] }) => (
  <ProfilePreview
    loading={loading}
    Name={membersList?.map((member: any) => member.name)[0]}
    Emails={membersList.map((member: any) => member.email)}
    Websites={membersList.map((member: any) => member.logoUrl)}
    Phones={membersList.map((member) => {
      if (member.phone) {
        const phoneNumber = parsePhoneNumber(member.phone)?.formatInternational()
        return `${member.phoneType ? member.phoneType.substr(0, 1).toUpperCase() : 'O'} ${phoneNumber}`
      }
    })}
  />
)

const MergeFlow = (props: any) => {
  const {
    classes: { actionButtons, wrapper, columns, column }
  } = useStyles()

  const { queryParams } = useSearchQuery<MergePageParams>()
  const { entity } = useParams<Params>()
  const history = useHistory()

  const { teamContextValue } = useContext(TeamContext)
  const { setMobileHeader, setSubHeader } = useContext(LayoutContext)

  const [loading, setLoading] = useState<boolean>(false)
  const [membersList, setMembersList] = useState<OptionType[]>([])
  const [tabIndex, setTabIndex] = useState(0)
  const handleClose = useSidepanelClose()

  useEffect(() => {
    setMobileHeader('Merge')
  }, [setMobileHeader])

  useEffect(() => {
    setLoading(false)
  }, [])

  useEffect(() => {
    if (!props.loading && queryParams.ids) {
      const ids = queryParams.ids.split(',')
      const list = ids.map((id: string) => {
        const data = transformData(props[id].data[0])
        return data
      })
      setMembersList(list)
    }
  }, [props.loading, queryParams.ids])

  const handleMerge = async () => {
    setLoading(true)
    await post(
      mergeUrlWithParams(`/${entity}/mergemulti`, { teamNumber: `${teamContextValue.teamNumber}` }),
      membersList.map(({ md5 }) => md5)
    )
    setLoading(false)
    history.push(`${Paths._merge}/${entity}/success`)
  }

  const handleSelect = (e: ChangeEvent<{}>, items: OptionType[]) => {
    setMembersList(items)
  }

  const handleDelete = (row: OptionType) => {
    setMembersList(membersList.filter(({ md5 }: { md5: string }) => md5 !== row.md5))
  }

  const handleTabChange = (newValue: number) => {
    setTabIndex(newValue)
  }

  const ActionButtons = (
    <Box className={actionButtons}>
      <Button variant="text" color="secondary" onClick={handleClose} disabled={props.loading}>
        Cancel
      </Button>
      <Button variant="text" onClick={handleMerge} disabled={props.loading || membersList.length <= 1} disablePR>
        Merge
      </Button>
    </Box>
  )

  const tabs = [
    {
      label: 'PROFILE LIST',
      component: () => <Picker membersList={membersList} loading={props.loading} handleSelect={handleSelect} handleDelete={handleDelete} />
    },
    {
      label: 'PREVIEW',
      component: () => <Preview membersList={membersList} loading={props.loading} />
    }
  ]

  return (
    <>
      {loading && (
        <Box width="100vw" height="100vh" display="flex" justifyContent="center" py={25}>
          <CircularProgress />
        </Box>
      )}

      {!loading && (
        <>
          <Narrow>
            <Topbar sub={membersList[0]?.name} nativeBack={true} />
          </Narrow>
          <Box className={wrapper}>
            <SidepanelWide>
              <Columns className={columns}>
                <Column xs={8} className={column}>
                  <Picker membersList={membersList} loading={props.loading} handleSelect={handleSelect} handleDelete={handleDelete} />
                </Column>
                <Column xs={4} style={{ padding: 0 }}>
                  <Preview membersList={membersList} loading={props.loading} />
                </Column>
              </Columns>
              {ActionButtons}
            </SidepanelWide>
            <SidepanelNarrow>
              <TabSet tabIndex={tabIndex} setTabIndex={setTabIndex} tabs={tabs} onChange={handleTabChange} disabled={loading} />
              {ActionButtons}
            </SidepanelNarrow>
          </Box>
        </>
      )}
    </>
  )
}

export default MergeFlow
