import React from 'react'

import { Box, Chip, ChipProps } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Typography from '_shared/Typography'

const useStyles = makeStyles()((theme) => ({
  root: {
    borderRadius: theme.spacing(0.5),
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '16px'
  },
  colorSecondary: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.text.primary
  },
  colorError: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.text.primary
  },
  colorSuccess: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.text.primary
  },
  colorWarning: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.text.primary
  },
  clickable: {
    '&.MuiChip-colorSecondary:hover': {
      backgroundColor: theme.palette.secondary.light
    },
    '&.MuiChip-colorError:hover': {
      backgroundColor: theme.palette.error.light
    },
    '&.MuiChip-colorSuccess:hover': {
      backgroundColor: theme.palette.success.light
    },
    '&.MuiChip-colorWarning:hover': {
      backgroundColor: theme.palette.warning.light
    }
  },
  icon: {
    fontSize: 14,
    width: 14,
    maxWidth: 14,
    marginRight: theme.spacing(1)
  }
}))

export const StatusLabel = ({ label, icon: Icon, noWrap }: { label: string; icon: React.ElementType; noWrap?: boolean }) => {
  const { classes } = useStyles()

  return (
    <Box display="flex" alignItems="center" overflow="hidden">
      <Icon iconClassName={classes.icon} />
      <Typography noWrap={noWrap}>{label}</Typography>
    </Box>
  )
}

const StatusChip = (props: Pick<ChipProps, 'color' | 'label' | 'size'> & { className?: string; clickable?: boolean }) => {
  const { cx, classes } = useStyles()

  return (
    <Chip
      label={props.label}
      color={props.color}
      size={props.size}
      clickable={props.clickable}
      classes={{
        root: cx(classes.root, props.className),
        colorSecondary: classes.colorSecondary,
        colorError: classes.colorError,
        colorSuccess: classes.colorSuccess,
        colorWarning: classes.colorWarning,
        clickable: classes.clickable
      }}
    />
  )
}

export default StatusChip
