import React, { useCallback, useContext, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import { isSidepanel } from '_pages/sidebar'

import { TeamContext } from '_core/context/TeamContext'

import AddPeople from '_core/components/AddPrivilegedPeople'
import { PersonOptionType } from '_core/components/dialogs/CreatePersonOptionDialog'
import { Narrow } from '_core/components/layout'
import Topbar from '_core/components/Topbar'

import { useLookUpActiveDirectoryMembers } from '_core/hooks/useLookup'
import useSidebar from '_core/hooks/useSidebar'
import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'

import { post } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import { DataAdminSubPaths } from 'Paths'

export type PrivilegedMember =
  | {
      rowNumber: number
      appUserKey: string
      teamNumber: number
      allowPrivilegedCompanyAccess: boolean
      viaWhomEmail: string
      lastModified: string
      total: number
    }
  | string

const AddPrivilegedMembersPage = () => {
  const { setMobileHeader } = useContext(LayoutContext)

  useEffect(() => {
    setMobileHeader('Add users')
  }, [setMobileHeader])

  return (
    <Narrow>
      <Topbar nativeBack />
      <Component />
    </Narrow>
  )
}

const Component = () => {
  const {
    teamContextValue: { teamNumber }
  } = useContext(TeamContext)
  const history = useHistory()
  const { close: sidebarClose } = useSidebar()
  const { updateParent } = useSidepanelPayloads()
  const { lookUpActiveDirectoryMembers, forceAbort } = useLookUpActiveDirectoryMembers()

  const loadOptions = useCallback(
    async (searchTerm) => {
      const result = await lookUpActiveDirectoryMembers(searchTerm)
      if (result) {
        return result.users.map(({ displayName, emailAddress }) => ({ name: displayName, email: emailAddress }))
      }
    },
    [lookUpActiveDirectoryMembers]
  )

  const sidepanel = isSidepanel()

  const handleSave = async (addedMembers: PersonOptionType[]) => {
    if (addedMembers.length) {
      const payload = addedMembers.map((member) => member.email)
      await post(`/teams/${teamNumber}/privilegedcomembers`, payload)
    }

    if (sidepanel) {
      sidebarClose()
      updateParent({ action: 'RELOAD_LIST', value: 'privileged-companies' })
    } else {
      history.push(`${DataAdminSubPaths.privilegedCompanies}/members`)
    }
  }

  return <AddPeople handleSave={handleSave} sidepanel={sidepanel} loadOptions={loadOptions} forceAbort={forceAbort} />
}

export default AddPrivilegedMembersPage
