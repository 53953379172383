import { Box, Card, CardContent, AccordionDetails, AccordionSummary, Accordion } from '@mui/material'
import { CSSObject } from 'tss-react'
import { makeStyles } from 'tss-react/mui'

import { IconButton } from '_shared/buttons'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

const useStyles = makeStyles<{ actionStyles: CSSObject }>()((theme, { actionStyles }) => ({
  card: {
    textAlign: 'center',
    background: theme.palette.background.light,
    position: 'relative',
    borderRadius: 10
  },
  cardSecondary: {
    '& .MuiCardContent-root:last-child': {
      paddingBottom: theme.spacing(2)
    }
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: `${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(1)}`
  },
  token: {
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '0.25px',
    minHeight: theme.spacing(3),
    maxWidth: `calc(100% - ${theme.spacing(5)})`,
    minWidth: theme.spacing(20)
  },
  title: {
    fontSize: 16,
    lineHeight: '20px'
  },
  cause: {
    lineHeight: theme.spacing(3),
    color: theme.palette.text.secondary,
    letterSpacing: '0.25px'
  },
  date: {
    color: '#7d7d7d',
    fontSize: 12,
    lineHeight: theme.spacing(2.5),
    padding: `${theme.spacing(1)} ${theme.spacing(2.5)}`,
    background: '#F8F8FB',
    borderRadius: `0px 0px ${theme.spacing(1)} ${theme.spacing(1)}`
  },
  actionIcon: {
    color: theme.palette.text.secondary,
    ...actionStyles,
    '&:hover': {
      color: theme.palette.secondary.main
    }
  },
  skeleton: {
    margin: '0 auto'
  },
  button: {
    padding: '6px 8px',
    minWidth: theme.spacing(7)
  },
  questionIcon: {
    position: 'absolute',
    top: 6,
    right: 9
  },
  undoHeader: {
    textAlign: 'center'
  },
  hidden: {
    display: 'none'
  },
  tokenText: {
    maxWidth: '75vw',
    lineHeight: '36px'
  },
  details: {
    padding: 0,
    justifyContent: 'center'
  },
  accordion: {
    '&:before': {
      height: 0
    }
  },
  summary: {
    display: 'grid!important',
    gridTemplateColumns: 'minmax(0, auto) 39px',
    padding: 0
  }
}))

export interface EditItemIdentifiersProps {
  identifiers: {
    main: string
    other: any[]
  }
  date?: Date
  actionButton: JSX.Element
}

export const EditItemIdentifiers = ({ identifiers, actionButton }: EditItemIdentifiersProps) => {
  const actionStyles: CSSObject = {
    position: 'absolute',
    top: 6,
    right: 6,
    zIndex: 1
  }

  const token1 = identifiers?.main
  const token2 = identifiers?.other?.[0] || ''
  const token3 = identifiers?.other?.slice(1)

  const { classes, cx } = useStyles({ actionStyles })

  return (
    <Card variant="outlined" classes={{ root: cx(classes.card, classes.cardSecondary) }}>
      {actionButton && <Box className={classes.actionIcon}>{actionButton}</Box>}
      <CardContent classes={{ root: classes.cardContent }}>
        <Typography classes={{ root: classes.token }} style={{ maxWidth: '60%' }} noWrap>
          <Skeleton condition={!token1}>{token1}</Skeleton>
        </Typography>
        {token3?.length ? (
          <Accordion classes={{ root: classes.accordion }}>
            <AccordionSummary
              expandIcon={<IconButton icon={['far', 'chevron-down']} size="small" disablePadding />}
              aria-label="Expand"
              aria-controls={`${token2}-content`}
              id={`${token2}-header`}
              classes={{ root: classes.summary }}
            >
              <Typography classes={{ root: classes.cause }} noWrap>
                {token2}
              </Typography>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.details }}>
              <Box display="flex" flexDirection="column" alignItems="center">
                {token3.map((item: any, i: number) => (
                  <Typography style={{ maxWidth: '75vw' }} className={classes.cause} key={i} noWrap>
                    {item}
                  </Typography>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        ) : (
          <Skeleton condition={!token2 && !token1} width={150} height={24}>
            <Typography classes={{ root: cx(classes.cause, classes.tokenText, { [classes.hidden]: !token2.length }) }} noWrap>
              {token2}
            </Typography>
          </Skeleton>
        )}
      </CardContent>
    </Card>
  )
}
