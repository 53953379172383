import { useEffect, useContext, useState, useMemo } from 'react'

import { GridColDef } from '@mui/x-data-grid-pro'
import { useParams } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import DownloadBulkControl from '_core/components/DownloadBulkControl'
import Filters from '_core/components/filters/CompanyContacts'
import {
  companyColumn,
  jobTitleColumn,
  nameColumn,
  bestIntroducerColumn,
  scoreColumn,
  lastInboundColumn,
  lastMeetingColumn,
  lastOutboundColumn,
  nextFutureMeetingColumn
} from '_core/components/grid/columns'
import GridPageFrame from '_core/components/GridPageFrame'
import { Narrow } from '_core/components/layout'
import PeopleList, { PeopleListProps, Heading } from '_core/components/PeopleList'
import { sortMap } from '_core/components/sort'
import Topbar from '_core/components/Topbar'

import useCompanyContactsUserSettings from '_core/hooks/useCompanyContactsUserSettings'
import { DownloadBulkType, DownloadBulkParams } from '_core/hooks/useDownloadBulk'
import useInteractionsPeriodEndpointParams from '_core/hooks/useInteractionsPeriodEndpointParams'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useSortStatsEndpointParams from '_core/hooks/useSortStatsEndpointParams'
import useTagsEndpointParams from '_core/hooks/useTagsEndpointParams'

import DynamicEntity from '_core/DynamicEntity'
import UserSettings from '_core/UserSettings'

import { getBinary, mergeUrlWithParams } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

type AddProps = Pick<PeopleListProps, 'sort' | 'sortByField' | 'updateSort' | 'viewMode' | 'columns'> & {
  download: DownloadBulkType
}

const Contacts = ({
  download,
  ...props
}: Modify<PeopleListProps, { items: CompanyContactListItem[] }> & {
  download: DownloadBulkType
}) => {
  const { setSubHeader } = useContext(LayoutContext)

  useEffect(() => {
    setSubHeader(`Contacts ${props.total ? `· ${props.total}` : ''}`)
    return () => setSubHeader('')
  }, [setSubHeader, props.total])

  const items = useMemo(
    () =>
      props.items?.map((person) => {
        const workExperience = {
          companyIdentity: person.CompanyMd5,
          companyName: person.JobCompanyName || person.CorpLevelCompanyName,
          jobTitle: person.TitleText,
          currentAsOf: person.JobCurrentAsOf
        }

        const formerJob = !!person.IsFormerJob
        const lastInbound = person.Stats?.LastInboundMsg || ''
        const lastOutbound = person.Stats?.LastOutboundMsg || ''
        const lastMeeting = person.Stats?.LastMeeting || ''
        const nextFutureMeeting = person.Stats?.NextFutureMeeting || ''
        return {
          id: person.MyUserKeyMd5 || person.PersonMd5,
          name: person.PersonNameText,
          byline: person.ContactBestJobTitleText || '',
          byline2: person.ContactBestJobMatchedCompanyName || person.ContactBestJobCorpLevelCompanyName || '',
          score: person.BestUserKnowsPersonScore,
          link: `${Paths._people}/${person.MyUserKeyMd5 || person.PersonMd5}`,
          title: person.ContactBestJobTitleText || '',
          formerJob,
          workExperience,
          company: {
            name: person.ContactBestJobMatchedCompanyName || person.ContactBestJobCorpLevelCompanyName || '',
            link: `${Paths._companies}/${person.ContactBestJobCompanyMd5}`,
            sidepanel: true as SidepanelType
          },
          bestIntroducer: {
            name: person.BestKnowerNameText,
            link: `${Paths._relationships}/${person.BestKnowerUserKeyMd5}/people/${person.MyUserKeyMd5 || person.PersonMd5}`,
            sidepanel: true as SidepanelType
          },
          userKey: person.BestEmailAddrText,
          email: person.BestEmailAddrText,
          sidepanel: true as SidepanelType,
          lastInbound,
          lastOutbound,
          lastMeeting,
          nextFutureMeeting,
          variant: 'expandable',
          privacy: person.Privacy,
          tags: person.Tags || []
        }
      }),
    [props.loading, props.items.length]
  )

  return (
    <PeopleList
      {...props}
      items={items}
      downloadAllControl={<DownloadBulkControl totalRows={props.total} disabled={props.loading} download={download} />}
    />
  )
}

type CProps = {
  onLoading: (loading: boolean, result: { total_item_count: number } | undefined) => void
  onPageSizeChange: (rowsPerPage: NumberToString<RowPerPageOptionsType>) => void
  updateSort: (sort: ScoreType | StatSortType) => void
  columns: GridColDef[]
}

const Component = (props: CProps) => {
  const { id } = useParams<{ id: string }>()
  const { teamContextValue } = useContext(TeamContext)
  const { queryParams } = useSearchQuery<GridParams & { viewMode?: ViewModeType; includeTags?: string; excludeTags?: string }>()
  const { keyword, rowsPerPage = '10', sort, viewMode, includeTags, excludeTags } = queryParams

  const interactionsPeriodParams = useInteractionsPeriodEndpointParams(queryParams)
  const sortStatsParams = useSortStatsEndpointParams(sort)
  const tagsParams = useTagsEndpointParams('people', includeTags, excludeTags)

  const payload = {
    TeamNumber: `${teamContextValue.teamNumber}`,
    IncludeStats: `${true}`,
    SortScoreType: 'PersonalScore',
    WithPersonTags: `${true}`,
    ...[tagsParams, sortStatsParams, interactionsPeriodParams]
      .flat()
      .filter(({ value }) => !!value)
      .reduce((acc, { name, value }) => ({ ...acc, [name]: `${value}` }), {})
  }

  const download = {
    fileName: 'company_contacts',
    requestBinary: (data: DownloadBulkParams) => getBinary(`/companies/${id}/peoplexl`, { ...payload, ...data })
  }

  const sortByField = Object.keys(sortMap).find((key) => sortMap[key].asc === sort || sortMap[key].desc === sort)

  return (
    <DynamicEntity<{ extraProps: { addprops: AddProps } }>
      url={sort ? mergeUrlWithParams(`/companies/${id}/people`, payload) : null}
      component={Contacts}
      addprops={{
        sort,
        updateSort: props.updateSort,
        viewMode: viewMode || 'collapsed',
        sortByField: sortByField || 'score',
        columns: props.columns,
        download
      }}
      updatePageSize={props.onPageSizeChange}
      onLoading={props.onLoading}
      pageSize={+rowsPerPage}
      list
      infinite
      search
      autoHideOnScroll
      keepMounted
      empty="No results found"
      emptySubtitle={keyword ? `No results found for your search '${keyword}'` : ''}
      id="company_contacts"
    />
  )
}
const CompanyContacts = () => {
  const { queryParams, updateQuery } = useSearchQuery<GridParams & { viewMode?: ViewModeType; includeTags?: string; excludeTags?: string }>()

  const [total, setTotal] = useState<number>()
  const [contentLoading, setContentLoading] = useState<boolean>(false)

  const {
    setInitial,
    handleChange,
    reset,
    loading: filtersLoading,
    opened,
    interactionsFiltersShown,
    interactionsColumnsShown,
    toggleInteractionSwitch,
    toggleOpen: toggleFilterOpen
  } = useCompanyContactsUserSettings()

  const disabled = filtersLoading || interactionsFiltersShown === null

  const { viewMode, sort, excludeEmpty } = queryParams

  const searchPlaceholder = 'Search for contacts'

  const columns = useMemo(
    () =>
      [
        { column: nameColumn },
        { column: jobTitleColumn },
        { column: companyColumn },
        { column: bestIntroducerColumn },
        { column: lastInboundColumn, condition: !!interactionsColumnsShown },
        { column: lastOutboundColumn, condition: !!interactionsColumnsShown },
        { column: lastMeetingColumn, condition: !!interactionsColumnsShown },
        { column: nextFutureMeetingColumn, condition: !!interactionsColumnsShown },
        { column: scoreColumn }
      ]
        .filter(({ condition }) => typeof condition !== 'boolean' || !!condition)
        .map(({ column }) => column),
    [interactionsColumnsShown]
  )

  const sortItems = columns.filter(({ sortable }) => sortable).map(({ headerName, field }) => ({ label: headerName || '', field }))

  const onLoading = (loading: boolean, result: { total_item_count: number } | undefined) => {
    setContentLoading(loading)
    setTotal(result?.total_item_count)
  }

  const onPageSizeChange = (pageSize: NumberToString<RowPerPageOptionsType>) => {
    handleChange({ rowsPerPage: pageSize })
  }

  const updateViewMode = (viewMode: ViewModeType) => {
    handleChange({ viewMode })
  }

  const updateSort = (sort: ScoreType | StatSortType) => {
    handleChange({ sort })
  }

  const toggleExclude = () => {
    updateQuery({ excludeEmpty: excludeEmpty === 'true' ? 'false' : 'true' })
  }

  const filtersProps = {
    toggleOpen: toggleFilterOpen,
    interactionsFiltersShown: !!interactionsFiltersShown,
    disabled,
    opened,
    total,
    contentLoading,
    reset,
    queryData: queryParams,
    handleChange,
    toggleInteractionSwitch
  }

  return (
    <UserSettings endpoint="/usersettings/companycontactsfilters" setInitial={setInitial}>
      <Narrow>
        <Topbar nativeBack autoHideOnScroll />
      </Narrow>
      <GridPageFrame
        loading={typeof total !== 'number'}
        gridTitle="Contacts"
        gridHeadingIcon={['far', 'user']}
        searchPlaceholder={searchPlaceholder}
        disabledSearch={filtersProps.disabled}
        filters={<Filters {...filtersProps} />}
        heading={
          <Heading
            filters={<Filters {...filtersProps} />}
            filtersProps={{ opened: filtersProps.opened, toggleOpen: filtersProps.toggleOpen, disabled: filtersProps.disabled }}
            sortProps={{ sort, updateSort, items: sortItems, toggleExclude, excludeEmpty: excludeEmpty === 'true' }}
            viewProps={{ viewMode, updateViewMode }}
            searchPlaceholder={searchPlaceholder}
          />
        }
        component={<Component columns={columns} onLoading={onLoading} updateSort={updateSort} onPageSizeChange={onPageSizeChange} />}
      />
    </UserSettings>
  )
}

export default CompanyContacts
