import React from 'react'

import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { AvatarWrapper } from '_shared/Avatar'
import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

const styles = makeStyles()((theme) => ({
  text: {
    maxWidth: '100%'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(1),
    minHeight: theme.spacing(8)
  }
}))

const UnknownProfileSummary = ({ title, userKey, url, loading }: Pick<ProfileSummaryProps, 'title' | 'userKey' | 'loading' | 'url'>) => {
  const { classes } = styles()

  return (
    <Box className={classes.wrapper}>
      <AvatarWrapper name={loading ? '' : title} userKey={userKey} size="lg" mode="dark" logoUrl={url} hideName />

      {(loading || title) && (
        <Box mt={2} maxWidth="100%">
          <Skeleton condition={!title}>
            <Typography variant="h4" semiBold className={classes.text} noWrap>
              {title || 'Placeholder Name'}
            </Typography>
          </Skeleton>
        </Box>
      )}

      {(loading || userKey) && (
        <Skeleton condition={loading} width="80%" height={20}>
          <Tooltip title={userKey}>
            <Typography variant="body1" className={classes.text} noWrap color="text.secondary">
              {userKey}
            </Typography>
          </Tooltip>
        </Skeleton>
      )}
    </Box>
  )
}

export default UnknownProfileSummary
