import React from 'react'

import { makeStyles } from 'tss-react/mui'

import { IconButton } from '_shared/buttons'
import { Icon, IconButtonProps } from '_shared/buttons/Icon'

type ButtonProps = Pick<IconButtonProps, 'hint' | 'disabled' | 'onClick'> & { fontSize?: number | string } & Icon

const useStyles = makeStyles<Pick<ButtonProps, 'fontSize'>>()((theme, { fontSize }) => ({
  icon: {
    cursor: 'pointer',
    fontSize: fontSize || 18,
    width: 42,
    height: 42,
    maxWidth: 42
  }
}))

const GridHeaderButton = ({ icon, hint, disabled, onClick, fontSize }: ButtonProps) => {
  const { classes } = useStyles({ fontSize })

  return <IconButton hint={hint} icon={icon} disabled={disabled} color="primary" classes={{ root: classes.icon }} onClick={onClick} />
}

export default GridHeaderButton
