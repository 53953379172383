import { ChangeEvent, ReactElement } from 'react'

import { Box, Switch } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { HelpIconPopover } from '_shared/popovers/IconPopover'
import Typography from '_shared/Typography'

const useStyles = makeStyles()((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: {
      width: 270,
      overflow: 'auto',
      height: '100%'
    }
  },
  popoverTrigger: {
    marginLeft: theme.spacing(1),
    fontSize: 18
  },
  switch: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: `-${theme.spacing(1)}`,
    marginBottom: theme.spacing(0.5)
  }
}))

type SwitcherProps = {
  label: string
  disabled: boolean
  checked: boolean
  toggle: (event: ChangeEvent<HTMLInputElement>) => void
  informText: ReactElement | string
}

const InteractionSwitcher = (props: SwitcherProps) => {
  const { disabled, checked, toggle } = props
  const { classes } = useStyles()

  return (
    <Box className={classes.switch}>
      <Switch disabled={disabled} checked={checked} onChange={toggle} />
      <Typography variant="h4" semiBold>
        {props.label}
      </Typography>
      <HelpIconPopover placement="bottom-start" maxWidth={250} title={props.informText} className={classes.popoverTrigger} />
    </Box>
  )
}

export default InteractionSwitcher
