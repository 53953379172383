import { useEffect, useContext, useState, useMemo, ComponentProps } from 'react'

import { GridColDef } from '@mui/x-data-grid-pro'
import { useParams, useLocation } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import CompaniesList, { CompaniesListProps, DownloadAll, Heading } from '_core/components/CompaniesList'
import Filters from '_core/components/filters/Companies'
import {
  nameColumn,
  scoreColumn,
  keyContactColumn,
  bestIntroducerColumn,
  lastInboundColumn,
  lastMeetingColumn,
  lastOutboundColumn,
  nextFutureMeetingColumn
} from '_core/components/grid/columns'
import GridPageFrame from '_core/components/GridPageFrame'
import { Narrow } from '_core/components/layout'
import { sortMap } from '_core/components/sort'
import Topbar from '_core/components/Topbar'

import useCompaniesUserSettings from '_core/hooks/useCompaniesUserSettings'
import { DownloadBulkParams } from '_core/hooks/useDownloadBulk'
import useInteractionsPeriodEndpointParams from '_core/hooks/useInteractionsPeriodEndpointParams'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useSortStatsEndpointParams from '_core/hooks/useSortStatsEndpointParams'
import useTagsEndpointParams from '_core/hooks/useTagsEndpointParams'
import useUserDataVisibility from '_core/hooks/useUserDataVisibility'

import DynamicEntity from '_core/DynamicEntity'
import UserSettings from '_core/UserSettings'

import { getBinary, mergeUrlWithParams } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

type AddProps = {
  extraProps: {
    addprops: Pick<CompaniesListProps, 'sort' | 'updateSort' | 'sortByField' | 'viewMode' | 'columns'> &
      Pick<ComponentProps<typeof DownloadAll>, 'download'>
  }
}

const Companies = ({
  download,
  ...props
}: Modify<CompaniesListProps, { items: UserCompaniesListItem[] }> & Pick<ComponentProps<typeof DownloadAll>, 'download'>) => {
  const { setSubHeader } = useContext(LayoutContext)

  const items = useMemo(
    () =>
      props.items?.map((company) => {
        const lastInbound = company.Stats?.LastInboundMsg || ''
        const lastOutbound = company.Stats?.LastOutboundMsg || ''
        const lastMeeting = company.Stats?.LastMeeting || ''
        const nextFutureMeeting = company.Stats?.NextFutureMeeting || ''

        return {
          id: company.CompanyMd5,
          name: company.CompanyNameText,
          score: company.Score,
          link: `${Paths._relationships}/${company.UserKeyMd5}/companies/${company.CompanyMd5}`,
          logoUrl: company.BestUrlText,
          byline: company.BestUrlText,
          keyContact: {
            name: company.AnchorEmployeeNameText,
            link: `${Paths._people}/${company.AnchorEmployeePersonMd5}`,
            sidepanel: true as SidepanelType
          },
          bestIntroducer: {
            name: company.BestKnowerNameText,
            link: `${Paths._relationships}/${company.BestKnowerUserKeyMd5}/companies/${company.CompanyMd5}`,
            sidepanel: true as SidepanelType
          },
          lastInbound,
          lastOutbound,
          lastMeeting,
          nextFutureMeeting,
          sidepanel: true as SidepanelType,
          variant: 'expandable',
          tags: company.Tags || []
        }
      }),
    [props.loading, props.items.length]
  )

  useEffect(() => {
    setSubHeader(`Companies ${props.total ? `· ${props.total}` : ''}`)
    return () => setSubHeader('')
  }, [setSubHeader, props.total])

  return (
    <CompaniesList {...props} items={items} downloadAllControl={<DownloadAll total={props.total} loading={props.loading} download={download} />} />
  )
}

type CProps = {
  onLoading: (loading: boolean, result: { total_item_count: number } | undefined) => void
  onPageSizeChange: (rowsPerPage: NumberToString<RowPerPageOptionsType>) => void
  updateSort: (sort: ScoreType | StatSortType) => void
  columns: GridColDef[]
}

const Component = (props: CProps) => {
  const { id } = useParams<{ id: string }>()
  const { search } = useLocation()
  const { teamContextValue } = useContext(TeamContext)

  const { queryParams } = useSearchQuery<ContributorsCompaniesPageParams, { modifyProps: [{ dealsChecked?: IncludeDealsType[] }] }>(['dealsChecked'])
  const { sort, viewMode, rowsPerPage = '10', keyword, and, dealsChecked = [], includeTags, excludeTags } = queryParams

  const interactionsPeriodParams = useInteractionsPeriodEndpointParams(queryParams)
  const sortStatsParams = useSortStatsEndpointParams(sort)
  const tagsParams = useTagsEndpointParams('companies', includeTags, excludeTags)

  const sortByField = Object.keys(sortMap).find((key) => sortMap[key].asc === sort || sortMap[key].desc === sort)

  const params: { name: string; value?: any }[] = [
    { name: 'teamNumber', value: teamContextValue?.teamNumber },
    { name: 'WhereNoneNextFutureMeeting', value: !!and },
    { name: 'IncludeStats', value: true },
    { name: 'WhereDidDealWithUs', value: dealsChecked.includes('hadDeal') },
    { name: 'WithCompanyTags', value: true },
    ...sortStatsParams,
    ...interactionsPeriodParams,
    ...tagsParams
  ]

  const payload = params.filter(({ value }) => !!value).reduce((acc, { name, value }) => ({ ...acc, [name]: `${value}` }), {})

  const url = search ? mergeUrlWithParams(`/users/${id}/companies`, payload) : null

  const download = {
    fileName: 'user_companies',
    requestBinary: (data: DownloadBulkParams, excludeFormerEmployees: boolean) =>
      getBinary('/companies/xl', {
        ...payload,
        ...data,
        relationOfUserKey: id,
        numAliases: '5',
        numUrls: '5',
        numEmployees: '5',
        excludeFormerRoles: excludeFormerEmployees.toString()
      })
  }

  return (
    <DynamicEntity<AddProps>
      url={url}
      component={Companies}
      updatePageSize={props.onPageSizeChange}
      pageSize={+rowsPerPage}
      list
      infinite
      search
      autoHideOnScroll
      keepMounted
      onLoading={props.onLoading}
      addprops={{
        sort,
        sortByField: sortByField || 'score',
        updateSort: props.updateSort,
        viewMode: viewMode || 'collapsed',
        columns: props.columns,
        download
      }}
      empty="No results found"
      emptySubtitle={keyword ? `No results found for your search '${keyword}'` : ''}
      id="person_companies"
    />
  )
}

const UserCompanies = () => {
  const { queryParams, updateQuery } = useSearchQuery<ContributorsCompaniesPageParams, { modifyProps: [{ dealsChecked?: IncludeDealsType[] }] }>([
    'dealsChecked'
  ])

  const { sort, viewMode, excludeEmpty } = queryParams
  const [total, setTotal] = useState<number>()
  const [contentLoading, setContentLoading] = useState<boolean>(false)

  const { dataVisibility } = useUserDataVisibility()
  const { allowFilteringByInteractions } = dataVisibility || {}

  const {
    setInitial,
    reset,
    interactionsFiltersShown,
    interactionsColumnsShown,
    handleChange,
    toggleDealSwitch,
    toggleInteractionSwitch,
    loading: filtersLoading,
    opened,
    toggleOpen: toggleFilterOpen
  } = useCompaniesUserSettings({
    isContributor: true,
    allowFilteringByInteractions
  })

  const disabled = filtersLoading || interactionsFiltersShown === null

  const onPageSizeChange = (rowsPerPage: NumberToString<RowPerPageOptionsType>) => {
    handleChange({ rowsPerPage })
  }

  const updateSort = (sort: ScoreType | StatSortType) => {
    handleChange({ sort })
  }

  const toggleExclude = () => {
    updateQuery({ excludeEmpty: excludeEmpty === 'true' ? 'false' : 'true' })
  }

  const updateViewMode = (viewMode: ViewModeType) => {
    handleChange({ viewMode })
  }

  const onLoading = (loading: boolean, result: { total_item_count: number } | undefined) => {
    setContentLoading(loading)
    setTotal(result?.total_item_count)
  }

  const filtersProps = {
    isContributor: true,
    toggleOpen: toggleFilterOpen,
    interactionsFiltersShown: !!interactionsFiltersShown,
    disabled,
    opened,
    total,
    reset,
    contentLoading,
    queryData: queryParams,
    handleChange,
    toggleDealSwitch,
    toggleInteractionSwitch
  }

  const columns = useMemo(
    () =>
      [
        { column: nameColumn },
        { column: keyContactColumn },
        { column: bestIntroducerColumn },
        { column: lastInboundColumn, condition: !!interactionsColumnsShown },
        { column: lastOutboundColumn, condition: !!interactionsColumnsShown },
        { column: lastMeetingColumn, condition: !!interactionsColumnsShown },
        { column: nextFutureMeetingColumn, condition: !!interactionsColumnsShown },
        { column: scoreColumn }
      ]
        .filter(({ condition }) => typeof condition !== 'boolean' || !!condition)
        .map(({ column }) => column),
    [interactionsColumnsShown]
  )

  const sortItems = columns.filter(({ sortable }) => sortable).map(({ headerName, field }) => ({ label: headerName || '', field }))

  const searchPlaceholder = 'Search for companies'
  return (
    <UserSettings endpoint="/usersettings/companiessearchfilter" setInitial={setInitial}>
      <Narrow>
        <Topbar nativeBack autoHideOnScroll />
      </Narrow>
      <GridPageFrame
        loading={typeof total !== 'number'}
        gridTitle="Companies"
        gridHeadingIcon={['far', 'building']}
        searchPlaceholder={searchPlaceholder}
        disabledSearch={disabled}
        filters={<Filters {...filtersProps} />}
        heading={
          <Heading
            filtersProps={{
              opened: filtersProps.opened,
              toggleOpen: filtersProps.toggleOpen,
              isContributor: filtersProps.isContributor,
              interactionsFiltersShown: filtersProps.interactionsFiltersShown,
              disabled: filtersProps.disabled
            }}
            filters={<Filters {...filtersProps} />}
            sortProps={{ items: sortItems, value: sort, update: updateSort, toggleExclude, excludeEmpty: excludeEmpty === 'true' }}
            viewProps={{ viewMode, updateViewMode }}
            searchPlaceholder={searchPlaceholder}
          />
        }
        component={<Component columns={columns} onLoading={onLoading} updateSort={updateSort} onPageSizeChange={onPageSizeChange} />}
      />
    </UserSettings>
  )
}

export default UserCompanies
