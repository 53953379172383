import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Avatar from '_shared/Avatar'
import IconPopover, { IconPopoverProps } from '_shared/popovers/IconPopover'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import { formatDate, WorkExperienceType } from 'utils/Utils'

import { ellipsis, widgetSubTitle } from 'AppTheme'

type AffiliationProps = {
  title: string
  description: JSX.Element | string
  blurb?: string
  logoUrl: string
  icons?: JSX.Element
  className?: string
}

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex'
  },
  data: {
    display: 'flex',
    flexDirection: 'column',
    color: 'rgba(0,0,0,0.87)',
    marginLeft: theme.spacing(2),
    overflowX: 'hidden',
    flex: 1,
    '& > *': ellipsis
  },
  heading: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    ...widgetSubTitle(theme)
  },
  subline: {
    marginBottom: theme.spacing(0.5)
  }
}))

export const AffiliationPopover = (props: { workExperience: WorkExperienceType; icon?: IconPopoverProps['icon'] }) => {
  const Message = (
    <>
      {props.workExperience?.companyName && (
        <Typography variant="h4" color="primary">
          No longer at {props.workExperience.companyName}
        </Typography>
      )}

      {(!props.workExperience || !props.workExperience?.companyName) && (
        <Typography variant="h4" color="primary">
          No longer at company
        </Typography>
      )}

      {props.workExperience?.jobTitle && <Typography color="text.hint">formerly {props.workExperience.jobTitle}</Typography>}

      {props.workExperience?.currentAsOf && (
        <Typography color="text.secondary">last evidence on {formatDate(props.workExperience.currentAsOf)}</Typography>
      )}
    </>
  )

  return <IconPopover icon={props.icon || ['far', 'business-time']} style={{ fontSize: 16, cursor: 'default' }} title={Message} />
}

const Affiliation = ({ title, description, blurb, logoUrl, icons, className }: AffiliationProps) => {
  const {
    classes: { root, data, heading, subline },
    cx
  } = useStyles()
  return (
    <div className={cx(root, className)}>
      <Avatar size="xs" name={title} logoUrl={logoUrl} hideName />

      <Box className={data}>
        <Skeleton condition={!title} style={{ maxWidth: 'max-content' }}>
          <Typography className={heading} component="div">
            {title || 'Very long name placeholer'}
          </Typography>
        </Skeleton>

        {(description || (!title && !description)) && (
          <Skeleton condition={!title && !description} style={{ maxWidth: 'max-content' }}>
            <Typography className={subline}>{description || (!title && 'Much longer placeholer for descriptions')}</Typography>
          </Skeleton>
        )}

        {(!title || blurb) && (
          <Skeleton condition={!title && !blurb} style={{ maxWidth: 'max-content' }}>
            <Typography color="text.secondary">{blurb || '23 September 2020'}</Typography>
          </Skeleton>
        )}
      </Box>

      {icons}
    </div>
  )
}

export default Affiliation
