import React from 'react'
import { Box } from '@mui/material'

import { LayoutContext } from './LayoutContextProvider'
import Typography from '_shared/Typography'
import Skeleton from '_shared/Skeleton'
import { ellipsisContainer } from '../AppTheme'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => ({
    typographyContainer: ellipsisContainer,
    typography: {
      textAlign: 'left',
      maxWidth: '100%',
      display: 'inline-block',
      paddingBottom: 0,
      fontSize: '20px'
    }
  })
)
const MobileNavBarTitle = (props: { title?: string; loading?: boolean }) => {
  const { mobileHeader, loading } = React.useContext(LayoutContext)
  const { classes, cx } = useStyles()
  return (
    <Box className={cx(classes.typographyContainer, 'events-onboarding')}>
      <Skeleton condition={loading} width={140} height={24}>
        <Typography variant="h3" className={classes.typography} noWrap semiBold>
          {mobileHeader}
        </Typography>
      </Skeleton>
    </Box>
  )
}

export default MobileNavBarTitle
