import React, { useContext, useEffect } from 'react'

import Page from '_shared/Page'

import Empty from '_core/components/Empty'
import { Narrow } from '_core/components/layout'
import PersonData from '_core/components/market-data/PersonData'
import ReportIncorrectData from '_core/components/market-data/Report'
import Topbar from '_core/components/Topbar'
import Widget from '_core/components/Widget'

import { LayoutContext } from 'Layout/LayoutContextProvider'

interface PageProps {
  marketData: { [key: string]: any } | null
  error: string
}

const MarketDataPage = ({ marketData, error }: PageProps) => {
  const { setSubHeader } = useContext(LayoutContext)

  useEffect(() => {
    setSubHeader('Details')
    return () => setSubHeader('')
  }, [setSubHeader])

  return (
    <Page>
      <Narrow>
        <Topbar nativeBack />
        <Widget>
          {!error && (
            <PersonData
              urlType="people"
              id={marketData?.id}
              fullName={marketData?.full_name}
              industry={marketData?.industry}
              locationName={marketData?.location_name}
              twitterUrl={marketData?.twitter_url}
              linkedinUrl={marketData?.linkedin_url}
              jobTitle={marketData?.job_title}
              jobCompanyMd5={marketData?.jobCompanyMd5}
              jobCompanyName={marketData?.job_company_name}
              jobCompanyWebsite={marketData?.job_company_website}
              jobCompanySize={marketData?.job_company_size}
              jobCompanyLocationName={marketData?.job_company_location_name}
              jobCompanyIndustry={marketData?.job_company_industry}
              jobCompanyFacebookUrl={marketData?.job_company_facebook_url}
              jobCompanyTwitterUrl={marketData?.job_company_twitter_url}
              jobCompanyLinkedinUrl={marketData?.job_company_linkedin_url}
              workEmail={marketData?.work_email}
              skills={marketData?.skills}
              interests={marketData?.interests}
              education={marketData?.education}
              experience={marketData?.experience}
              phoneNumbers={marketData?.phone_numbers}
              mobilePhone={marketData?.mobile_phone}
            />
          )}
          {error && <Empty title={error} />}
        </Widget>
        <ReportIncorrectData marketData={JSON.stringify(marketData)} />
      </Narrow>
    </Page>
  )
}

export default MarketDataPage
