import React, { useContext, useEffect } from 'react'

import { useParams } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import { emailReason } from '_core/components/dialogs/InformAboutIdentity'
import IdentifierLabel from '_core/components/IdentifierLabel'
import Repeater from '_core/components/lists/Repeater'
import TitleDescription from '_core/components/TitleDescription'

import { mergeUrlWithParams, post } from 'utils/httpUtils'
import { dateToStringWithTodayComparing } from 'utils/Utils'

import Paths from 'Paths'

type InfoEmailsProps = {
  loading: boolean
  total: number
  items: { [key: string]: any }[]
  name: string
  setTotalEmails: (val: number) => void
  style: { [key: string]: any }
}

const InfoEmails = ({ loading, setTotalEmails, name, items, ...props }: InfoEmailsProps) => {
  const { id } = useParams<{ id: string }>()
  const { teamContextValue } = useContext(TeamContext)

  useEffect(() => {
    if (typeof props.total === 'number') {
      setTotalEmails(props.total)
    }
  }, [setTotalEmails, props.total])

  const submitInvalid = (identity: string, value: number) => {
    return post(mergeUrlWithParams('/people/disqualify', { teamNumber: `${teamContextValue.teamNumber}` }), {
      identity,
      reason: emailReason[value]
    })
  }

  return (
    <Repeater
      component={TitleDescription}
      skeleton={{ size: 4, loading }}
      addprops={{ style: { paddingLeft: 5, ...props.style } }}
      items={items?.map((email: { [key: string]: any }) => ({
        title: '',
        description: (
          <IdentifierLabel
            name={name}
            identity={email.AddressText}
            type="EmailAddress"
            submit={submitInvalid}
            sourceLink={`${Paths._people}/${id}/emailSources/${email.AddressText}`}
            auditLink={`${Paths._people}/${id}/audit`}
            style={{ paddingBottom: 8 }}
          />
        ),
        blurb: email.CurrentAsOf ? `Last evidence on ${dateToStringWithTodayComparing(email.CurrentAsOf)}` : 'No data found'
      }))}
      empty="No email addresses were found"
    />
  )
}

export default InfoEmails
