import { PropsWithChildren, useContext, ReactElement } from 'react'

import { IconName, IconPrefix } from '@fortawesome/fontawesome-common-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Dialog as MuiDialog, DialogTitle, DialogContent, DialogProps as MuiDialogProps, DialogActions, CircularProgress } from '@mui/material'
import { useRouteMatch } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import DialogProvider, { DialogContext } from '_core/context/Dialog'

import { Button, IconButton } from '_shared/buttons'

import { useWide } from '_core/components/layout'
import { successAnimation } from '_core/components/Success'

import Paths from 'Paths'

import Typography from './Typography'

const useStyles = makeStyles<{
  noTitleIcon?: boolean
  circleProgressSize?: number
  maxWidth?: number
  height?: number | string
}>()((theme, { noTitleIcon, circleProgressSize, maxWidth, height }) => ({
  titleWrapper: {
    padding: theme.spacing(2)
  },
  titleInnerWrapper: {
    display: 'grid',
    alignItems: 'start',
    gridTemplateColumns: noTitleIcon ? '1fr auto' : 'auto 1fr auto'
  },
  titleIconWrapper: {
    width: '45px',
    height: '45px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FEFCF9',
    border: '0.5px solid #E69847',
    borderRadius: '50%',
    marginRight: theme.spacing(2)
  },
  title: {
    color: theme.palette.text.primary,
    maxWidth: '100%',
    lineHeight: '25px',
    fontSize: '18px',
    fontWeight: 600,
    fontStyle: 'normal',
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px'
    }
  },
  subtitle: {
    lineHeight: '18px'
  },
  content: {
    marginBottom: theme.spacing(2),
    '&, &:first-of-type': {
      padding: `0px ${theme.spacing(2)}`
    }
  },
  contentWrapper: {
    maxHeight: 'calc(100% - 57px)',
    marginBottom: '57.5px',
    overflow: 'auto'
  },
  animation: {
    '& .checkmark': {
      width: '45px',
      height: '45px',
      marginRight: theme.spacing(2),
      right: 0,
      top: 0
    }
  },
  titleLink: {
    fontSize: '14px',
    padding: theme.spacing(0.5),
    marginLeft: theme.spacing(2),
    marginRight: `-${theme.spacing(0.5)}`
  },
  iconWrapper: {
    display: 'flex'
  },
  paper: {
    maxWidth: maxWidth || 400,
    height
  },
  actions: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1.5)}`,
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: `1px solid ${theme.palette.text.secondary}`,
    position: 'absolute',
    width: '100%',
    boxSizing: 'border-box',
    bottom: 0,
    left: 0
  },
  hidden: {
    opacity: 0,
    pointerEvents: 'none'
  },
  circularProgress: {
    position: 'absolute',
    top: `calc(50% - ${(circleProgressSize || 0) / 2}px)`,
    left: `calc(50% - ${(circleProgressSize || 0) / 2}px)`
  }
}))

type TitleProps = {
  title: string
  subtitle?: string
  icon?: [IconPrefix, IconName] | string
  iconColor?: string
  iconFont?: number
  link?: string
  linkTooltip?: string
  linkIcon?: [IconPrefix, IconName]
  noWrap?: boolean
  successTitle?: string
}

const Title = ({ title, subtitle, icon, iconColor = '#E69847', iconFont = 14, link, linkTooltip, linkIcon, noWrap, successTitle }: TitleProps) => {
  const { successMode } = useContext(DialogContext)
  const noTitleIcon = !icon?.length && !successMode
  const { classes } = useStyles({ noTitleIcon })

  return (
    <DialogTitle className={classes.titleWrapper}>
      <Box className={classes.titleInnerWrapper}>
        {!successMode && (
          <>
            {Array.isArray(icon) && icon?.length && (
              <Box className={classes.titleIconWrapper}>
                <FontAwesomeIcon icon={icon} style={{ fontSize: iconFont, color: iconColor }} />
              </Box>
            )}

            {typeof icon === 'string' && <img src={icon} width="35px" height="35px" alt="title icon" style={{ marginRight: 16 }} />}
            <Box display="flex" justifyContent="center" flexDirection="column" height="100%" maxWidth="100%" overflow="hidden">
              {title && (
                <Typography noWrap={noWrap} className={classes.title}>
                  {title}
                </Typography>
              )}
              {subtitle && (
                <Typography color="text.secondary" className={classes.subtitle}>
                  {subtitle}
                </Typography>
              )}
            </Box>

            {link && (
              <IconButton
                hint={linkTooltip || 'Open the link'}
                classes={{ root: classes.titleLink }}
                component="a"
                href={link}
                icon={linkIcon ? linkIcon : ['fas', 'question-circle']}
                target="_blank"
                size="small"
                className={classes.iconWrapper}
              />
            )}
          </>
        )}

        {successMode && (
          <>
            <Box className={classes.animation}>{successAnimation}</Box>
            <Box display="flex" justifyContent="center" flexDirection="column" height="100%" maxWidth="100%" overflow="hidden">
              <Typography noWrap={noWrap} className={classes.title}>
                {successTitle || 'Success'}
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </DialogTitle>
  )
}

const Content = (props: PropsWithChildren<{ successContent?: string | ReactElement }>) => {
  const { classes } = useStyles({})
  const { children, successContent } = props
  const { successMode } = useContext(DialogContext)

  return <DialogContent classes={{ root: classes.content }}>{successMode ? successContent : children}</DialogContent>
}

const Actions = (props: PropsWithChildren<{ successActions?: ReactElement }>) => {
  const { classes } = useStyles({})
  const { children, successActions } = props
  const { successMode } = useContext(DialogContext)
  return (
    <DialogActions disableSpacing classes={{ root: classes.actions }}>
      {successMode ? successActions : children}
    </DialogActions>
  )
}

const DefaultSuccessAction = ({ close }: { close: () => void }) => {
  return (
    <Button variant="text" onClick={close} disablePR>
      Close
    </Button>
  )
}

const Dialog = ({
  open,
  onClose,
  keepMounted,
  disableRestoreFocus,
  onAnimationEnd,
  children,
  maxWidth,
  height,
  loading,
  success,
  circleProgressSize = 40,
  title
}: Modify<MuiDialogProps, { title: ReactElement | string; maxWidth?: number; height?: number | string }> & {
  loading?: boolean
  circleProgressSize?: number
  success?: boolean
}) => {
  const teamPage = useRouteMatch(Paths._teams) /* temporary solution for team page modals */
  const wide = useWide()
  const { classes, cx } = useStyles({ circleProgressSize, maxWidth, height })

  const handleOnClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (!loading && onClose) {
      onClose(event, reason)
    }
  }

  return (
    <DialogProvider successMode={success}>
      <MuiDialog
        keepMounted={keepMounted}
        disableRestoreFocus={disableRestoreFocus}
        onClose={handleOnClose}
        open={open}
        disableScrollLock={!wide && !!teamPage}
        onAnimationEnd={onAnimationEnd}
        classes={{ paper: classes.paper }}
      >
        {title}
        {loading && <CircularProgress size={circleProgressSize} classes={{ root: classes.circularProgress }} />}
        <Box className={cx({ [classes.hidden]: loading }, classes.contentWrapper)}>{children}</Box>
      </MuiDialog>
    </DialogProvider>
  )
}

export { Title as DialogTitle, Content as DialogContent, Actions as DialogActions, DefaultSuccessAction }
export default Dialog
