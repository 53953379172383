import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { Moment as MomentType } from 'moment'
import { useLocation } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import Timeline, { TimelineList } from '_core/components/lists/Timeline'
import NameLink from '_core/components/NameLink'

import useSidepanel from '_core/hooks/useSidepanel'

import { stringifyUrl } from '_core/helpers/browser'

import { getLocal, formatTime, formatDateTime } from 'utils/Utils'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  time: {
    zIndex: 1,
    position: 'absolute',
    background: 'rgba(244 244 244 / 60%)',
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    height: 20,
    left: 12
  },
  timeline: {
    background: theme.palette.background.light,
    '& .MuiTimelineSeparator-root': {
      marginTop: 16,
      marginBottom: -16
    },
    '& .MuiTimelineDot-root': {
      background: 'none',
      border: 'none',
      boxShadow: 'none',
      padding: 0,
      color: theme.palette.primary.main
    }
  }
}))

type InteractionTypes = {
  time: string
  title: string
  byline?: string
  byline2?: string
  isSelected?: boolean
}

export const getTime = (time: MomentType) => {
  if (getLocal(time).isSame(getLocal(), 'day')) {
    return formatTime(time)
  }
  if (getLocal(time).isSame(getLocal(), 'week')) {
    return formatDateTime(time, 'ddd MM/DD h:mm A')
  }
  return formatDateTime(time)
}

export const Interaction = (props: InteractionTypes) => {
  const { classes } = useStyles()
  const { time, title, byline, byline2 } = props

  return (
    <Box className={classes.root}>
      <Skeleton condition={!time} width={120} height={28}>
        <Box mb={3.5}>
          <Typography classes={{ root: classes.time }} color="text.secondary">
            <span>{time || 'Placeholder'}</span>
          </Typography>
        </Box>
      </Skeleton>
      <Skeleton condition={!title}>
        <Typography noWrap semiBold>
          {title || 'Very long name placeholder'}
        </Typography>
      </Skeleton>
      {(!title || byline) && (
        <Skeleton condition={!title && !byline}>
          <Typography noWrap>{byline || 'Much longer placeholder for byline'}</Typography>
        </Skeleton>
      )}
      {(!title || byline2) && (
        <Skeleton condition={!title && !byline2}>
          <Typography color="text.hint" noWrap>
            {byline2 || 'Placeholer for byline2'}
          </Typography>
        </Skeleton>
      )}
    </Box>
  )
}

type InteractionsType = {
  items: { [key: string]: any }[]
  loading: boolean
  errorMessage?: string
}

const Interactions = (props: InteractionsType) => {
  const { pathname } = useLocation()
  const { classes } = useStyles()
  const { openedUrl } = useSidepanel('preview')

  return (
    <Timeline className={classes.timeline} loading={props.loading}>
      <TimelineList
        items={props.items?.map((item: any) => {
          const link = stringifyUrl(`${Paths._messages}/${item.sourceKey}`, { deleteBackLink: pathname })
          return {
            time: getTime(item.receivedDateTime),
            title: item.rawSubject || '(No subject)',
            byline: item.body,
            byline2: (
              <NameLink
                sidepanel="preview"
                variant="light"
                url={stringifyUrl(`${Paths._people}/${item.senderEmail}`, { name: item.senderName, email: item.senderEmail })}
                name={item.senderName}
              />
            ),
            dotIcon: <FontAwesomeIcon icon={['fas', 'envelope']} style={{ fontSize: 14 }} />,
            link,
            isSelected: link === openedUrl,
            sidepanel: 'preview',
            component: 'div'
          }
        })}
        skeleton={{ size: 7, loading: props.loading }}
        component={Interaction}
      />
    </Timeline>
  )
}

export default Interactions
