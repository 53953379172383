import React, { useEffect, useRef } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { isSidepanel } from '_pages/sidebar'

import { Button } from '_shared/buttons'
import FloatingButton from '_shared/buttons/FloatingButton'
import { IMenuAction } from '_shared/buttons/FloatingMenuButton'
import Typography from '_shared/Typography'

import HelpLinkButton from '_core/components/HelpLinkButton'
import { Narrow, Wide } from '_core/components/layout'
import { useAutoHideOnScrollStyles } from '_core/components/layout/autohide-on-scroll'
import SearchInput from '_core/components/SearchInput'
import SidepanelLink from '_core/components/SidepanelLink'
import Topbar from '_core/components/Topbar'
import Widget from '_core/components/Widget'

import useOnboarding from '_core/hooks/useOnboarding'
import { useTabs } from '_core/hooks/useTabs'

import { checkOutlook } from '_core/helpers/outlook'

import TabSet from 'SharedComponents/TabSet'

import Paths, { DataAdminSubPaths } from 'Paths'

const useStyles = makeStyles()((theme) => ({
  heading: {
    width: '100%',
    zIndex: 1,
    marginBottom: theme.spacing(2),
    background: theme.palette.background.light,
    [theme.breakpoints.up('md')]: {
      background: 'inherit',
      position: 'inherit'
    }
  },
  tabSet: {
    padding: 0
  }
}))

export const baseUrl = DataAdminSubPaths.privilegedCompanies

const tabs = [
  {
    label: 'COMPANIES',
    routesMatch: [baseUrl],
    className: 'privileged-companies-onboarding'
  },
  {
    label: 'PRIVILEGED USERS',
    routesMatch: [`${baseUrl}/members`],
    className: 'privileged-members-onboarding'
  },
  {
    label: 'EXCEPTIONS',
    routesMatch: [`${baseUrl}/contacts`],
    className: 'privileged-contacts-onboarding'
  }
]

export const Buttons = ({ action }: { action: IMenuAction }) => {
  return (
    <>
      <Wide>
        <SidepanelLink linkProps={{ to: action.link }} sidepanel={action.sidepanel}>
          <Button variant="outlined" color="primary" startIcon={<FontAwesomeIcon icon={action.icon} style={{ fontSize: 14 }} />}>
            {action.label}
          </Button>
        </SidepanelLink>
      </Wide>
      <Narrow>
        <SidepanelLink linkProps={{ to: action.link }}>
          <FloatingButton>
            <FontAwesomeIcon icon={action.icon} size="lg" />
          </FloatingButton>
        </SidepanelLink>
      </Narrow>
    </>
  )
}

const Tabs = ({ loading }: { loading: boolean }) => {
  const { tabIndex, setTabIndex, tabChanged } = useTabs(tabs)
  const { classes } = useStyles()
  const { startOnboarding } = useOnboarding()

  useEffect(() => {
    if (!loading) {
      startOnboarding(DataAdminSubPaths.privilegedCompanies)
    }
  }, [loading])

  return <TabSet className={classes.tabSet} tabIndex={tabIndex} setTabIndex={setTabIndex} tabs={tabs} onChange={tabChanged} />
}

export const HelpLink = () => (
  <HelpLinkButton
    hint="Learn more about restricted companies"
    style={{ fontSize: 18 }}
    to={{ pathname: 'https://help.dotalign.com/article/sppjq1nml0-restricted-companies' }}
  />
)

export const SearchHeading = ({ loading }: { loading: boolean }) => {
  const hasSidebar = isSidepanel() || checkOutlook()
  const { autoHideClassName } = useAutoHideOnScrollStyles(true)
  const anchorRef = useRef<HTMLDivElement | null>(null)

  return (
    <>
      <Topbar nativeBack={Paths._dataAdmin} action={<HelpLink />} />
      <Widget className={autoHideClassName} sticky={hasSidebar ? 88 : 60}>
        <Tabs loading={loading} />
        <div ref={anchorRef}>
          <SearchInput placeholder="Search" variant="collapsed" opened />
        </div>
      </Widget>
    </>
  )
}

const Heading = ({ loading }: { loading: boolean }) => (
  <Wide>
    <Box display="flex" alignItems="center">
      <Typography variant="h1">Restricted companies</Typography>
      <HelpLink />
    </Box>
    <Box mt={2}>
      <Tabs loading={loading} />
    </Box>
  </Wide>
)

export default Heading
