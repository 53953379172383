import React, { useContext, useEffect } from 'react'

import Page from '_shared/Page'

import Empty from '_core/components/Empty'
import { Narrow } from '_core/components/layout'
import CompanyData from '_core/components/market-data/CompanyData'
import ReportIncorrectData from '_core/components/market-data/Report'
import Topbar from '_core/components/Topbar'
import Widget from '_core/components/Widget'

import { LayoutContext } from 'Layout/LayoutContextProvider'

interface PageProps {
  marketData: { [key: string]: any } | null
  error: string
}

const MarketDataPage = ({ marketData, error }: PageProps) => {
  const { setSubHeader } = useContext(LayoutContext)

  useEffect(() => {
    setSubHeader('Details')
    return () => setSubHeader('')
  }, [setSubHeader])

  return (
    <Page>
      <Narrow>
        <Topbar nativeBack />
        {!error && (
          <Widget>
            <CompanyData
              id={marketData?.id}
              name={marketData?.name}
              location={marketData?.location}
              founded={marketData?.founded}
              website={marketData?.website}
              employeeCount={marketData?.employee_count}
              facebookUrl={marketData?.facebook_url}
              twitterUrl={marketData?.twitter_url}
              linkedinUrl={marketData?.linkedin_url}
              industry={marketData?.industry}
              type={marketData?.type}
              tags={marketData?.tags}
              summary={marketData?.summary}
            />
          </Widget>
        )}
        {error && <Empty title={error} />}
        <ReportIncorrectData marketData={JSON.stringify(marketData)} />
      </Narrow>
    </Page>
  )
}

export default MarketDataPage
