import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { Button } from '_shared/buttons'
import IconPopover from '_shared/popovers/IconPopover'
import Typography from '_shared/Typography'

const useStyles = makeStyles()((theme) => ({
  iconWrapper: {
    flexGrow: 1,
    width: 45,
    height: 45,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FEFCF9',
    border: '0.5px solid #E69847',
    borderRadius: '50%',
    marginRight: theme.spacing(2)
  },
  privacy: {
    display: 'grid',
    alignItems: 'start',
    gridTemplateColumns: 'auto 1fr auto'
  },
  icon: {
    fontSize: 14,
    marginLeft: theme.spacing(1)
  }
}))

const InteractionsPrivacyMsg = () => {
  const { classes } = useStyles()

  const PrivacyMsg = (
    <Box className={classes.privacy}>
      <Box className={classes.iconWrapper}>
        <FontAwesomeIcon icon={['fas', 'lock']} style={{ fontSize: '14px', color: '#E69847' }} />
      </Box>
      <Typography>
        Users can only view events and interactions that they are a part of. Events and interactions are fetched directly from Microsoft Graph and not
        stored in DotAlign.{' '}
        <Button<'a'>
          component="a"
          variant="link"
          bold={false}
          disablePadding
          href="https://help.dotalign.com/article/7t2k2kpfzt-events-page"
          target="_blank"
        >
          Learn more
        </Button>
      </Typography>
    </Box>
  )

  return <IconPopover placement="bottom-start" icon={['fas', 'lock']} color="#E69847" className={classes.icon} maxWidth={390} title={PrivacyMsg} />
}

export default InteractionsPrivacyMsg
