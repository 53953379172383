import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Chip } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import Popover from '_shared/popovers/Popover'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

const useStyles = makeStyles()((theme) => ({
  root: {
    maxWidth: '100%'
  },
  label: {
    fontSize: 14,
    lineHeight: '22px',
    paddingTop: 2,
    paddingBottom: 2
  },
  byLineIcon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
    fontSize: 16,
    width: '16px'
  },
  filled: {
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.secondary.light
  }
}))

const ExternalTag = ({ tagData, className }: { tagData: string; className?: string }) => {
  const { classes, cx } = useStyles()
  const { root, label } = classes

  return (
    <Skeleton condition={!tagData} width="60px" height="26px" variant="rounded">
      <Chip variant="outlined" size="small" label={tagData} classes={{ root: cx(root, className), label }} />
    </Skeleton>
  )
}

export const ExtraTagsPopover = ({
  name,
  title,
  tags,
  triggerElement
}: {
  name: string
  triggerElement: JSX.Element
  tags: string[]
  title: string
}) => {
  const { classes } = useStyles()

  return (
    <Popover placement="bottom-start" triggerElement={triggerElement}>
      <Box maxHeight="80vh" overflow="auto" maxWidth={500}>
        <Typography variant="h4" bold>
          {name}
        </Typography>
        <Box display="flex" alignItems="center" pt={1}>
          <FontAwesomeIcon icon={['fas', 'hashtag']} className={classes.byLineIcon} />
          <Typography semiBold>{title}</Typography>
        </Box>
        <Box display="flex" flexWrap="wrap" mt={0.5}>
          {tags.map((tag: string) => (
            <Box my={0.5} mr={1} key={tag}>
              <ExternalTag tagData={tag} />
            </Box>
          ))}
        </Box>
      </Box>
    </Popover>
  )
}

export default ExternalTag
