import { SyntheticEvent, useCallback, useContext } from 'react'

import { TeamContext } from '_core/context/TeamContext'

import { Button } from '_shared/buttons'
import Dialog, { DialogTitle, DialogActions, DialogContent } from '_shared/Dialog'
import Combobox from '_shared/forms/Combobox'

import CreatePersonOptionDialog, {
  renderOption,
  useCreatePersonOption,
  PersonOptionType,
  SuggestPersonOptionType
} from '_core/components/dialogs/CreatePersonOptionDialog'
import { transformPersonOption } from '_core/components/introductions/options'

import useAsyncCombobox from '_core/hooks/useAsyncCombobox'
import useDialog from '_core/hooks/useDialog'
import { useLookUpMarketDataPeople } from '_core/hooks/useLookup'
import useSuggestOptionCreation from '_core/hooks/useSuggestOptionCreation'

const TriggerEl = (props: { open: () => void }) => (
  <Button onClick={props.open} disabled={!props.open} color="primary" variant="text">
    Pick
  </Button>
)

type ContactType = Pick<PersonOptionType, 'name' | 'email'>

const ContactPickerDialog = ({
  close,
  isOpened,
  contact,
  handleChange,
  filterOptions
}: {
  close: () => void
  isOpened: boolean
  contact: ContactType
  handleChange: (contact: ContactType | null) => void
  filterOptions: (options: PersonOptionType[]) => (PersonOptionType | SuggestPersonOptionType)[]
}) => {
  const { teamContextValue } = useContext(TeamContext)
  const { isDialogOpened, openDialog, closeDialog } = useDialog()
  const { closeCreateOptionDialog, setCreatedOption, createdOption } = useCreatePersonOption(closeDialog)
  const { lookUpMarketDataPeople, forceAbort } = useLookUpMarketDataPeople()

  const { inputValue, open, options, optionsLoading, handleClose, handleOpen, handleFocus, handleInputChange } = useAsyncCombobox<PersonOptionType>({
    loadOptions: useCallback(
      async (searchTerm: string) => {
        const result = await lookUpMarketDataPeople(`${teamContextValue.teamNumber}`, searchTerm)
        if (result) {
          return result.map((person) => ({
            name: person.full_name,
            email: person.work_email,
            jobTitle: person.job_title,
            company: person.job_company_name
          }))
        }
      },
      [lookUpMarketDataPeople]
    ),
    forceAbort
  })

  const filterWithSuggest = useSuggestOptionCreation<PersonOptionType, SuggestPersonOptionType>({
    loading: optionsLoading,
    filterFn: filterOptions,
    transformOption: transformPersonOption
  })

  const handleContactSelect = (e: SyntheticEvent<Element, Event>, contact: ContactType | SuggestPersonOptionType | null) => {
    if (contact) {
      if ('label' in contact) {
        openDialog()
        setCreatedOption({
          name: contact.name,
          email: contact.email || ''
        })
      } else {
        handleChange(contact)
        close()
      }
    }
  }

  const handleCreationSubmit = (createdOption: Pick<PersonOptionType, 'name' | 'email'>) => {
    handleChange(createdOption)
    close()
  }

  return (
    <>
      <Dialog open={isOpened} onClose={close} title={<DialogTitle title="Select contact" />}>
        <DialogContent>
          <Combobox
            autoFocus
            placeholder="Search for person"
            icon={['far', 'search']}
            value={contact}
            loading={optionsLoading}
            open={open}
            inputValue={inputValue}
            options={options}
            onChange={handleContactSelect}
            getOptionLabel={(option) => option.name || ''}
            renderOption={renderOption}
            filterOptions={filterWithSuggest}
            onInputChange={handleInputChange}
            onOpen={handleOpen}
            onClose={handleClose}
            onFocus={handleFocus}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="secondary" onClick={close} disablePL>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <CreatePersonOptionDialog opened={isDialogOpened} close={closeCreateOptionDialog} submit={handleCreationSubmit} value={createdOption} />
    </>
  )
}

ContactPickerDialog.TriggerEl = TriggerEl

export default ContactPickerDialog
