import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { makeStyles } from 'tss-react/mui'

import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import RemoveTeamMember from '_core/components/dialogs/RemoveTeamMember'
import Heading from '_core/components/Heading'
import { useWide } from '_core/components/layout'
import Item from '_core/components/lists/Item'
import Repeater from '_core/components/lists/Repeater'
import Widget from '_core/components/Widget'

import useDialog from '_core/hooks/useDialog'

import { del } from 'utils/httpUtils'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  settingsRow: {
    display: 'grid',
    alignItems: 'start',
    gridTemplateColumns: 'minmax(0, 1fr) auto',
    '& a': {
      color: theme.palette.primary.main
    }
  },
  email: {
    display: 'block',
    maxWidth: '100%'
  },
  icon: {
    padding: theme.spacing(1.5),
    margin: `-${theme.spacing(1.5)}`,
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: '50%',
    fontSize: '15px',
    width: '15px',
    color: '#979797',
    '&:hover': {
      color: '#2E9CBD',
      backgroundColor: 'rgba(27, 149, 187, 0.04)'
    }
  }
}))

interface IOtherRole {
  credentialType: 'ActiveDirectoryTeamMember' | 'OwnsEmailAddress'
  displayName: string
  id: string
  roleType: Exclude<Role, 'Member'>
  lastSyncDateTime?: string
  isAccessProhibited: boolean
}

interface IOtherRoleItem extends IOtherRole {
  action: JSX.Element | null
}

const OtherRoleItem = ({ id, displayName, roleType, credentialType, lastSyncDateTime, action }: IOtherRoleItem) => {
  const { classes } = useStyles()

  return (
    <Box className={classes.settingsRow}>
      <Box>
        <Skeleton variant="text" width="100%" height="21px" condition={!displayName}>
          <Typography variant="body1">{displayName || 'Deleted role'}</Typography>
        </Skeleton>
        {credentialType === 'OwnsEmailAddress' && (
          <Typography variant="body1" className={classes.email} noWrap>
            <a href={`mailto:${id}`}>{id}</a>
          </Typography>
        )}
        <Skeleton variant="text" width="100%" height="21px" condition={!roleType}>
          <Typography variant="body1" color="text.secondary">
            {roleType}
          </Typography>
        </Skeleton>
        <Skeleton variant="text" width="100%" condition={!displayName && !lastSyncDateTime}>
          <Typography variant="body1" color="text.secondary">
            {credentialType === 'ActiveDirectoryTeamMember'
              ? lastSyncDateTime
                ? `synced ${moment(lastSyncDateTime).fromNow()}`
                : `sync pending`
              : ''}
          </Typography>
        </Skeleton>
      </Box>
      {action}
    </Box>
  )
}

interface IOtherRolesProps {
  team?: Team
  items: {
    data: IOtherRole[]
    teamNumber: number
  }
  admin?: boolean
  handleChange: () => void
  loading: boolean
}

const OtherRolesWidget = ({ team, items, admin, handleChange, loading }: IOtherRolesProps) => {
  const { dialogContentProps: openedDialog, openDialog, closeDialog } = useDialog<IOtherRole | null>(null)
  const [load, setLoad] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const wide = useWide()
  const { classes } = useStyles()

  useEffect(() => {
    if (loading && load) {
      console.log(loading, load)
      setLoad(false)
    }
  }, [loading, load])

  const removeCredential = (credential: any) => {
    if (credential && team?.id) {
      setLoad(true)
      del(`/teams/${team.id}/membershipcredentials/${credential.id}`).then(() => {
        enqueueSnackbar(`"${credential.displayName}" has been flagged for removal from the team`)
        closeDialog()
        handleChange()
      })
    }
  }

  const C = () => <FontAwesomeIcon icon={['far', 'ellipsis-v']} className={classes.icon} />

  const getActions = (role: IOtherRole) =>
    [
      role.credentialType === 'ActiveDirectoryTeamMember' && {
        name: 'Edit',
        icon: ['far', 'edit'],
        link: `${Paths._teams}/${team?.id}/associations/group/${role.id}/edit`,
        sidepanel: true
      },
      role.credentialType === 'OwnsEmailAddress' && {
        name: 'Edit',
        icon: ['far', 'edit'],
        link: `${Paths._teams}/${team?.id}/associations/user/${encodeURIComponent(role.id)}/edit`,
        sidepanel: true
      },
      { name: 'Remove', icon: ['far', 'times'], action: () => openDialog(role) },
      role.credentialType === 'ActiveDirectoryTeamMember' && {
        name: 'Go to group',
        icon: ['far', 'external-link'],
        externalLink: `https://portal.azure.com/#view/Microsoft_AAD_IAM/GroupDetailsMenuBlade/~/Overview/groupId/${role.id}`
      }
    ].filter(Boolean)

  return (
    <>
      <Widget scope={wide ? 'none' : 'default'} style={{ borderRadius: 0, paddingBottom: wide || admin ? '16px' : '56px', marginBottom: 0 }}>
        <Heading title="Other roles" icon={['far', 'list']} count={loading ? -1 : items?.data.length} />
        <Box marginTop={{ md: -1.5 }}>
          <Repeater
            direction="vertical"
            component={OtherRoleItem}
            skeleton={{ size: 1, loading: loading || load }}
            items={items?.data?.map((role: IOtherRole) => ({
              id: role.id,
              displayName: role.displayName,
              roleType: role.roleType,
              lastSyncDateTime: role.lastSyncDateTime,
              credentialType: role.credentialType,
              action: admin ? (
                <Item
                  component={C}
                  addprops={{ style: { minWidth: 'auto', padding: '0 0 0 12px' } }}
                  disabled={loading || load}
                  item={{
                    menu: {
                      actions: getActions(role)
                    }
                  }}
                />
              ) : null
            }))}
          />
        </Box>
      </Widget>

      {team && (
        <RemoveTeamMember
          credential={openedDialog}
          team={team}
          isOpen={!!openedDialog}
          close={closeDialog}
          remove={() => removeCredential(openedDialog)}
          loading={loading || load}
        />
      )}
    </>
  )
}

export default OtherRolesWidget
