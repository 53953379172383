import React, { isValidElement } from 'react'

import { makeStyles } from 'tss-react/mui'

import Typography from '_shared/Typography'

const useStyles = makeStyles()((theme) => ({
  title: {
    letterSpacing: '0.1px',
    marginBottom: theme.spacing(0.5)
  }
}))

export type FormLabelProps = { label: string | React.ReactNode }

const FormLabel = ({ label }: FormLabelProps) => {
  const { classes } = useStyles()

  return (
    <>
      {typeof label === 'string' && (
        <Typography component="div" className={classes.title} color="text.secondary" noWrap semiBold>
          {label}
        </Typography>
      )}
      {isValidElement(label) && label}
    </>
  )
}

export default FormLabel
