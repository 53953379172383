import { useContext, useState } from 'react'

import { RadioGroup } from '@mui/material'
import { useForm } from 'react-hook-form'
import { makeStyles } from 'tss-react/mui'

import { TeamContext } from '_core/context/TeamContext'

import { Button, IconButton } from '_shared/buttons'
import Dialog, { DialogActions, DialogContent, DialogTitle, DefaultSuccessAction } from '_shared/Dialog'
import Radio from '_shared/forms/Radio'

import ManualEditSuccessMessage from '_core/components/ManualEditSuccessMessage'

import { mergeUrlWithParams, post } from 'utils/httpUtils'

const useStyles = makeStyles()(() => ({
  icon: {
    fontSize: 13
  }
}))

const TriggerEl = ({ open, className }: { open: () => void; className?: string }) => {
  const {
    classes: { icon },
    cx
  } = useStyles()

  return (
    <IconButton
      disablePY
      disablePR
      onClick={open}
      classes={{ root: cx(icon, className) }}
      hint="Inform us about this phone number"
      icon={['far', 'times']}
      size="small"
    />
  )
}

export type InformPhoneType = {
  personName?: string
  personId?: string
  phoneNumber?: string
  isInvalid?: boolean
  isFormer?: boolean
}

type PhoneOption = 'CurrentToggler' | 'NeverHadThisNumber' | 'IsStillCurrent'

const InformAboutPhoneNumberDialog = ({
  isOpened,
  close,
  successMode,
  personName,
  personId,
  phoneNumber,
  isInvalid = false,
  isFormer = false,
  openSuccess
}: {
  isOpened: boolean
  close: () => void
  successMode: boolean
  openSuccess: () => void
} & InformPhoneType) => {
  const [loading, setLoading] = useState<boolean>(false)
  const { register, watch } = useForm<{ phoneOption: PhoneOption }>({ mode: 'onChange', shouldUnregister: true })
  const { teamContextValue } = useContext(TeamContext)

  const handleNext = async () => {
    const reason = watch().phoneOption

    if (reason && personId && phoneNumber) {
      const params = {
        IsCurrent: `${reason === 'IsStillCurrent' || (reason === 'CurrentToggler' && isFormer)}`,
        NeverHadThisNumber: `${reason === 'NeverHadThisNumber'}`,
        PhoneDigits: phoneNumber
      }

      setLoading(true)
      await post(mergeUrlWithParams(`/people/${personId}/phoneNumbers`, { teamNumber: `${teamContextValue.teamNumber}` }), params)
      setLoading(false)
      openSuccess()
    }
  }

  return (
    <Dialog open={isOpened} onClose={close} success={successMode} loading={loading} title={<DialogTitle title={phoneNumber || ''} noWrap />}>
      <DialogContent successContent={<ManualEditSuccessMessage />}>
        <RadioGroup name="phoneOption">
          <Radio
            {...register('phoneOption')}
            value="CurrentToggler"
            label={
              <>
                This phone number is {isFormer ? '' : 'not'} currently valid for <b>{personName}</b> {isFormer ? '' : 'anymore'}
              </>
            }
          />
          {!!isInvalid && (
            <Radio
              {...register('phoneOption')}
              value="IsStillCurrent"
              label={
                <>
                  This phone number is still currently valid for <b>{personName}</b>
                </>
              }
            />
          )}
          {!isInvalid && (
            <Radio
              {...register('phoneOption')}
              value="NeverHadThisNumber"
              label={
                <>
                  This was never a valid phone number for <b>{personName}</b>
                </>
              }
            />
          )}
        </RadioGroup>
      </DialogContent>

      <DialogActions successActions={<DefaultSuccessAction close={close} />}>
        <Button variant="text" color="secondary" onClick={close}>
          Cancel
        </Button>

        <Button variant="text" onClick={handleNext} disabled={!watch().phoneOption} disablePR>
          Next
        </Button>
      </DialogActions>
    </Dialog>
  )
}

InformAboutPhoneNumberDialog.TriggerEl = TriggerEl

export default InformAboutPhoneNumberDialog
