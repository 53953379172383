import React from 'react'

import { Link } from 'react-router-dom'

import { IconButton } from '_shared/buttons'
import { IconButtonProps } from '_shared/buttons/Icon'

const HelpLinkButton = ({ target, ...props }: Omit<IconButtonProps<typeof Link>, 'icon' | 'component' | 'children' | 'ref'>) => {
  return <IconButton<typeof Link> icon={['far', 'question-circle']} component={Link} target={target || '_blank'} {...props} />
}

export default HelpLinkButton
