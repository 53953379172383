import { useState, useEffect, useContext } from 'react'

import { TeamContext } from '_core/context/TeamContext'

import { EditForm } from '_core/components/EditCompany'

import { validUrl } from '_core/helpers/string'

import { mergeUrlWithParams, post } from 'utils/httpUtils'

const useCompanyForm = (props: { name?: string; url?: string } = {}) => {
  const { teamContextValue } = useContext(TeamContext)
  const { name: pName = '', url: pUrl = '' } = props
  const [name, setName] = useState<string>()
  const [url, setUrl] = useState<string>()

  const setInitial = () => {
    setName(pName)
    setUrl(pUrl)
  }

  useEffect(() => {
    setInitial()
  }, [])

  const updated: { [key in 'name' | 'url']: boolean } = {
    name: name !== pName,
    url: url !== pUrl
  }

  const save = async () => {
    await post(mergeUrlWithParams('/companies', { teamNumber: `${teamContextValue.teamNumber}` }), {
      accounts: [{ name: name?.trim(), url: url?.trim() }]
    })
    setInitial()
  }

  const allowSubmit = !!(
    name &&
    validUrl(url) &&
    (Object.getOwnPropertyNames(updated) as (keyof typeof updated)[]).filter((prop) => updated[prop]).length > 0
  )

  const isReady = typeof name === 'string' && typeof url === 'string'
  return {
    name: name || '',
    url: url || '',
    setName,
    setUrl,
    allowSubmit,
    updated,
    save,
    isReady,
    Form: isReady ? EditForm : () => null
  }
}

export default useCompanyForm
