import { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Checkbox, FormControlLabel, RadioGroup } from '@mui/material'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { Button, IconButton } from '_shared/buttons'
import Dialog, { DialogActions, DialogContent, DialogTitle, DefaultSuccessAction } from '_shared/Dialog'
import Radio from '_shared/forms/Radio'
import Typography from '_shared/Typography'

import ManualEditSuccessMessage from '_core/components/ManualEditSuccessMessage'
import SidepanelLink from '_core/components/SidepanelLink'

import useLocalStorage from '_core/hooks/useLocalStorage'

const useStyles = makeStyles()((theme) => ({
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: `-${theme.spacing(0.5)}`,
    marginBottom: `-${theme.spacing(0.5)}`,
    '& > div': {
      display: 'inline-flex',
      alignItems: 'center',
      '&:first-of-type': {
        maxWidth: 'calc(100% - 39px)'
      }
    }
  },
  complainOptionsIcon: {
    fontSize: 13
  },
  label: {
    marginBottom: 16,
    marginRight: 0,
    alignItems: 'flex-start',
    '& > span:first-of-type': {
      paddingTop: 0
    },
    '&:last-child': {
      marginBottom: 0
    }
  }
}))

const TriggerEl = (props: { onClick: () => void; hint: string }) => {
  const {
    classes: { complainOptionsIcon }
  } = useStyles()
  return (
    <IconButton
      disablePY
      disablePR
      onClick={props.onClick}
      hint={props.hint}
      icon={['far', 'times']}
      classes={{ root: complainOptionsIcon }}
      size="small"
    />
  )
}

type InformAboutIdentityDialogProps = {
  identity: string
  auditLink: string
  opened: boolean
  close: () => void
  submit: (step: number) => Promise<unknown>
  junk: string
  shared: string
  notBelongsTo: JSX.Element | string
  successMode: boolean
  openSuccess: () => void
}

type NameReason = 'CommonName' | 'JunkName'
type EmailReason = 'SharedEmail' | 'AutomatedSender'

export const nameReason: { [key: number]: NameReason } = {
  1: 'CommonName',
  2: 'JunkName'
}

export const emailReason: { [key: number]: EmailReason } = {
  1: 'SharedEmail',
  2: 'AutomatedSender'
}

export const getReason = (key: AuditIdentifierType) => {
  switch (key) {
    case 'PersonCompleteName':
    case 'PersonTwoPartName':
    case 'PersonSourceKey':
      return { label: 'name', reason: nameReason, entity: 'people' }
    case 'CompanyName':
    case 'DistilledCoName':
    case 'CompanySourceKey':
      return { label: 'name', reason: nameReason, entity: 'companies' }
    case 'DomainUrl':
      return { label: 'domain url', reason: nameReason, entity: 'companies' }
    case 'EmailAddress':
      return { label: 'email address', reason: emailReason, entity: 'people' }
    default:
      return {}
  }
}

export const getReasonLabel = (identityType: AuditIdentifierType) => {
  const { label, entity } = getReason(identityType)
  return {
    notBelongsTo: (name: string, identity: string) =>
      name?.trim() === identity?.trim() ? (
        'This is not a valid name'
      ) : (
        <>
          This {label} does not belong to <b>{name}</b>
        </>
      ),
    junk: `This ${label} is junk, automated or otherwise invalid and should not be associated with any ${
      entity === 'people' ? 'person' : 'company'
    }.`,
    shared: `This is a shared ${label} and should not be associated with any ${entity === 'people' ? 'person' : 'company'}.`
  }
}

const InformAboutIdentityDialog = ({
  identity,
  auditLink,
  opened,
  close,
  submit,
  junk,
  shared,
  notBelongsTo,
  successMode,
  openSuccess
}: InformAboutIdentityDialogProps) => {
  const {
    classes: { label }
  } = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const { register, watch } = useForm<{ reasonStep: '0' | '1' | '2'; unsub: boolean }>({ mode: 'onChange', shouldUnregister: true })
  const [loading, setLoading] = useState<boolean>(false)
  const [unsub, setUnsub] = useLocalStorage('hide_audit_panel', false)

  useEffect(() => {
    if (opened) {
      setActiveStep(0)
      setLoading(false)
    }
  }, [opened])

  const handleChoice = async () => {
    const step = parseInt(watch().reasonStep)
    if (step > 0) {
      setLoading(true)
      await submit(step)
      setLoading(false)
      openSuccess()
    } else {
      setActiveStep(step + 1)
    }
  }

  const handleAuditClick = () => {
    setUnsub(watch().unsub)
    close()
  }

  return (
    <Dialog
      title={<DialogTitle noWrap title={activeStep === 0 ? identity : activeStep === 1 ? 'Help Us' : ''} />}
      open={opened}
      onClose={close}
      loading={loading}
      success={successMode}
    >
      <DialogContent successContent={<ManualEditSuccessMessage />}>
        {activeStep === 0 && (
          <RadioGroup aria-label="reason step" name="reasonStep">
            <Radio {...register('reasonStep')} value="0" label={notBelongsTo} />
            <Radio {...register('reasonStep')} value="1" label={shared} />
            <Radio {...register('reasonStep')} value="2" label={junk} />
          </RadioGroup>
        )}
        {activeStep === 1 && (
          <>
            <Typography variant="body1">
              DotAlign manages identities automatically but occasionally needs a little help. Click to audit identities and educate the system.
            </Typography>
            <Typography variant="body1" style={{ marginTop: 16 }}>
              <FontAwesomeIcon icon={['far', 'question-circle']} style={{ fontSize: 18, marginRight: 8 }} color="rgba(0,0,0,0.4)" />
              Click to learn about
              <Link
                to="https://help.dotalign.com/article/q4zoh501xc-auditing-identities"
                target="_blank"
                style={{ color: '#2E9CBD', textDecoration: 'underline', paddingLeft: '8px' }}
              >
                Audit
              </Link>
            </Typography>
            <Typography variant="body1" component="div" style={{ marginTop: 16 }}>
              <FormControlLabel {...register('unsub')} className={label} control={<Checkbox name="unsub" />} label="Don't show this message again" />
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions successActions={<DefaultSuccessAction close={close} />}>
        {activeStep === 0 && (
          <>
            <Button variant="text" color="secondary" onClick={close}>
              Cancel
            </Button>
            {unsub && watch().reasonStep === '0' ? (
              <SidepanelLink linkProps={{ to: auditLink }} sidepanel={true}>
                <Button variant="text" onClick={close} disabled={!watch().reasonStep} disablePR>
                  Next
                </Button>
              </SidepanelLink>
            ) : (
              <Button variant="text" onClick={handleChoice} disabled={!watch().reasonStep} disablePR>
                Next
              </Button>
            )}
          </>
        )}
        {activeStep === 1 && (
          <SidepanelLink linkProps={{ to: auditLink }} sidepanel={true}>
            <Button variant="text" onClick={handleAuditClick} disablePR>
              Audit
            </Button>
          </SidepanelLink>
        )}
      </DialogActions>
    </Dialog>
  )
}

InformAboutIdentityDialog.TriggerEl = TriggerEl
export default InformAboutIdentityDialog
