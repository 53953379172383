import React, { useState } from 'react'

import { Link } from 'react-router-dom'

import { Button } from '_shared/buttons'
import Dialog, { DialogActions, DialogContent, DialogTitle } from '_shared/Dialog'
import Typography from '_shared/Typography'

import { defineStatus } from '_core/components/introductions/StatusChip'

import { put } from 'utils/httpUtils'
import { getLocal } from 'utils/Utils'

import Paths from 'Paths'

type RequestType = 'closed' | 'active'

const requestTypeMap: { [key in RequestType]: { title: string; label: string; content: string; successContent: any } } = {
  active: {
    title: 'Make request active',
    label: 'Make active',
    content: 'Are you sure you want to make the request active?',
    successContent: 'Do you want to specify the request outcome?'
  },
  closed: {
    title: 'Close request',
    label: 'Close',
    content: 'Are you sure you want to close the request?',
    successContent: 'Do you want to adjust the request outcome?'
  }
}

const UpdateRequestTypeDialog = ({
  outputType,
  isOpened,
  closeDialog,
  planUid,
  openSuccess,
  successMode,
  setUpdatedValue,
  updateParent
}: {
  outputType: RequestType
  isOpened: boolean
  closeDialog: () => void
  planUid: string
  successMode: boolean
  openSuccess: () => void
  updateParent: (val: any) => void
  setUpdatedValue: (value?: string) => void
}) => {
  const [fetching, setFetching] = useState(false)

  const closeRequest = async () => {
    setFetching(true)
    const resp = await put<IntroductionPlanResp>('/prospecting/editplan', {
      planUid,
      ...(outputType === 'closed' ? { closedOut: getLocal() } : { setClosedOutToNull: true })
    })
    if (resp) {
      setUpdatedValue(resp.closedOut)
      updateParent({
        action: 'UPDATE_INTRODUCTION_DETAILS',
        value: {
          planUid,
          fieldName: 'status',
          value: defineStatus(resp.planUid, !!resp.closedOut)
        }
      })
      openSuccess()
      setFetching(false)
    }
  }

  const SuccessActions = (
    <>
      <Button variant="text" onClick={closeDialog} color="secondary">
        Close
      </Button>
      <Link to={`${Paths._introductions}/${planUid}/outcome`}>
        <Button variant="text" disablePR>
          Specify outcome
        </Button>
      </Link>
    </>
  )

  return (
    <Dialog
      open={isOpened}
      success={successMode}
      onClose={closeDialog}
      loading={fetching}
      title={<DialogTitle title={requestTypeMap[outputType].title} link="https://help.dotalign.com" linkTooltip="Learn more about requests" />}
    >
      <DialogContent successContent={requestTypeMap[outputType].successContent}>
        <Typography>{requestTypeMap[outputType].content}</Typography>
      </DialogContent>
      <DialogActions successActions={SuccessActions}>
        <Button variant="text" onClick={closeDialog} color="secondary">
          Cancel
        </Button>
        <Button variant="text" onClick={closeRequest} disablePR disabled={!planUid}>
          {requestTypeMap[outputType].label}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UpdateRequestTypeDialog
