import React, { useState } from 'react'

import { Button } from '_shared/buttons'
import Dialog, { DefaultSuccessAction, DialogActions, DialogContent, DialogTitle } from '_shared/Dialog'
import Typography from '_shared/Typography'

import GridHeaderButton from '_core/components/GridHeaderButton'
import { useWide } from '_core/components/layout'

import useSidepanelClose from '_core/hooks/useSidepanelClose'

import { del } from 'utils/httpUtils'

import Paths from 'Paths'

const TriggerEl = ({ open, disabled }: { open: () => void; disabled: boolean }) => (
  <GridHeaderButton hint="Delete requests" disabled={disabled} onClick={open} icon={['far', 'trash']} />
)

const DeleteIntroductionRequestDialog = ({
  isOpened,
  closeDialog,
  reload,
  items,
  openSuccess,
  successMode
}: {
  isOpened: boolean
  closeDialog: () => void
  items: any[]
  successMode: boolean
  openSuccess: () => void
  reload?: () => void
}) => {
  const [fetching, setFetching] = useState(false)
  const handleClose = useSidepanelClose(Paths._introductions)
  const wide = useWide()

  const remove = async () => {
    setFetching(true)
    const res = await del('/prospecting/plan', items)
    if (res) {
      openSuccess()
      setFetching(false)
    }
  }

  const closeHandler = () => {
    if (reload) {
      reload()
    }
    closeDialog()
    if (!wide) {
      handleClose()
    }
  }

  return (
    <Dialog
      open={isOpened}
      success={successMode}
      onClose={successMode ? closeHandler : closeDialog}
      loading={fetching}
      title={
        <DialogTitle
          title={`Delete the selected ${items.length === 1 ? 'request' : 'requests'}?`}
          link="https://help.dotalign.com"
          linkTooltip="Learn more about request deleting"
        />
      }
    >
      <DialogContent successContent={`The ${items.length === 1 ? 'request is' : 'requests are'} successfully deleted`}>
        <Typography>Request is not visible on the main screen any more.</Typography>
      </DialogContent>
      <DialogActions successActions={<DefaultSuccessAction close={closeHandler} />}>
        <Button variant="text" onClick={closeDialog} color="secondary">
          Cancel
        </Button>
        <Button variant="text" onClick={remove} disablePR disabled={!items.length}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DeleteIntroductionRequestDialog.TriggerEl = TriggerEl

export default DeleteIntroductionRequestDialog
