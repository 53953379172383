import React, { useContext, useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import Page from '_shared/Page'

import Heading from '_core/components/Heading'
import { Column, Columns, Narrow, useWide, Wide } from '_core/components/layout'
import Topbar from '_core/components/Topbar'
import Widget from '_core/components/Widget'

import DynamicEntity from '_core/DynamicEntity'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import AliasName from './name'
import AliasUrl from './url'

const Aliases = (props: any) => {
  const { teamContextValue } = useContext(TeamContext)
  const { id } = useParams<any>()
  const { setSubHeader } = React.useContext(LayoutContext)
  const [totalAliases, setTotalAliases] = useState<number | null>(null)
  const [totalDomains, setTotalDomains] = useState<number | null>(null)

  useEffect(() => {
    setSubHeader('Aliases and domains')
    return () => setSubHeader('')
  }, [setSubHeader])

  const wide = useWide()

  return (
    <Page variant="core">
      <Wide>
        <Heading title="Aliases and domains" icon={['far', 'fingerprint']} variant="main" />
      </Wide>
      <Narrow>
        <Topbar nativeBack />
      </Narrow>
      <Columns spacing={wide ? 1 : 0}>
        <Column two>
          <Widget scope="stack">
            <Heading title="Aliases" icon={['far', 'building']} count={totalAliases || 0} />
            <DynamicEntity
              url={`/companies/${id}?teamNumber=${teamContextValue.teamNumber}&numAliases=10`}
              component={AliasName}
              addprops={{ companyName: props.companyName, setTotalAliases: setTotalAliases }}
              infinite={true}
              {...props}
              id="company_aliases_name"
            />
          </Widget>
        </Column>
        <Column two>
          <Widget scope="stack">
            <Heading title="Domains" icon={['far', 'globe']} count={totalDomains || 0} />
            <DynamicEntity
              url={`/companies/${id}?teamNumber=${teamContextValue.teamNumber}&numUrls=10`}
              component={AliasUrl}
              addprops={{ companyName: props.companyName, setTotalDomains: setTotalDomains }}
              infinite={true}
              {...props}
              id="company_aliases_url"
            />
          </Widget>
        </Column>
      </Columns>
    </Page>
  )
}

export default Aliases
