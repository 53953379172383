import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, List, ListItem } from '@mui/material'
import { Moment as MomentType } from 'moment'
import { makeStyles } from 'tss-react/mui'

import { TeamContext } from '_core/context/TeamContext'

import { Button, IconButton } from '_shared/buttons'
import DatePicker from '_shared/DatePicker'
import Dialog, { DialogTitle, DialogActions, DialogContent, DefaultSuccessAction } from '_shared/Dialog'
import Combobox from '_shared/forms/Combobox'
import TextField from '_shared/forms/TextField'
import Typography from '_shared/Typography'

import { PersonOption, renderStatusOption, renderPersonOption, StatusOption } from '_core/components/introductions/options'
import ProfileItem from '_core/components/ProfileItem'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import useAsyncCombobox from '_core/hooks/useAsyncCombobox'
import { useLookUpActiveDirectoryMembers } from '_core/hooks/useLookup'

import { getLocal } from 'utils/Utils'

const useStyles = makeStyles()((theme) => ({
  list: {
    marginLeft: `-${theme.spacing(2)}`,
    marginRight: `-${theme.spacing(2)}`
  },
  listItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.05)'
    }
  },
  group: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'inline-block',
    fontSize: 14,
    marginTop: 4,
    lineHeight: 1.4,
    color: 'rgba(0,0,0,0.6)'
  },
  heading: {
    fontSize: 12,
    textTransform: 'uppercase',
    marginTop: theme.spacing(2)
  },
  suggestion: {
    minHeight: 'auto',
    height: 'auto'
  },
  profileItem: {
    padding: 0
  }
}))

const TriggerEl = (props: { open: () => void; mode?: 'edit' }) => {
  const { open, mode } = props

  return mode === 'edit' ? (
    <Button onClick={open} disabled={!open} startIcon={<FontAwesomeIcon icon={['far', 'edit']} style={{ fontSize: 14 }} />} variant="link" disablePB>
      Change introducer
    </Button>
  ) : (
    <Button onClick={open} disabled={!open} color="primary" variant="text">
      Pick
    </Button>
  )
}

const initIntroducer = {
  name: '',
  email: ''
}

type ConditionalProps =
  | {
      // reported request
      statusOptions: StatusOption[]
      status: StatusOption | undefined
      memo: string
    }
  | {
      // regular request
      statusOptions?: never
      status?: never
      memo?: never
    }

const today = getLocal()

const IntroducerPickerDialog = ({
  planUid,
  contactEmail,
  close,
  isOpened,
  successMode,
  requester,
  ...props
}: {
  planUid: string
  contactEmail: string
  close: () => void
  isOpened: boolean
  successMode: boolean
  introducerEmail: string
  requester: string
  handleSave: (introducer: { name: string; email: string }, memo: string, date: MomentType | null, status?: string) => void
  loading?: boolean
} & ConditionalProps) => {
  const { teamContextValue } = useContext(TeamContext)
  const [loading, setLoading] = useState(false)
  const [memo, setMemo] = useState(props.memo || '')
  const [date, setDate] = useState<MomentType | null>(null)
  const [status, setStatus] = useState<StatusOption | null>(null)
  const { classes } = useStyles()

  const { lookUpActiveDirectoryMembers, forceAbort } = useLookUpActiveDirectoryMembers()

  const {
    inputValue,
    value: introducer,
    open,
    options,
    optionsLoading,
    handleClose,
    handleOpen,
    handleFocus,
    handleInputChange,
    handleValueChange,
    updateValue
  } = useAsyncCombobox<PersonOption>({
    initialValue: initIntroducer,
    loadOptions: useCallback(
      async (searchTerm) => {
        const result = await lookUpActiveDirectoryMembers(searchTerm)
        if (result) {
          return result.users.map((user) => ({ email: user.emailAddress, name: user.displayName }))
        }
      },
      [lookUpActiveDirectoryMembers]
    ),
    forceAbort
  })

  useEffect(() => {
    if (isOpened) {
      return () => {
        updateValue(initIntroducer)
        setStatus(null)
        setDate(null)
        setMemo('')
      }
    }
  }, [isOpened])

  useEffect(() => {
    if (props.status) {
      setStatus(props.status)
    }
  }, [props.status])

  const onMemoChange = (e: ChangeEvent<HTMLInputElement>) => setMemo(e.target.value)

  const removeIntroducer = () => {
    updateValue(initIntroducer)
  }

  const updateStatus = (e: ChangeEvent<{}>, newValue: typeof status) => {
    if (newValue) setStatus(newValue)
  }

  const handleSave = () => {
    if (introducer) {
      return props.handleSave(introducer, memo, date, status?.value)
    }
  }

  const onDateChange = (date: MomentType | null) => setDate(date)

  const Picker = (
    <>
      {!introducer?.email && (
        <>
          <Combobox
            autoFocus
            value={introducer}
            loading={optionsLoading}
            open={open}
            inputValue={inputValue}
            options={options}
            placeholder="Search by name or email"
            onChange={handleValueChange}
            onInputChange={handleInputChange}
            renderOption={renderPersonOption}
            getOptionLabel={(option: PersonOption) => option.name}
            onOpen={handleOpen}
            onClose={handleClose}
            onFocus={handleFocus}
          />

          {requester && isOpened && teamContextValue.teamNumber && (
            <Suggestions
              introducerEmail={props.introducerEmail}
              requesterEmail={requester}
              setLoading={setLoading}
              teamNumber={teamContextValue.teamNumber}
              handleIntroducerSelect={updateValue}
            />
          )}
        </>
      )}
      {introducer?.email && (
        <>
          <Box my={-1.5}>
            <ProfileItem
              name={introducer?.name}
              userKey={introducer?.email}
              byline={introducer?.email}
              score=""
              className={classes.profileItem}
              icons={<IconButton hint="Change introducer" icon={['far', 'times']} onClick={removeIntroducer} size="small" disablePR />}
            />
          </Box>
          {props.statusOptions && (
            <Box my={1.5}>
              <Combobox<typeof status, false, boolean>
                onChange={updateStatus}
                value={status}
                fullWidth
                options={props.statusOptions}
                renderOption={renderStatusOption}
                disableClearable={!!status}
                filterOptions={(options) => options.filter((option) => status && option && status.value !== option.value)}
              />
            </Box>
          )}
          <Box mb={2}>
            <DatePicker label="Date" value={date || today} onChange={onDateChange} ActionBar={null} size="medium" fullWidth disableFuture />
          </Box>
          <TextField label="Memo (optional)" disabled={loading} value={memo} onChange={onMemoChange} multiline rows={3} fullWidth />
        </>
      )}
    </>
  )

  return (
    <Dialog
      open={isOpened}
      onClose={close}
      loading={props.loading || loading}
      title={<DialogTitle title={props.introducerEmail ? 'Edit introducer' : 'Select introducer'} />}
      success={successMode}
    >
      <DialogContent successContent={<Typography>{introducer?.name} has been selected as introducer</Typography>}>{Picker}</DialogContent>
      <DialogActions successActions={<DefaultSuccessAction close={close} />}>
        <Button variant="text" color="secondary" onClick={close} disablePL>
          Cancel
        </Button>
        <Button variant="text" onClick={handleSave} disabled={!introducer || props.introducerEmail === introducer.email} disablePR>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const Suggestions = ({
  teamNumber,
  introducerEmail,
  requesterEmail,
  setLoading,
  handleIntroducerSelect
}: {
  teamNumber: number
  introducerEmail: string
  requesterEmail: string
  setLoading: (val: boolean) => void
  handleIntroducerSelect: (introducer: PersonOption) => void
}) => {
  const { fetchWithAbort, result, loading } = useAbortableFetch<{ data: PersonType[] }>()
  const { classes } = useStyles()

  const filterSuggestions = (introducer: PersonOption) => introducer.email !== introducerEmail

  const suggestions = result?.data[0].Introducers?.data
    .map((entry) => ({ name: entry.IntroducerName, email: entry.IntroducerBestEmailAddressText }))
    .filter(filterSuggestions)
    .slice(0, 3)

  useEffect(() => {
    setLoading(loading)
  }, [loading, setLoading])

  useEffect(() => {
    if (teamNumber) {
      fetchWithAbort({ url: `/people/${requesterEmail}?teamNumber=${teamNumber}&numIntroducers=4` })
    }
  }, [fetchWithAbort, requesterEmail, setLoading, teamNumber])

  return (
    <>
      <Typography variant="h4" className={classes.heading}>
        Suggestions
      </Typography>
      <List className={classes.list}>
        {suggestions?.map((person) => (
          <Box key={person.email}>
            <ListItem disableGutters component="li" className={classes.listItem} onClick={() => handleIntroducerSelect(person)}>
              <ProfileItem name={person.name} byline={person.email} userKey={person.email} className={classes.suggestion} />
            </ListItem>
          </Box>
        ))}
      </List>
    </>
  )
}

IntroducerPickerDialog.TriggerEl = TriggerEl

export default IntroducerPickerDialog
