/* eslint-disable react/jsx-key */
import React, { ComponentProps, Dispatch, Fragment, SetStateAction, useEffect, useMemo, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, SelectChangeEvent, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Moment as MomentType } from 'moment'
import { useLocation, useParams } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { AlertWidget } from '_pages/companies/[id]/touchpoints'

import Avatar from '_shared/Avatar'
import { Button } from '_shared/buttons'
import Select from '_shared/forms/Select'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import ActivitiesStatsCard from '_core/components/ActivitiesStatsCard'
import { nameReason } from '_core/components/dialogs/InformAboutIdentity'
import Empty from '_core/components/Empty'
import ExternalTag, { ExtraTagsPopover } from '_core/components/ExternalTag'
import Heading from '_core/components/Heading'
import HelpLinkButton from '_core/components/HelpLinkButton'
import IdentifierLabel from '_core/components/IdentifierLabel'
import { Interaction, getTime } from '_core/components/Interactions'
import InteractionsPrivacyMsg from '_core/components/InteractionsPrivacy'
import { Column, Columns, Narrow, useWide, Wide } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import Timeline, { TimelineList } from '_core/components/lists/Timeline'
import NameLink from '_core/components/NameLink'
import Relationship from '_core/components/Relationship'
import SidepanelLink from '_core/components/SidepanelLink'
import TagsGroup from '_core/components/TagsGroup'
import TitleDescription from '_core/components/TitleDescription'
import TouchpointRelationSummary from '_core/components/TouchpointRelationSummary'
import Widget from '_core/components/Widget'

import useAbortableFetch from '_core/hooks/useAbortableFetch'
import useActivitiesStats from '_core/hooks/useActivitiesStats'
import useActivitiesStatsUserSettings, { withinOptions } from '_core/hooks/useActivitiesStatsUserSettings'
import useCompanyActivitiesPayloads from '_core/hooks/useCompanyActivitiesPayloads'
import { CSuiteType } from '_core/hooks/useCSuite'
import { CompaniesType as SimilarCompaniesType } from '_core/hooks/useSimilarCompanies'

import { stringifyUrl } from '_core/helpers/browser'
import { sum } from '_core/helpers/numbers'
import UserSettings from '_core/UserSettings'

import { mergeUrlWithParams, post } from 'utils/httpUtils'
import { ellipsisText, getLocal, dateFormatURLQuery } from 'utils/Utils'

import { widgetSubTitle } from 'AppTheme'

import Paths from 'Paths'

type ProfileWidgetsType = {
  loading: boolean
  teamNumber: number
  interactions: { [p: string]: any } | null | undefined
  marketData: { [p: string]: any } | null
  similar: SimilarCompaniesType | null
  setSimilar: Dispatch<SetStateAction<any>>
  cSuite: CSuiteType | null
  setCSuite: Dispatch<SetStateAction<any>>
  showInteractionsInProfile: boolean
  showActivityStats: boolean
  isDetailedActivityFromStatsWidgetVisible: boolean
  marketDataIntegration: MarketDataIntegration | undefined
}

const useStyles = makeStyles()((theme) => ({
  heading: widgetSubTitle(theme),
  timeline: {
    marginTop: theme.spacing(1),
    '& .MuiTimelineSeparator-root': {
      marginTop: theme.spacing(2),
      marginBottom: `-${theme.spacing(2)}`
    },
    '& .MuiTimelineDot-root': {
      background: 'none',
      border: 'none',
      boxShadow: 'none',
      padding: 0,
      color: theme.palette.primary.main
    }
  },
  empty: {
    '& > div:last-child': {
      '& .MuiTypography-root': {
        fontSize: '14px !important'
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(5)
      }
    }
  },
  label: {
    fontSize: 14
  },
  tagsWrapper: {
    overflow: 'hidden',
    marginBottom: `-${theme.spacing(0.5)}`,
    flexWrap: 'wrap',
    rowGap: theme.spacing(0.5)
  }
}))

const InteractionsWidget = ({ interactions, ...props }: Pick<ProfileWidgetsType, 'interactions' | 'loading'> & CompanyType) => {
  const { classes } = useStyles()
  const { pathname } = useLocation()

  const { data: items, errorMessage } = interactions || {}
  const loading = props.loading || !interactions

  return (
    <Widget>
      <Heading
        underlined
        title="Interactions"
        icon={['far', 'envelope']}
        count={0}
        action={
          <Box flex={1}>
            <InteractionsPrivacyMsg />
          </Box>
        }
        link={!loading && items?.length ? `${Paths._companies}/${props.CompanyMd5}/interactions` : undefined}
      />
      {errorMessage && (
        <Empty
          className={classes.empty}
          icon={<FontAwesomeIcon size="4x" style={{ color: '#A7A7A7' }} icon={['fat', 'envelope-open-text']} />}
          subTitle={errorMessage}
        />
      )}
      {!errorMessage && (
        <>
          {(!!items?.length || loading) && (
            <Timeline scope="widget" className={classes.timeline} loading={loading}>
              <TimelineList
                items={items
                  ?.map((item: { [key: string]: any }) => ({
                    time: getTime(item.receivedDateTime),
                    title: item.rawSubject || '(No subject)',
                    byline: item.body,
                    byline2: (
                      <NameLink
                        variant="light"
                        url={stringifyUrl(`${Paths._people}/${item.senderEmail}`, { name: item.senderName, email: item.senderEmail })}
                        name={item.senderName}
                      />
                    ),
                    dotIcon: <FontAwesomeIcon icon={['fas', 'envelope']} />,
                    sidepanel: true,
                    link: stringifyUrl(`${Paths._messages}/${item.sourceKey}`, { deleteBackLink: pathname }),
                    component: 'div'
                  }))
                  .slice(0, 5)}
                skeleton={{ size: 5, loading }}
                component={Interaction}
              />
            </Timeline>
          )}
          {!items?.length && !loading && (
            <Empty
              className={classes.empty}
              subTitle={`You have not exchanged any email messages with ${props.CompanyNameText}`}
              icon={<FontAwesomeIcon size="3x" icon={['fat', 'envelope-open-text']} style={{ color: '#A7A7A7' }} />}
            />
          )}
        </>
      )}
    </Widget>
  )
}

const CSuiteWidget = ({
  cSuite,
  setCSuite,
  teamNumber,
  ...props
}: Pick<ProfileWidgetsType, 'cSuite' | 'setCSuite' | 'teamNumber' | 'loading' | 'marketData'> & CompanyType) => {
  const [error, setError] = useState('')
  const { id } = useParams<{ id: string }>()
  const { fetchWithAbort, loading } = useAbortableFetch()
  const { classes } = useStyles()

  useEffect(() => {
    if (props.BestUrlText && !cSuite && teamNumber) {
      ;(async () => {
        try {
          const res = await fetchWithAbort({ url: `/companies/${props.BestUrlText}/cSuite?titlecase=true&teamNumber=${teamNumber}` })
          if (res) setCSuite(res)
        } catch (e: any) {
          setCSuite({})
          setError(e?.status === 500 ? 'No records were found' : e?.error)
          console.log('error during fetching cSuite', e)
        }
      })()
    }
  }, [props.BestUrlText, cSuite, teamNumber])

  const items = cSuite?.data?.length
    ? cSuite.data.slice(0, 5).map((person) => {
        return {
          name: person.full_name,
          score: person.personMd5 ? person.score : undefined,
          sidepanel: true,
          link: person.personMd5
            ? `${Paths._people}/${person.personMd5}`
            : mergeUrlWithParams(`${Paths._companies}/${id}/c-suite/${person.full_name}`, {
                email: person.work_email,
                company: person.job_company_name
              })
        }
      })
    : []

  const load = loading || props.loading
  const showEmptyWidget = error || (!load && !cSuite?.data?.length)

  return (
    <Widget>
      <Heading
        underlined
        title="C Suite"
        icon={['fas', 'user-tie']}
        count={cSuite?.total}
        link={cSuite?.total && !load ? `${Paths._companies}/${id}/c-suite?name=${props.CompanyNameText}` : undefined}
        sidepanel
      />
      {!showEmptyWidget && <Repeater direction="horizontal" component={Avatar} skeleton={{ size: 5, loading: load }} items={items} />}
      {showEmptyWidget && (
        <Empty
          className={classes.empty}
          subTitle={error || 'No records were found'}
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'user-group']} style={{ color: '#A7A7A7' }} />}
        />
      )}
    </Widget>
  )
}

const SimilarCompaniesWidget = ({
  similar,
  setSimilar,
  teamNumber,
  ...props
}: Pick<ProfileWidgetsType, 'similar' | 'setSimilar' | 'teamNumber' | 'loading' | 'marketData'> & CompanyType) => {
  const [error, setError] = useState('')
  const { id } = useParams<{ id: string }>()
  const { fetchWithAbort, loading } = useAbortableFetch<{ [key: string]: any }>()
  const { classes } = useStyles()

  const load = loading || props.loading
  const { employee_count, industry, location, type, website } = props.marketData || {}
  const enoughFilters = props.marketData && (website || props.BestUrlText)
  const showEmptyWidget = error || (enoughFilters && !loading && !similar?.data?.length) || (props.marketData && !enoughFilters)

  const initParams = useMemo(
    () => ({
      website: website || props.BestUrlText,
      minimumEmployeeCount: employee_count ? Math.round(Number(employee_count) / 2).toString() : '',
      maximumEmployeeCount: employee_count ? Math.round(Number(employee_count) * 1.5).toString() : '',
      industries: industry ? [industry] : [],
      countries: [location?.country || 'United States'],
      companyType: type || 'Private',
      teamNumber: teamNumber.toString(),
      titlecase: 'true'
    }),
    [props.BestUrlText, website, employee_count, industry, location?.country, type, teamNumber]
  )

  useEffect(() => {
    if (enoughFilters && !similar && teamNumber) {
      ;(async () => {
        try {
          const res = await fetchWithAbort({ url: mergeUrlWithParams('/companies/similarCompanies', initParams) })
          if (res) setSimilar(res)
        } catch (e: any) {
          setSimilar({})
          setError(e?.status === 500 ? 'No records were found' : e?.error)
          console.log('error during fetching similar companies', e)
        }
      })()
    }
  }, [enoughFilters, similar, teamNumber])

  const items = similar?.data
    ? similar.data.map((company) => {
        return {
          name: company.name,
          score: company.companyMd5 ? company.score : undefined,
          sidepanel: true,
          logoUrl: company.website,
          link: company.companyMd5
            ? `${Paths._companies}/${company.companyMd5}`
            : mergeUrlWithParams(`${Paths._companies}/${id}/similar/${company.name}`, {
                website: company.website
              }),
          byline: company.website,
          byline2: company.industry
        }
      })
    : []

  return (
    <Widget>
      <Heading
        underlined
        title="Similar companies"
        icon={['fas', 'diagram-venn']}
        count={showEmptyWidget ? 0 : similar?.total}
        link={
          similar?.total && id && !showEmptyWidget ? `${Paths._companies}/${id}/similar?clearSearch=true&name=${props.CompanyNameText}` : undefined
        }
        sidepanel
      />
      {!showEmptyWidget && (
        <Repeater direction="horizontal" component={Avatar} skeleton={{ size: 5, loading: !props.marketData || load }} items={items} />
      )}
      {showEmptyWidget && (
        <Empty
          className={classes.empty}
          subTitle={
            error ||
            (enoughFilters ? 'No records were found matching your search' : 'Not enough market data is available for an effective search filter.')
          }
          action={
            <Box display="flex" justifyContent="center">
              <SidepanelLink
                linkProps={{ to: `${Paths._companies}/${id}/similar?openedFilters=true&clearSearch=true&name=${props.CompanyNameText}` }}
                sidepanel={true}
              >
                <Button variant="link" bold={false}>
                  {enoughFilters ? 'Change' : 'Add'} filters
                </Button>
              </SidepanelLink>
            </Box>
          }
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'user-group']} style={{ color: '#A7A7A7' }} />}
        />
      )}
    </Widget>
  )
}

const IntroducersWidget = (props: Pick<ProfileWidgetsType, 'loading'> & CompanyType) => {
  const { classes } = useStyles()
  const wide = useWide()

  const { data: items, total_item_count } = props.Introducers || {}

  return (
    <Widget>
      <Heading
        underlined
        title="Introducers"
        count={total_item_count}
        icon={['far', 'address-book']}
        link={total_item_count ? `${Paths._companies}/${props.CompanyMd5}/introducers` : ''}
      />
      {(!!total_item_count || props.loading) && (
        <Repeater
          direction="horizontal"
          component={Avatar}
          skeleton={{ size: wide ? 5 : 4, loading: props.loading }}
          items={items?.slice(0, wide ? 5 : 4).map((intro) => ({
            name: intro.IntroducerName,
            userKey: intro.IntroducerBestEmailAddressText,
            score: intro.IntroducerScore,
            link: `${Paths._relationships}/${intro.UserKeyMd5 || intro.IntroducerPersonKeyMd5}/companies/${props.CompanyMd5}`,
            sidepanel: true
          }))}
        />
      )}
      {!total_item_count && !props.loading && (
        <Empty
          className={classes.empty}
          subTitle={`${props.CompanyNameText} has no introducers`}
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'address-book']} style={{ color: '#A7A7A7' }} />}
        />
      )}
    </Widget>
  )
}

const RelationshipsWidget = (props: Pick<ProfileWidgetsType, 'loading'> & CompanyType) => {
  const { classes } = useStyles()

  const items = props.Employees?.data.filter((item) => item.KnowerNameText) || []

  return (
    <Widget>
      <Heading
        underlined
        title="Key relationships"
        icon={['far', 'star']}
        count={props.loading ? undefined : 0}
        link={items.length ? `${Paths._companies}/${props.CompanyMd5}/relationships` : ''}
      />
      {(!!items.length || props.loading) && (
        <Repeater
          direction="vertical"
          component={Relationship}
          skeleton={{ size: 3, loading: props.loading }}
          items={items.slice(0, 3).map((rel) => ({
            name1: rel.PersonNameText,
            name2: rel.KnowerNameText,
            score: rel.UserKnowsPersonScore,
            userKey1: rel.ContactBestEmail
          }))}
        />
      )}
      {!items.length && !props.loading && (
        <Empty
          className={classes.empty}
          subTitle={`You have no key relationships with ${props.CompanyNameText}`}
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'star']} style={{ color: '#A7A7A7' }} />}
        />
      )}
    </Widget>
  )
}

const ActivitiesStatsWidget = ({
  loading,
  name = '',
  md5 = '',
  isDetailedActivityFromStatsWidgetVisible
}: {
  loading: boolean
  name?: string
  md5?: string
  isDetailedActivityFromStatsWidgetVisible: boolean
}) => {
  const { period: withinValue, setInitial, handleChange } = useActivitiesStatsUserSettings()

  const rangeMap: { [key in (typeof withinOptions)[number]['value']]: MomentType } = {
    this_month: getLocal().startOf('month'),
    this_quarter: getLocal().startOf('quarter'),
    this_year: getLocal().startOf('year')
  }

  const statsPayloads = useCompanyActivitiesPayloads(md5)

  const from = withinValue ? rangeMap[withinValue].format(dateFormatURLQuery) : withinValue
  const to = getLocal().format(dateFormatURLQuery)

  const { stats, clearStats } = useActivitiesStats(
    md5 ? statsPayloads : null,
    useMemo(() => [md5], [md5]),
    from,
    to
  )

  useEffect(() => {
    if (!md5) {
      clearStats()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [md5])

  const { meetings, inbound, outbound } = stats?.[0] || {}
  const count = (meetings?.count || 0) + (inbound?.count || 0) + (outbound?.count || 0)

  const handleRangeChange = (e: SelectChangeEvent<unknown>) => {
    const { value } = e.target as { value: Exclude<typeof withinValue, undefined> }
    handleChange(value)
  }

  return (
    <UserSettings endpoint={!loading ? '/usersettings/activitiesstatsperiod' : ''} setInitial={setInitial}>
      <Widget>
        <Heading
          underlined
          title="Activity stats"
          icon={['far', 'wave-pulse']}
          sidepanel
          count={count}
          link={
            isDetailedActivityFromStatsWidgetVisible && count
              ? stringifyUrl(`${Paths._companies}/${md5}/activities`, {
                  from,
                  to,
                  name
                })
              : ``
          }
        />
        <Box mb={2} display="flex" justifyContent="flex-end">
          <Select
            size="small"
            variant="outlined"
            value={withinValue}
            options={[...withinOptions]}
            onChange={handleRangeChange}
            disabled={loading || !stats}
          />
        </Box>
        <Columns spacing={1}>
          {[
            { header: 'Inbound', amount: inbound?.count || 0 },
            { header: 'Outbound', amount: outbound?.count || 0 },
            { header: 'Meetings', amount: meetings?.count || 0 }
          ].map(({ header, amount }) => (
            <Column key={header} xs={4}>
              <ActivitiesStatsCard header={header} amount={amount} loading={loading || !stats} />
            </Column>
          ))}
        </Columns>
      </Widget>
    </UserSettings>
  )
}

const TouchpointsWidget = (props: Pick<ProfileWidgetsType, 'loading'> & CompanyType) => {
  const { classes } = useStyles()

  const stats =
    !props.loading && props.Stats
      ? Object.getOwnPropertyNames(props.Stats).filter(
          (k) =>
            ['FirstInboundMsg', 'FirstMeeting', 'FirstOutboundMsg', 'LastInboundMsg', 'LastMeeting', 'LastOutboundMsg', 'NextFutureMeeting'].indexOf(
              k
            ) > -1
        )
      : []

  return (
    <Widget>
      <Heading
        underlined
        title="Key touch points"
        icon={['far', 'handshake']}
        count={props.loading ? -1 : 0}
        link={!props.loading && stats.length ? `/companies/${props.CompanyMd5}/touchpoints` : ''}
      />
      {(!!stats.length || props.loading) && (
        <Repeater component={TitleDescription} skeleton={{ size: 3, loading: props.loading }} items={TouchpointRelationSummary(props.Stats)} />
      )}
      {!stats.length && !props.loading && (
        <Empty
          className={classes.empty}
          subTitle={`You have no touchpoints with ${props.CompanyNameText}`}
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'handshake']} style={{ color: '#A7A7A7' }} />}
        />
      )}
    </Widget>
  )
}

const ContactsWidget = (props: Pick<ProfileWidgetsType, 'loading'> & CompanyType) => {
  const { classes } = useStyles()
  const { total_item_count, data: items } = props.Employees || {}
  const wide = useWide()

  return (
    <Widget>
      <Heading
        underlined
        title="Contacts"
        count={total_item_count}
        icon={['far', 'user']}
        link={total_item_count ? `${Paths._companies}/${props.CompanyMd5}/contacts` : ''}
      />
      {(!!total_item_count || props.loading) && (
        <Repeater
          direction="horizontal"
          component={Avatar}
          skeleton={{ size: wide ? 5 : 4, loading: props.loading }}
          items={items?.slice(0, wide ? 5 : 4).map((contact) => ({
            name: contact.PersonNameText,
            score: contact.WeKnowPersonScore,
            sidepanel: true,
            link: `${Paths._people}/${contact.PersonMd5}`,
            userKey: contact.ContactBestEmail
          }))}
        />
      )}
      {!total_item_count && !props.loading && (
        <Empty
          className={classes.empty}
          subTitle={`${props.CompanyNameText} has no contacts`}
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'user-group']} style={{ color: '#A7A7A7' }} />}
        />
      )}
    </Widget>
  )
}

const AliasesDomainsWidget = (props: Pick<ProfileWidgetsType, 'loading' | 'teamNumber'> & CompanyType) => {
  const {
    classes: { heading }
  } = useStyles()
  const { id } = useParams<{ id: string }>()

  const submitInvalid = async (identity: string, value: number) => {
    return post(mergeUrlWithParams('/companies/disqualify', { teamNumber: `${props.teamNumber}` }), {
      identity,
      reason: nameReason[value]
    })
  }

  return (
    <Widget>
      <Heading
        underlined
        title="Aliases and domains"
        count={props.loading ? -1 : sum(props.Aliases?.total_item_count, props.Urls?.total_item_count)}
        icon={['far', 'fingerprint']}
        link={!props.loading ? `${Paths._companies}/${props.CompanyMd5}/aliases` : undefined}
      />
      <Box marginBottom="16px">
        <Typography
          variant="body1"
          className={heading}
          component="div"
          style={{ marginTop: 16, paddingTop: 8, marginBottom: props?.Aliases?.data?.length ? -8 : 0 }}
        >
          Aliases
        </Typography>

        <Repeater
          component={IdentifierLabel}
          variant="homepage"
          skeleton={{ size: 2, loading: props.loading }}
          items={props.Aliases?.data?.slice(0, 2).map((name) => ({
            name: props.CompanyNameText,
            identity: name.CoNameAlias,
            icon: ['far', 'building'],
            type: 'CompanyName',
            submit: submitInvalid,
            sourceLink: `${Paths._companies}/${id}/aliasSources/${name.CoNameAlias}`,
            auditLink: `${Paths._companies}/${id}/audit`
          }))}
          empty="No aliases were found"
        />

        <Typography variant="body1" className={heading} component="div" style={{ marginTop: 12, marginBottom: props?.Urls?.data?.length ? -8 : 0 }}>
          Domains
        </Typography>

        <Repeater
          component={IdentifierLabel}
          variant="homepage"
          skeleton={{ size: 2, loading: props.loading }}
          items={props.Urls?.data?.slice(0, 2).map((email) => ({
            name: props.CompanyNameText,
            identity: email.CoUrl,
            icon: ['far', 'globe'],
            type: 'DomainUrl',
            submit: submitInvalid,
            sourceLink: `${Paths._companies}/${id}/domainSources/${email.CoUrl}`,
            auditLink: `${Paths._companies}/${id}/audit`
          }))}
          empty="No domains were found"
        />
      </Box>
    </Widget>
  )
}

const MarketDataWidget = (props: Pick<ProfileWidgetsType, 'marketData'> & { name: string }) => {
  const { id: companyId } = useParams<{ id: string }>()
  const { classes } = useStyles()
  const theme = useTheme()
  const sidepanel = useMediaQuery(theme.breakpoints.up('sidepanel'))

  const { tags, summary, id, website, founded, location, employee_count, industry } = props.marketData || {}
  const mocked = new Array(3).fill('')
  const noData = props.marketData && !id
  const noWidgetData = id && !tags?.length && !summary
  const notEnoughData = noWidgetData && !website && !founded && !location && !employee_count && !industry

  return (
    <Widget>
      <Heading
        underlined
        title="Details"
        icon={['fas', 'chart-column']}
        count={0}
        action={
          <Box flex={1}>
            <HelpLinkButton
              hint="Learn more about market data"
              to={{ pathname: 'https://help.dotalign.com/article/x8f8c6vw1i-dot-align-market-data' }}
              size="small"
              disablePY
            />
          </Box>
        }
        link={!noData && !notEnoughData && props.name ? `${Paths._companies}/${companyId}/market-data?name=${props.name}` : ''}
        sidepanel
      />

      {(noData || notEnoughData || (noWidgetData && !notEnoughData)) && (
        <Empty
          className={classes.empty}
          icon={<FontAwesomeIcon size="3x" icon={['fat', 'chart-column']} style={{ color: '#A7A7A7' }} />}
          subTitle={
            <>
              {noData && 'No details are available in market data'}
              {notEnoughData && 'Not enough details are available in market data'}
              {noWidgetData && !notEnoughData && 'No tag or summary info is available, click "see all" for other market data'}
            </>
          }
        />
      )}

      <Box mb={{ md: 2 }}>
        {!props.marketData && (
          <Box>
            {mocked.map((el, i) => (
              <Skeleton condition={!props.marketData} height="23px" width="100%" key={i} />
            ))}
          </Box>
        )}

        {summary && <Typography style={{ paddingBottom: '8px' }}>{ellipsisText(summary, 150)}</Typography>}

        {(!props.marketData || !!tags?.length) && (
          <TagsGroup<ComponentProps<typeof ExternalTag>['tagData']>
            tagComponent={ExternalTag}
            className={classes.tagsWrapper}
            max={sidepanel ? 2 : 1}
            items={!props.marketData ? mocked : tags}
            title={<Typography bold>Tags</Typography>}
            {...(props.marketData
              ? {
                  renderShowAll: ({ extraTagsAmount }: { extraTagsAmount: number }) => (
                    <ExtraTagsPopover
                      tags={!props.marketData ? mocked : tags}
                      title="Tags"
                      name={props.name}
                      triggerElement={
                        <Box display="inline-flex" alignItems="center" height="100%">
                          <Typography noWrap>{extraTagsAmount} more</Typography>
                        </Box>
                      }
                    />
                  )
                }
              : {})}
          />
        )}
      </Box>
    </Widget>
  )
}

const Profile = ({
  showInteractionsInProfile,
  showActivityStats,
  marketDataIntegration,
  marketData,
  similar,
  setSimilar,
  cSuite,
  setCSuite,
  teamNumber,
  isDetailedActivityFromStatsWidgetVisible,
  interactions,
  ...props
}: ProfileWidgetsType & CompanyType) => {
  const wideStrict = useWide('lg')

  useEffect(() => {
    if (!props.loading && props.CompanyMd5) window.parent.postMessage('da_open', '*')
  }, [props.loading, props.CompanyMd5])

  const { enabled: enabledMarketData, showSimilarCompanies } = marketDataIntegration || {}

  const MarketDataWidgetEl = <MarketDataWidget marketData={marketData} name={props.CompanyNameText} />
  const ContactsWidgetEl = <ContactsWidget {...props} />
  const IntroducersWidgetEl = <IntroducersWidget {...props} />
  const CSuiteWidgetWidgetEl = <CSuiteWidget cSuite={cSuite} setCSuite={setCSuite} teamNumber={teamNumber} marketData={marketData} {...props} />
  const InteractionsWidgetEl = <InteractionsWidget interactions={interactions} {...props} />
  const SimilarCompaniesWidgetEl = (
    <SimilarCompaniesWidget marketData={marketData} similar={similar} setSimilar={setSimilar} teamNumber={teamNumber} {...props} />
  )
  const ActivitiesStatsWidgetEl = (
    <ActivitiesStatsWidget
      loading={props.loading}
      isDetailedActivityFromStatsWidgetVisible={isDetailedActivityFromStatsWidgetVisible}
      name={props.CompanyNameText}
      md5={props.CompanyMd5}
    />
  )
  const TouchpointsWidgetEl = <TouchpointsWidget {...props} />
  const RelationshipsWidgetEl = <RelationshipsWidget {...props} />
  const AliasesDomainsWidgetEl = <AliasesDomainsWidget teamNumber={teamNumber} {...props} />

  const columnsRows = [
    {
      condition: showInteractionsInProfile && showActivityStats && enabledMarketData && showSimilarCompanies,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl, ContactsWidgetEl], [IntroducersWidgetEl, CSuiteWidgetWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[SimilarCompaniesWidgetEl], [TouchpointsWidgetEl], [ActivitiesStatsWidgetEl]] },
            { columns: [[RelationshipsWidgetEl], [AliasesDomainsWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl, IntroducersWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[ContactsWidgetEl], [CSuiteWidgetWidgetEl]] },
            { columns: [[SimilarCompaniesWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[ActivitiesStatsWidgetEl], [RelationshipsWidgetEl]] },
            { columns: [[AliasesDomainsWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: showInteractionsInProfile && showActivityStats && enabledMarketData && !showSimilarCompanies,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl, ContactsWidgetEl], [IntroducersWidgetEl, RelationshipsWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [ActivitiesStatsWidgetEl], [AliasesDomainsWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl, IntroducersWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[ContactsWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[ActivitiesStatsWidgetEl], [RelationshipsWidgetEl]] },
            { columns: [[AliasesDomainsWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: showInteractionsInProfile && showActivityStats && !enabledMarketData,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[IntroducersWidgetEl, TouchpointsWidgetEl], [ContactsWidgetEl, ActivitiesStatsWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[RelationshipsWidgetEl], [AliasesDomainsWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[IntroducersWidgetEl, ContactsWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [ActivitiesStatsWidgetEl]] },
            { columns: [[RelationshipsWidgetEl], [AliasesDomainsWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !showInteractionsInProfile && showActivityStats && enabledMarketData && showSimilarCompanies,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl], [ContactsWidgetEl]] },
            { columns: [[SimilarCompaniesWidgetEl], [CSuiteWidgetWidgetEl], [RelationshipsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [ActivitiesStatsWidgetEl], [AliasesDomainsWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl]] },
            { columns: [[ContactsWidgetEl], [CSuiteWidgetWidgetEl]] },
            { columns: [[SimilarCompaniesWidgetEl], [RelationshipsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [ActivitiesStatsWidgetEl]] },
            { columns: [[AliasesDomainsWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !showInteractionsInProfile && showActivityStats && enabledMarketData && !showSimilarCompanies,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl], [ContactsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [ActivitiesStatsWidgetEl], [RelationshipsWidgetEl]] },
            { columns: [[AliasesDomainsWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl]] },
            { columns: [[ContactsWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[ActivitiesStatsWidgetEl], [RelationshipsWidgetEl]] },
            { columns: [[AliasesDomainsWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !showInteractionsInProfile && showActivityStats && !enabledMarketData,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[IntroducersWidgetEl], [ContactsWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[ActivitiesStatsWidgetEl], [RelationshipsWidgetEl], [AliasesDomainsWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[IntroducersWidgetEl], [ContactsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [ActivitiesStatsWidgetEl]] },
            { columns: [[RelationshipsWidgetEl], [AliasesDomainsWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: showInteractionsInProfile && !showActivityStats && enabledMarketData && showSimilarCompanies,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl, ContactsWidgetEl], [IntroducersWidgetEl, CSuiteWidgetWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[SimilarCompaniesWidgetEl], [TouchpointsWidgetEl], [RelationshipsWidgetEl]] },
            { columns: [[AliasesDomainsWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl, IntroducersWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[ContactsWidgetEl], [CSuiteWidgetWidgetEl]] },
            { columns: [[SimilarCompaniesWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[RelationshipsWidgetEl], [AliasesDomainsWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: showInteractionsInProfile && !showActivityStats && enabledMarketData && !showSimilarCompanies,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl, ContactsWidgetEl], [IntroducersWidgetEl, RelationshipsWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [AliasesDomainsWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl, IntroducersWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[ContactsWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[RelationshipsWidgetEl], [AliasesDomainsWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: showInteractionsInProfile && !showActivityStats && !enabledMarketData,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[IntroducersWidgetEl, TouchpointsWidgetEl], [ContactsWidgetEl, RelationshipsWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[AliasesDomainsWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[IntroducersWidgetEl, ContactsWidgetEl], [InteractionsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [RelationshipsWidgetEl]] },
            { columns: [[AliasesDomainsWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !showInteractionsInProfile && !showActivityStats && enabledMarketData && showSimilarCompanies,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl], [ContactsWidgetEl]] },
            { columns: [[SimilarCompaniesWidgetEl], [CSuiteWidgetWidgetEl], [RelationshipsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [AliasesDomainsWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl]] },
            { columns: [[ContactsWidgetEl], [CSuiteWidgetWidgetEl]] },
            { columns: [[SimilarCompaniesWidgetEl], [RelationshipsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [AliasesDomainsWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !showInteractionsInProfile && !showActivityStats && enabledMarketData && !showSimilarCompanies,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl], [ContactsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [RelationshipsWidgetEl], [AliasesDomainsWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[MarketDataWidgetEl], [IntroducersWidgetEl]] },
            { columns: [[ContactsWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[RelationshipsWidgetEl], [AliasesDomainsWidgetEl]] }
          ]
        }
      ]
    },
    {
      condition: !showInteractionsInProfile && !showActivityStats && !enabledMarketData,
      rows: [
        {
          condition: wideStrict,
          colRows: [
            { columns: [[IntroducersWidgetEl], [ContactsWidgetEl], [TouchpointsWidgetEl]] },
            { columns: [[RelationshipsWidgetEl], [AliasesDomainsWidgetEl]] }
          ]
        },
        {
          condition: !wideStrict,
          colRows: [
            { columns: [[IntroducersWidgetEl], [ContactsWidgetEl]] },
            { columns: [[TouchpointsWidgetEl], [RelationshipsWidgetEl]] },
            { columns: [[AliasesDomainsWidgetEl]] }
          ]
        }
      ]
    }
  ]

  const colRows = (columnsRows.find(({ condition }) => !!condition) || columnsRows[0]).rows.find(({ condition }) => condition)?.colRows

  return (
    <>
      <Wide>
        <Columns>
          <Column md={12}>
            <AlertWidget {...props} />
          </Column>
        </Columns>
        {colRows?.map(({ columns }, k) => (
          <Columns key={k}>
            {columns.map((column, i) => (
              <Column key={`${k}${i}`} two={!wideStrict}>
                {column.map((row, j) => (
                  <Fragment key={`${k}${i}${j}`}>{row}</Fragment>
                ))}
              </Column>
            ))}
          </Columns>
        ))}
      </Wide>
      <Narrow>
        <AlertWidget {...props} />
        {enabledMarketData && MarketDataWidgetEl}
        {IntroducersWidgetEl}
        {ContactsWidgetEl}
        {showSimilarCompanies && (
          <>
            {CSuiteWidgetWidgetEl}
            {SimilarCompaniesWidgetEl}
          </>
        )}
        {showInteractionsInProfile && InteractionsWidgetEl}
        {TouchpointsWidgetEl}
        {showActivityStats && ActivitiesStatsWidgetEl}
        {RelationshipsWidgetEl}
        {AliasesDomainsWidgetEl}
      </Narrow>
    </>
  )
}
export default Profile
