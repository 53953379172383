import React, { ReactElement, useRef } from 'react'

import { TooltipProps } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { TooltipStyled } from '_shared/Tooltip'

type StyleProps = { maxWidth?: number | string; maxHeight?: number | string }
export type PopoverProps = { triggerElement: ReactElement } & StyleProps & Pick<TooltipProps, 'children' | 'placement' | 'className'>

const useStyles = makeStyles<StyleProps>()((theme, { maxWidth, maxHeight }) => ({
  tooltip: {
    padding: theme.spacing(2),
    maxWidth: maxWidth || 'calc(100vw - 50px)',
    maxHeight: maxHeight || 'calc(100vh - 50px)',
    boxSizing: 'border-box',
    overflow: 'auto'
  }
}))

const Popover = ({ maxWidth, maxHeight, ...props }: PopoverProps) => {
  const triggerElRef = useRef<HTMLSpanElement>(null)

  const { classes, cx } = useStyles({ maxWidth, maxHeight })

  return (
    <TooltipStyled classes={{ tooltip: cx(classes.tooltip, props.className) }} placement={props.placement} title={props.children}>
      <span ref={triggerElRef}>{props.triggerElement}</span>
    </TooltipStyled>
  )
}

export default Popover
