import { useState, useContext, ReactElement } from 'react'

import { makeStyles } from 'tss-react/mui'

import { TeamContext } from '_core/context/TeamContext'

import AuditColumns from '_core/components/audit/AuditLayout'
import AuditSources, { SourceFacts } from '_core/components/audit/AuditSources'
import InnerDialog from '_core/components/InnerDialog'
import { TopbarHeader } from '_core/components/Topbar'

import useAuditSources from '_core/hooks/useAuditSources'
import useEntityEndpoint from '_core/hooks/useEntityEndpoint'

import { mergeUrlWithParams } from 'utils/httpUtils'

const useStyles = makeStyles()(() => ({
  wrapper: {
    overflow: 'auto'
  },
  hidden: {
    display: 'none'
  }
}))

const AuditIdentifierSources = (props: {
  identifierData: { text: AuditIdentifier['text']; type: AuditIdentifierSourceRequestType }
  entityType: 'Person' | 'Company'
  close: () => void
  searchInput: ReactElement
  enabledOpenFacts: boolean
}) => {
  const { classes, cx } = useStyles()
  const { teamContextValue } = useContext(TeamContext)
  const [factsOpened, setFactsOpened] = useState<{ type: Exclude<GraphSourceType, 'Manual'>; key: string } | null>(null)

  const { identifierData, entityType, close } = props

  const { text: identifier, type } = identifierData

  const url = mergeUrlWithParams('/audit/idcontributors', {
    teamNumber: `${teamContextValue.teamNumber}`,
    identifier,
    entityType
  })

  const { result, loading, more, reload } = useEntityEndpoint(url, null, 5, true, true)

  const dataLength = result ? (result.data || result.results)?.length : 0
  const contributors = result?.data?.map((item: any) => item.userKey)
  const sources = useAuditSources({ identifier, type }, contributors, loading)

  const openFacts = (data: { type: Exclude<GraphSourceType, 'Manual'>; key: string }) => {
    setFactsOpened(data)
  }

  const closeFacts = () => {
    setFactsOpened(null)
  }

  return (
    <InnerDialog close={factsOpened ? closeFacts : close}>
      <AuditColumns.Column heading={<TopbarHeader title={identifier} sub="sources" />}>
        <div className={cx(classes.wrapper, { [classes.hidden]: !!factsOpened })}>
          {props.searchInput}
          <AuditSources
            items={sources?.map((source) => ({ ...source, ...(props.enabledOpenFacts && { openFacts }) }))}
            loading={loading}
            more={more}
            reload={reload}
            hasMore={!!result?.are_more}
            total={dataLength}
          />
        </div>
        {factsOpened && (
          <SourceFacts
            contributor={factsOpened.key}
            requestType={type}
            graphSourceType={factsOpened.type}
            identifier={encodeURIComponent(identifier)}
          />
        )}
      </AuditColumns.Column>
    </InnerDialog>
  )
}

export default AuditIdentifierSources
