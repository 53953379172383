import React from 'react'

import MuiTooltip, { TooltipProps } from '@mui/material/Tooltip'
import { withStyles } from 'tss-react/mui'

export const TooltipStyled = withStyles(MuiTooltip, (theme) => ({
  tooltip: {
    margin: `0 ${theme.spacing(2)}`,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.light,
    color: theme.palette.text.primary,
    boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);',
    fontSize: 14,
    lineHeight: '22px',
    maxWidth: 400
  }
}))

const Tooltip = ({
  children,
  title,
  disabled,
  placement
}: {
  disabled?: boolean
} & Pick<TooltipProps, 'placement' | 'children' | 'title'>) => {
  if (disabled) {
    return children
  }

  return (
    <TooltipStyled placement={placement} title={title}>
      {children}
    </TooltipStyled>
  )
}

export default Tooltip
