import { useEffect, useContext, useMemo } from 'react'

import { Route, Switch } from 'react-router-dom'

import { IconButton } from '_shared/buttons'
import Page from '_shared/Page'

import LeaveDialog from '_core/components/dialogs/Leave'
import FormLayout, { FormLayoutActions, FormLayoutContent } from '_core/components/FormLayout'
import { Narrow } from '_core/components/layout'
import { SelectTagsActions, SelectTagsContent } from '_core/components/SelectTagsForm'
import SidepanelLink from '_core/components/SidepanelLink'
import TagsSuccessBody, { AddTagsSuccessLayout } from '_core/components/TagsSuccess'
import Topbar from '_core/components/Topbar'

import useAdmin from '_core/hooks/useAdmin'
import useDialog from '_core/hooks/useDialog'
import useSearchQuery from '_core/hooks/useSearchQuery'
import useSidepanelClose from '_core/hooks/useSidepanelClose'
import useTagsAssign from '_core/hooks/useTagsAssign'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const AddTagsPage = () => {
  const { setMobileHeader, setSubHeader } = useContext(LayoutContext)
  const { queryParams } = useSearchQuery<{ names: string; keys: string }, { modifyProps: [{ keys: string[] }] }>(['keys'])

  const { names, keys = [] } = queryParams || {}

  const admin = useAdmin()

  const { items, totalActive, isReady, initialTagsProvided, isDirty, itemsLoading, clearSelected, toggleActive, setFilteredTags, handleSave } =
    useTagsAssign({
      taggableType: 'people',
      items: useMemo(() => [], []),
      loading: false
    })

  const { isDialogOpened, openDialog, closeDialog } = useDialog()

  const manageTagsLink = `${Paths._tags}/people`

  useEffect(() => {
    setMobileHeader('Add tags')
  }, [setMobileHeader])

  useEffect(() => {
    setSubHeader(names)
    return () => setSubHeader('')
  }, [setSubHeader, names])

  const closeSidepanel = useSidepanelClose(Paths._people)

  const handleSubmit = () => {
    handleSave(keys, `${Paths._people}/add-tags/success`)
  }

  const handleCancel = () => {
    isDirty ? openDialog() : closeSidepanel()
  }

  return (
    <Page>
      <Narrow>
        <Switch>
          <Route path={`${Paths._people}/add-tags/success`}>
            <Topbar nativeBack />
            <AddTagsSuccessLayout>
              <TagsSuccessBody closeLink={Paths._people} />
            </AddTagsSuccessLayout>
          </Route>
          <Route>
            <FormLayout>
              <Topbar
                nativeBack
                {...(admin
                  ? {
                      action: (
                        <SidepanelLink linkProps={{ to: manageTagsLink }}>
                          <IconButton icon={['far', 'money-check-pen']} size="small" hint="Manage tags" />
                        </SidepanelLink>
                      )
                    }
                  : {})}
              />
              <FormLayoutContent>
                <SelectTagsContent
                  isReady={isReady}
                  initialTagsProvided={initialTagsProvided}
                  loading={itemsLoading}
                  manageTagsLink={manageTagsLink}
                  items={items}
                  toggleActive={toggleActive}
                  setFilteredTags={setFilteredTags}
                />
              </FormLayoutContent>
              <FormLayoutActions>
                <SelectTagsActions
                  clearSelected={clearSelected}
                  handleSubmit={handleSubmit}
                  totalActive={totalActive}
                  handleCancel={handleCancel}
                  disabledApply={!isDirty}
                />
              </FormLayoutActions>
              <LeaveDialog opened={isDialogOpened} close={closeDialog} confirm={closeSidepanel} />
            </FormLayout>
          </Route>
        </Switch>
      </Narrow>
    </Page>
  )
}

export default AddTagsPage
