import React, { ReactElement } from 'react'

import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'

import Popover, { PopoverProps } from '_shared/popovers/Popover'
import Typography from '_shared/Typography'

export type IconPopoverProps = Omit<FontAwesomeIconProps, 'onClick' | 'title'> &
  Pick<PopoverProps, 'maxWidth' | 'placement'> & { title: string | ReactElement }

export const HelpIconPopover = ({ maxWidth, title, ...props }: Omit<IconPopoverProps, 'icon'>) => (
  <IconPopover icon={['far', 'question-circle']} maxWidth={maxWidth || 400} title={title} {...props} />
)

const IconPopover = ({ title, maxWidth, placement, color, ...iconProps }: IconPopoverProps) => (
  <Popover placement={placement || 'bottom'} maxWidth={maxWidth} triggerElement={<FontAwesomeIcon color={color || '#979797'} {...iconProps} />}>
    {typeof title === 'string' ? <Typography>{title}</Typography> : title}
  </Popover>
)

export default IconPopover
