import { Component, ReactNode } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { isSidepanel } from '_pages/sidebar'

import { Button } from '_shared/buttons'
import Typography from '_shared/Typography'

import Empty from '_core/components/Empty'
import { Wide, Narrow } from '_core/components/layout'

import useSidebar from '_core/hooks/useSidebar'
import useSidepanelClose from '_core/hooks/useSidepanelClose'

import { defaultSettings } from 'utils/fetchUtils'

import { logout } from 'Auth'
import { apiHost } from 'config'

import { getBrowser } from './helpers/browser'
import useSidepanel from './hooks/useSidepanel'

type StateType = {
  hasError: boolean
  error: ErrorEvent | null
  rejectError: PromiseRejectionEvent | null
}

type PropsType = {
  children: ReactNode
} & RouteComponentProps

const useStyles = makeStyles()((theme) => ({
  container: {
    maxWidth: 540,
    margin: '0 auto',
    padding: theme.spacing(2)
  },
  notSupported: {
    marginTop: 80
  },
  refresh: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      marginRight: theme.spacing(1)
    }
  },
  buttonsGroup: {
    margin: `${theme.spacing(2)} auto`,
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'center'
    },
    '& .MuiButton-root:not(:last-child)': {
      marginBottom: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(1),
        marginBottom: 0
      }
    }
  },
  olList: {
    textAlign: 'left',
    paddingLeft: theme.spacing(2)
  },
  disablePaddings: {
    padding: 0
  }
}))

const DefaultActions = (props: { classes: any; history: RouteComponentProps['history'] }) => {
  const { classes } = useStyles()
  const { history } = props

  const goBack = () => {
    history.goBack()
  }

  const handleLogout = async () => {
    try {
      await logout()
    } catch (error) {
      console.error('error during logout', error)
    }
  }

  const handleRefreshClick = () => {
    window.location.href = window.location.origin + window.location.pathname
  }

  return (
    <Box className={classes.buttonsGroup}>
      <Wide>
        <Button className={classes.refresh} onClick={handleRefreshClick} startIcon={<FontAwesomeIcon icon={['far', 'arrows-rotate']} />}>
          Refresh
        </Button>
        <Button onClick={goBack} startIcon={<FontAwesomeIcon icon={['far', 'arrow-left']} />}>
          Previous page
        </Button>
        <Button onClick={handleLogout} startIcon={<FontAwesomeIcon icon={['far', 'sign-out']} />}>
          Logout
        </Button>
      </Wide>
      <Narrow>
        <Button
          className={classes.refresh}
          size="small"
          onClick={() => window.location.reload()}
          startIcon={<FontAwesomeIcon icon={['far', 'arrows-rotate']} />}
        >
          Refresh
        </Button>
        <Button onClick={goBack} size="small" startIcon={<FontAwesomeIcon icon={['far', 'arrow-left']} />}>
          Previous page
        </Button>
        <Button onClick={handleLogout} startIcon={<FontAwesomeIcon icon={['far', 'sign-out']} />}>
          Logout
        </Button>
      </Narrow>
    </Box>
  )
}

const SignOutActions = () => {
  const { classes } = useStyles()

  const handleLogout = async () => {
    try {
      localStorage.removeItem('numAttempts')
      await logout()
    } catch (error) {
      console.error('error during logout', error)
    }
  }

  return (
    <span className={classes.buttonsGroup}>
      <Button onClick={handleLogout} startIcon={<FontAwesomeIcon icon={['far', 'sign-out']} />}>
        Logout
      </Button>
    </span>
  )
}

const NoAcessActions = (props: { history: RouteComponentProps['history'] }) => {
  const { classes } = useStyles()
  const { history } = props
  const handleClose = useSidepanelClose()

  const sidepanel = isSidepanel()

  const { backActive } = useSidebar()

  const goBack = () => {
    history.goBack()
  }

  const handleRefreshClick = () => {
    window.location.href = window.location.origin + window.location.pathname
  }

  return (
    <Box className={classes.buttonsGroup}>
      <Wide>
        <Button className={classes.refresh} onClick={handleRefreshClick} startIcon={<FontAwesomeIcon icon={['far', 'arrows-rotate']} />}>
          Refresh
        </Button>
        <Button onClick={goBack} startIcon={<FontAwesomeIcon icon={['far', 'arrow-left']} />}>
          Previous page
        </Button>
      </Wide>
      <Narrow>
        <Button
          className={classes.refresh}
          size="small"
          onClick={() => window.location.reload()}
          startIcon={<FontAwesomeIcon icon={['far', 'arrows-rotate']} />}
        >
          Refresh
        </Button>
        {(!sidepanel || backActive) && (
          <Button onClick={goBack} size="small" startIcon={<FontAwesomeIcon icon={['far', 'arrow-left']} />}>
            Previous page
          </Button>
        )}
        {isSidepanel('sidepanel') && (
          <Button onClick={handleClose} startIcon={<FontAwesomeIcon icon={['far', 'times']} />}>
            Close
          </Button>
        )}
      </Narrow>
    </Box>
  )
}

const ErrorContent = ({ type, history }: { type: keyof CustomErrorType; history: RouteComponentProps['history'] }) => {
  const { classes, cx } = useStyles()

  const reachTeamBtn = (
    <Button<'a'> href={`mailto:team@dotalign.com`} disablePadding component="a" variant="link" bold={false}>
      team@dotalign.com
    </Button>
  )

  const reachTeam = <>If the issue persists, please reach out to {reachTeamBtn}</>
  const { name, version } = getBrowser()

  const customErrors: CustomErrorType = {
    signInError: {
      title: 'Sign in error',
      subTitle: <>We can not sign in into your account. Please try to logout and sign in again. {reachTeam}</>,
      action: SignOutActions
    },
    notSupportedBrowser: {
      title: 'This browser version is not supported',
      subTitle: (
        <>
          {name} version &lt;{version}&gt; is not supported for use with DotAlign. Please contact your local administrator or reach out to{' '}
          {reachTeamBtn} for help resolving the issue.
        </>
      ),
      action: () => null,
      className: classes.notSupported
    },
    popupBlockedError: {
      title: 'Please enable pop-ups',
      subTitle: (
        <>
          <ol className={classes.olList}>
            <li>Look for the small icon in the address bar that indicates blocked pop-ups.</li>
            <li>
              Click on the icon and select &nbsp;
              <Typography component="span" color="text.hint" semiBold>
                &apos;Always allow pop-ups and redirects from this site&apos;
              </Typography>
              .
            </li>
            <li>Refresh the page and proceed with your login.</li>
          </ol>
          Please reach out to {reachTeamBtn} for help.
        </>
      ),
      action: () => null,
      className: classes.disablePaddings
    },
    accessDenied: {
      title: 'Access denied',
      subTitle: (
        <>
          You don&apos;t have permissions to view this page. Please contact your local administrator or reach out to{' '}
          <Button<'a'> href={`mailto:team@dotalign.com`} disablePadding component="a" variant="link" bold={false}>
            team@dotalign.com
          </Button>{' '}
          for help resolving the issue.
        </>
      ),
      action: NoAcessActions
    }
  }

  const {
    title = 'Unexpected error',
    subTitle = <>There was an unexpected error while rendering this screen. {reachTeam}</>,
    action: Actions = DefaultActions,
    className = ''
  } = type && type in customErrors ? customErrors[type] : {}

  return (
    <Empty
      className={cx(classes.container, className)}
      title={title}
      subTitle={subTitle}
      icon={<FontAwesomeIcon icon={['fas', 'exclamation-triangle']} color="#F5A91C" size="4x" style={{ opacity: 0.5, marginTop: 8 }} />}
      action={<Actions history={history} />}
    />
  )
}

export class Boundaries extends Component<RouteComponentProps, StateType> {
  state: StateType = {
    hasError: false,
    error: null,
    rejectError: null
  }

  static getDerivedStateFromError(error: ErrorEvent): StateType {
    return { hasError: true, rejectError: null, error }
  }

  catchErrors = (event: ErrorEvent) => {
    this.setState({ hasError: true, error: event })
  }

  catchUnhandledRejection = (event: PromiseRejectionEvent) => {
    this.setState({ hasError: true, rejectError: event })
  }

  componentDidMount() {
    window.addEventListener('error', this.catchErrors)
    window.addEventListener('unhandledrejection', this.catchUnhandledRejection)
  }

  componentWillUnmount() {
    window.removeEventListener('error', this.catchErrors)
    window.removeEventListener('unhandledrejection', this.catchUnhandledRejection)
  }

  componentDidUpdate(prevProps: PropsType, prevState: StateType) {
    if (this.state.hasError && !prevState.hasError) {
      this.postError()
    }

    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ hasError: false, error: null })
    }
  }

  postError = () => {
    const { error, rejectError } = this.state
    const details = error?.message || rejectError?.reason || error || rejectError
    // anon log
    fetch(`${apiHost}/api/log`, {
      method: 'POST',
      body: JSON.stringify({
        actionType: this.props.match.path,
        details: details && typeof details !== 'string' ? JSON.stringify(details) : details
      }),
      ...defaultSettings
    })
  }

  render() {
    const { rejectError, error } = this.state

    const errorType: keyof CustomErrorType = rejectError?.reason?.type || error?.type

    if (this.state.hasError) {
      return <ErrorContent type={errorType} {...this.props} />
    }

    return this.props.children
  }
}

const ErrorBoundaries = withRouter(Boundaries)

export const withErrors = (RoutingComponent: any) => (props: any) => (
  <ErrorBoundaries {...props}>
    <RoutingComponent {...props} />
  </ErrorBoundaries>
)

export default ErrorBoundaries
