import React, { useContext, useEffect, useState, useMemo } from 'react'

import { Box } from '@mui/material'
import { GridSelectionModel } from '@mui/x-data-grid-pro'
import { useParams } from 'react-router-dom'

import { TeamContext } from '_core/context/TeamContext'

import { AffiliationPopover } from '_core/components/Affiliation'
import Download from '_core/components/Download'
import DownloadBulkControl from '_core/components/DownloadBulkControl'
import Grid, { DataGrid, GridTypes } from '_core/components/grid'
import { companyColumn, jobTitleColumn, nameColumn, scoreColumn } from '_core/components/grid/columns'
import { Narrow, Wide } from '_core/components/layout'
import Repeater from '_core/components/lists/Repeater'
import ProfileItem from '_core/components/ProfileItem'
import SearchInput from '_core/components/SearchInput'
import Topbar from '_core/components/Topbar'
import Widget from '_core/components/Widget'

import { DownloadBulkParams } from '_core/hooks/useDownloadBulk'
import useSearchQuery from '_core/hooks/useSearchQuery'

import DynamicEntity from '_core/DynamicEntity'

import { getBinary, mergeUrlWithParams } from 'utils/httpUtils'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

export type ContactsProps = {
  items: UserCompanyContactListItem[]
  loading: boolean
  total: number
  paging: GridTypes['paging']
  setPageSize: GridTypes['setPageSize']
  updateSort: (val: ScoreType | StatSortType) => void
  download: { fileName: string; requestBinary: (data: DownloadBulkParams) => Promise<Blob | undefined> }
}

const Contacts = (props: ContactsProps) => {
  const { setSubHeader } = useContext(LayoutContext)
  const [selected, setSelected] = useState<string[]>([])

  useEffect(() => {
    setSubHeader(`Contacts ${props.total ? `· ${props.total}` : ''}`)
    return () => setSubHeader('')
  }, [setSubHeader, props.total])

  const items = useMemo(
    () =>
      props.items?.map((person) => {
        const formerJob = person.IsFormerJob
        const workExperience = {
          companyIdentity: person.CompanyMd5,
          companyName: person.JobCompanyName || person.CorpLevelCompanyName,
          jobTitle: person.TitleText,
          currentAsOf: person.JobCurrentAsOf
        }
        return {
          id: person.MyUserKeyMd5 || person.PersonMd5,
          name: person.PersonNameText,
          byline: person.TitleText || '',
          byline2: person.JobCompanyName || person.CorpLevelCompanyName || '',
          score: person.UserKnowsPersonScore,
          link: `${Paths._people}/${person.MyUserKeyMd5 || person.PersonMd5}`,
          title: person.TitleText || '',
          formerJob,
          company: {
            name: person.JobCompanyName || person.CorpLevelCompanyName || '',
            link: `${Paths._companies}/${person.CompanyMd5}`,
            sidepanel: true
          },
          workExperience,
          sidepanel: true,
          icons: (
            <Box display="flex" alignItems="center" justifyContent="end" mr={0.5}>
              {formerJob && (
                <Box ml={1.5}>
                  <AffiliationPopover workExperience={workExperience} icon={['far', 'address-book']} />
                </Box>
              )}
            </Box>
          )
        }
      }),
    [props.loading]
  )

  const handleSelect = (selectionModel: GridSelectionModel) => {
    setSelected(selectionModel as string[])
  }

  const DownloadAll = <DownloadBulkControl totalRows={props.total} disabled={props.loading} download={props.download} />

  const DownloadSelected = <Download disabled={!selected.length} leadsList={selected} leadType="Person" />

  return (
    <>
      <Narrow>
        <Repeater direction="vertical" variant="list" component={ProfileItem} skeleton={{ size: 10, loading: props.loading }} items={items} />
      </Narrow>
      <Wide>
        <DataGrid
          rows={items}
          columns={[nameColumn, jobTitleColumn, companyColumn, scoreColumn]}
          controls={[DownloadAll, DownloadSelected]}
          loading={props.loading}
          setPageSize={props.setPageSize}
          checkboxSelection
          paging={props.paging}
          total={props.total}
          onSelect={handleSelect}
        />
      </Wide>
    </>
  )
}

const Component = () => {
  const { from, to } = useParams<any>()
  const { queryParams, updateQuery } = useSearchQuery<GridParams>()
  const { keyword, rowsPerPage = '10', sort } = queryParams
  const { teamContextValue } = useContext(TeamContext)

  const onPageSizeChange = (pageSize: NumberToString<RowPerPageOptionsType>) => {
    updateQuery({ rowsPerPage: pageSize })
  }

  const payload = {
    TeamNumber: `${teamContextValue.teamNumber}`,
    SortBy: sort || 'ScoreDesc',
    SortScoreType: 'PersonalScore'
  }

  const download = {
    fileName: 'company_contacts',
    requestBinary: (data: DownloadBulkParams) => getBinary(`/companies/${to}/peoplexl`, { ...payload, ...data })
  }

  return (
    <DynamicEntity<{ extraProps: { addprops: Pick<ContactsProps, 'download'> } }>
      url={mergeUrlWithParams(`/users/${from}/companies/${to}/people`, payload)}
      component={Contacts}
      addprops={{ download }}
      updatePageSize={onPageSizeChange}
      pageSize={+rowsPerPage}
      list
      infinite
      search
      keepMounted
      empty="No results found"
      emptySubtitle={keyword ? `No results found for your search '${keyword}'` : ''}
      id="company_contacts"
    />
  )
}

const RelationshipContacts = () => {
  return (
    <>
      <Wide>
        <Box display="flex" height={1} minHeight={'calc(100vh - 296px)'}>
          <Grid>
            <Grid.Heading title="Contacts">
              <Box display="flex" alignItems="center">
                <SearchInput placeholder="Search for contacts" variant="collapsed" opened />
              </Box>
            </Grid.Heading>
            <Component />
          </Grid>
        </Box>
      </Wide>
      <Narrow>
        <Topbar nativeBack />
        <Widget>
          <SearchInput placeholder="Search for contacts" variant="collapsed" opened />
        </Widget>
        <Component />
      </Narrow>
    </>
  )
}

export default RelationshipContacts
