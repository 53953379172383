import React, { useState, useRef, cloneElement, ComponentType, ReactElement, useCallback, useMemo, useEffect } from 'react'

import { Box, Card, CardContent, Container } from '@mui/material'
import { useSnackbar } from 'notistack'
import { Route, useLocation } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { isSidepanel } from '_pages/sidebar'

import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import EditUndoDialog, { ManualEditRowItem } from '_core/components/dialogs/ManualEditUndo'
import { Controller as FilterController, ManualEditsFiltersType } from '_core/components/filters/ManualEdits'
import { DataGrid, GridTypes } from '_core/components/grid'
import { Narrow, useWide, Wide } from '_core/components/layout'
import { useAutoHideOnScrollStyles } from '_core/components/layout/autohide-on-scroll'
import Repeater from '_core/components/lists/Repeater'
import ManualEditUndo, { HelpLink } from '_core/components/ManualEditUndo'
import Overlay from '_core/components/Overlay'
import SearchInput from '_core/components/SearchInput'
import Topbar from '_core/components/Topbar'
import Widget from '_core/components/Widget'

import useDialog from '_core/hooks/useDialog'
import { useTabs } from '_core/hooks/useTabs'

import { destroyAnimation } from '_core/helpers/html'
import { checkOutlook } from '_core/helpers/outlook'

import { formatDateTime, formatDateTimeFromNow } from 'utils/Utils'

import TabSet from 'SharedComponents/TabSet'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  title: {
    marginRight: theme.spacing(2)
  },
  card: {
    textAlign: 'center',
    background: theme.palette.background.light,
    position: 'relative',
    borderRadius: 10
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  token: {
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '0.25px',
    minHeight: theme.spacing(3),
    maxWidth: `calc(100% - ${theme.spacing(5)}px)`,
    minWidth: theme.spacing(20)
  },
  assertion: {
    lineHeight: `${theme.spacing(3)}px`,
    color: theme.palette.text.secondary,
    letterSpacing: '0.25px'
  },
  date: {
    color: '#7d7d7d',
    fontSize: 12,
    lineHeight: `${theme.spacing(2.5)}px`,
    padding: `${theme.spacing(1)}px ${theme.spacing(2.5)}px`,
    background: '#F8F8FB',
    borderRadius: `0px 0px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  cardIcon: {
    position: 'absolute',
    top: 6,
    right: 9
  },
  skeleton: {
    margin: '0 auto'
  },
  input: {
    marginRight: theme.spacing(0.5),
    maxWidth: `calc(100% - ${39}px)`,
    transition: 'max-width 0.3s ease-in-out',
    flex: 1,
    zIndex: 2
  },
  headerIcons: {
    display: 'flex',
    justifyContent: 'flex-end',
    maxWidth: 39,
    transition: 'max-width 0.3s ease-in-out',
    flex: 1
  },
  heading: {
    width: '100%',
    zIndex: 1,
    marginBottom: theme.spacing(2),
    background: theme.palette.background.light,
    [theme.breakpoints.up('md')]: {
      background: 'inherit',
      position: 'inherit'
    }
  },
  tabSet: {
    padding: 0
  },
  questionIcon: {
    position: 'fixed',
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 1000,
    marginRight: 42
  }
}))

const tabs = [
  {
    label: 'COMPANIES',
    routesMatch: [`${Paths._manualEdits}/companies`, `${Paths._manualEdits}/companies/*`]
  },
  {
    label: 'PEOPLE',
    routesMatch: [`${Paths._manualEdits}/people`, `${Paths._manualEdits}/people/*`]
  },
  {
    label: 'PRIVATE RELATIONSHIPS',
    routesMatch: [`${Paths._manualEdits}/privaterelationships`]
  }
]

type HeadingProps = {
  filters: ReactElement
  filtersProps: Pick<ManualEditsFiltersType, 'opened' | 'disabled'> & {
    toggleOpen: () => void
  }
  disabledControls: boolean
}

export const Heading = ({ filtersProps, filters }: HeadingProps) => {
  const wide = useWide()
  const { pathname } = useLocation()

  const { toggleOpen: toggleFiltersOpen, opened: filtersOpened, disabled } = filtersProps
  const { classes } = useStyles()

  const anchorRef = useRef<HTMLDivElement | null>(null)

  const hasSidebar = isSidepanel() || checkOutlook()

  const { tabIndex, setTabIndex, tabChanged } = useTabs(tabs)
  const { autoHideClassName } = useAutoHideOnScrollStyles(!wide)

  useEffect(() => {
    if (!wide && filtersOpened) {
      toggleFiltersOpen()
    }
  }, [pathname])

  const TitleSection = (
    <div className={classes.heading}>
      <Wide>
        <Box display="flex" justifyContent="space-between" mb={2} px={{ xs: 1, md: 0 }}>
          <Box display="flex" alignItems="center">
            <Typography variant="h1" className={classes.title}>
              Manual edits
            </Typography>
            <HelpLink />
          </Box>
        </Box>
      </Wide>
      <Typography variant="h4" color="textSecondary">
        These are user-made manual edits, which DotAlign incorporates into the system to improve data quality
      </Typography>
    </div>
  )

  return (
    <>
      <Wide>
        {TitleSection}
        <TabSet className={classes.tabSet} tabIndex={tabIndex} setTabIndex={setTabIndex} tabs={tabs} onChange={tabChanged} />
      </Wide>
      <Narrow>
        {hasSidebar && <Topbar nativeBack action={<HelpLink />} />}
        <Widget className={autoHideClassName} sticky={hasSidebar ? 88 : 60}>
          {TitleSection}
          <TabSet className={classes.tabSet} tabIndex={tabIndex} setTabIndex={setTabIndex} tabs={tabs} onChange={tabChanged} />
          <Route path={[`${Paths._manualEdits}/people`, `${Paths._manualEdits}/companies`]}>
            <div ref={anchorRef}>
              <Box display="flex">
                <SearchInput disabled={disabled} placeholder="Search for manual edit" variant="collapsed" opened wrapperClassName={classes.input} />
                <Box className={classes.headerIcons}>
                  <FilterController opened={filtersOpened} toggleOpen={toggleFiltersOpen} disabled={disabled} />
                </Box>
              </Box>
            </div>
            {cloneElement(filters, { anchorEl: anchorRef.current })}
          </Route>
        </Widget>
      </Narrow>
    </>
  )
}

const EditItem = (
  props: ManualEditRowItem & {
    confirmDelete: (payload: ManualEditRowItem[], successCallback?: () => void) => Promise<void>
    cellAction: ComponentType<{ row: ManualEditRowItem & { openUndo: () => void; className: string } }>
  }
) => {
  const { classes } = useStyles()
  const { confirmDelete, cellAction: Action, ...item } = props
  const { identifier1RefText, identifier2RefText, date, assertionLabel, assertedBy } = item
  const [isUndoMode, setUndoMode] = useState<boolean>(false)
  const [undoLoading, setUndoLoading] = useState<boolean>(false)

  const el = React.useRef<HTMLDivElement>(null)

  const removeItem = () => {
    if (el.current) {
      destroyAnimation(el.current.parentNode)
    }
  }

  const open = () => {
    setUndoMode(true)
  }

  const close = () => {
    setUndoMode(false)
  }

  const confirm = async () => {
    setUndoLoading(true)
    await confirmDelete([item], removeItem)
    setUndoLoading(false)
    close()
  }

  return (
    <div ref={el}>
      <Overlay
        isOpen={isUndoMode}
        overlayContent={
          <ManualEditUndo loading={undoLoading} title="Undo Manual Edit?" handleConfirm={confirm} handleCancel={close} items={[item]} />
        }
      >
        <Card variant="outlined" classes={{ root: classes.card }}>
          {assertionLabel && Action && <Action row={{ ...item, openUndo: open, className: classes.cardIcon }} />}
          <CardContent classes={{ root: classes.cardContent }}>
            <Skeleton condition={!identifier1RefText}>
              <Tooltip title={identifier1RefText}>
                <Typography classes={{ root: classes.token }} noWrap>
                  {identifier1RefText}
                </Typography>
              </Tooltip>
            </Skeleton>
            <Tooltip title={assertionLabel}>
              <Typography classes={{ root: classes.assertion }}>
                <Skeleton width={140} condition={!assertionLabel}>
                  {assertionLabel}
                </Skeleton>
              </Typography>
            </Tooltip>
            <Skeleton condition={!identifier2RefText && !identifier1RefText}>
              <Tooltip title={identifier2RefText}>
                <Typography classes={{ root: classes.token }} noWrap>
                  {identifier2RefText}
                </Typography>
              </Tooltip>
            </Skeleton>
          </CardContent>
          <Tooltip title={formatDateTime(date)}>
            <Typography classes={{ root: classes.date }}>
              <Skeleton width={200} classes={{ root: classes.skeleton }} condition={!assertedBy || !formatDateTimeFromNow(date, 5)}>
                {date && `${formatDateTimeFromNow(date, 5)} by ${assertedBy}`}
              </Skeleton>
            </Typography>
          </Tooltip>
        </Card>
      </Overlay>
    </div>
  )
}

type ManualEditsType = (
  | ({ loading: true } & Modify<GridTypes, Partial<Omit<GridTypes, 'columns'>> & { items: ManualEditRowItem[] }>)
  | ({ loading: false } & Modify<Pick<GridTypes, 'total' | 'setPageSize' | 'columns' | 'paging'>, { items: ManualEditRowItem[] }>)
) & {
  confirmDelete: (payload: ManualEditRowItem[]) => Promise<
    | {
        success: boolean
        errorMessage?: string | undefined
      }
    | undefined
  >
}

const ManualEditsList = (props: ManualEditsType) => {
  const [selected, setSelected] = useState<string[]>([])
  const { total, setPageSize, columns, paging, items, loading, confirmDelete } = props
  const { openDialog, closeDialog, isDialogOpened } = useDialog(false)
  const { enqueueSnackbar } = useSnackbar()

  const selectedItems = items.filter(({ id }) => selected.includes(id))

  const handleSelect = useCallback(
    (selected: string[]) => {
      setSelected(selected)
      openDialog()
    },
    [openDialog, setSelected]
  )

  const clearSelected = useCallback(() => {
    setSelected([])
    closeDialog()
  }, [closeDialog, setSelected])

  const confirm = async (payload: ManualEditRowItem[], successCallback?: () => void) => {
    const { success, errorMessage } = (await confirmDelete(payload)) || {}

    if (success) {
      enqueueSnackbar('Manual edit is successfully removed', {
        variant: 'default'
      })
      if (successCallback) {
        successCallback()
      }
    } else {
      enqueueSnackbar(errorMessage, {
        variant: 'error'
      })
    }
  }

  const rows = useMemo(() => {
    return items.map((item) => ({ ...item, openUndo: () => handleSelect([item.id]) }))
  }, [items, handleSelect])

  return (
    <>
      <Wide>
        <DataGrid rows={rows} columns={columns} controls={[]} {...(loading ? { loading } : { loading, total, paging, setPageSize })} />
        <EditUndoDialog items={selectedItems} confirmDelete={confirm} isOpened={isDialogOpened} close={clearSelected} title="Undo Manual Edit?" />
      </Wide>
      <Narrow>
        <Container>
          <Repeater
            direction="vertical"
            component={EditItem}
            skeleton={{ size: 20, loading }}
            items={items.map((item) => ({
              ...item,
              confirmDelete: confirm,
              cellAction: columns.find(({ field }) => field === 'action')?.renderCell
            }))}
          />
        </Container>
      </Narrow>
    </>
  )
}

export default ManualEditsList
