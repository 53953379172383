import { useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'
import 'abortcontroller-polyfill'

import useAbortableFetch from '_core/hooks/useAbortableFetch'

import { updateQuerystring, parseUrl } from '_core/helpers/browser'

type ReturnType<ResultType> = {
  result: unknown extends ResultType ? any : ResultType | undefined | null
  loading: boolean
  more: () => void
  reload: () => void
  append: boolean
  paging: {
    page: number
    size: number | undefined
  }
}

const useEntityEndpoint = <T,>(
  url?: string | null,
  urls?: { key: string; url: string; merge?: boolean; single?: boolean }[] | null,
  pageSize?: number,
  search?: boolean | string,
  infinite?: boolean
): ReturnType<T> => {
  const [{ result, loading }, setState] = useState<{ result: any; loading: boolean }>({ result: undefined, loading: true })
  const [append, setAppend] = useState<boolean>(false)
  const [skip, setSkip] = useState<number | string>()

  const location = useLocation()
  const { page, rowsPerPage } = parseUrl(location.search) as { page: string; rowsPerPage: string }
  const keyword = typeof search === 'string' ? search : new URLSearchParams(location.search).get('keyword')
  const { fetchWithAbort } = useAbortableFetch<any>()

  const more = () => {
    setAppend(true)
    setSkip(result?.skip_token ? result.skip_token : result?.page_end || 0)
  }

  const reload = () => {
    setAppend(false)
    setSkip(undefined)
  }

  const updateUrl = (url: string) => {
    let endpoint = url

    // back to infinite list scrolls to saved position
    // if (!skip && infinite && page && rowsPerPage) {
    //   endpoint = updateQuerystring(url, 'Take', +page * +rowsPerPage)
    // }
    if (pageSize) endpoint = updateQuerystring(endpoint, 'Take', pageSize)
    if (search) endpoint = updateQuerystring(endpoint, 'SearchTerm', keyword || undefined)
    if (append && result) endpoint = updateQuerystring(endpoint, typeof skip === 'number' ? 'Skip' : 'SkipToken', skip)
    else {
      setState({ loading: true, result: undefined })
    }
    return endpoint
  }

  useEffect(() => {
    reload()
  }, [urls, url, keyword])

  useEffect(() => {
    if (!url) return

    let requestActive = true

    const makeRequest = async () => {
      const endpoint = updateUrl(url)

      const resp = await fetchWithAbort({ url: endpoint })
      if (requestActive && resp) {
        if (append && result?.data) resp.data = [...result.data, ...resp.data]

        setState({
          loading: false,
          result: resp.results || resp.data ? resp : { results: resp, total_item_count: resp.length }
        })
      } else {
        return null
      }
    }

    makeRequest()

    return () => {
      requestActive = false
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, skip, pageSize, search && keyword])

  useEffect(() => {
    if (!urls) return
    let requestActive = true

    const makeRequests = async () => {
      const resp = await fetchWithAbort(urls.map((endpoint: any) => ({ url: updateUrl(endpoint.url) })))
      if (resp && requestActive) {
        const endpoints = urls.map((endpoint, i) => ({ ...endpoint, result: resp[i] }))
        const result = endpoints
          .map((t: any) => (!t.merge ? { [t.key]: t.result } : (t.single && t.result.data?.[0]) || t.result))
          .reduce(function (result, current) {
            return Object.assign(result, current)
          }, {})
        const totals = endpoints.map((t: any) => t.result.total_item_count).reduce((result, x) => result + x, 0)
        setState({
          loading: false,
          result: { data: [result], total_item_count: totals }
        })
      } else {
        return null
      }
    }

    makeRequests()

    return () => {
      requestActive = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urls, skip, search && keyword])

  return {
    result,
    loading: loading || (typeof search === 'string' && search !== (keyword || '')),
    more,
    reload,
    append,
    paging: {
      page: parseInt(page, 10) || 1,
      size: pageSize
    }
  }
}

export default useEntityEndpoint
