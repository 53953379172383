import { useState, useRef, useEffect, useContext, ChangeEvent } from 'react'

import { Box } from '@mui/material'

import { GeneralSettingsContext } from '_core/context/GeneralSettings'

import Checkbox from '_shared/forms/Checkbox'
import TextField from '_shared/forms/TextField'

import HelpLinkButton from '_core/components/HelpLinkButton'
import Settings, { useStyles } from '_core/components/settings'

import useSaveSettings, { SaveAction } from '_core/hooks/useSaveSettings'

const GeneralSettings = (props: { loading: boolean; items: { customGaId: string; useCustomLogo: boolean } }) => {
  const {
    classes: { subsection, itemWrapper, iconButton }
  } = useStyles()
  const [settingsData, setSettingsData] = useState<{ customGaId: string; useCustomLogo: boolean }>(props.items)
  const customGaIdValueRef = useRef<string | null>(null)
  const customLogoTouched = useRef<boolean>(false)
  const { setLogo } = useContext(GeneralSettingsContext)

  const { isSaving, save: saveSettings } = useSaveSettings()

  useEffect(() => {
    if (customGaIdValueRef.current === null && !props.loading) {
      customGaIdValueRef.current = settingsData.customGaId
    }
  }, [settingsData?.customGaId, props.loading])

  const customCodeSettingsChange = (e: ChangeEvent<HTMLInputElement>) => {
    customGaIdValueRef.current = settingsData.customGaId
    setSettingsData({
      ...settingsData,
      customGaId: e.target.value
    })
  }

  const toggleCustomCodeSettingsChange = () => {
    setSettingsData((prevState) => ({
      ...settingsData,
      customGaId: prevState.customGaId ? '' : customGaIdValueRef.current || ''
    }))
  }

  const handleShowCustomLogo = (e: React.ChangeEvent<HTMLInputElement>) => {
    customLogoTouched.current = true
    setSettingsData({
      ...settingsData,
      useCustomLogo: e.target.checked
    })
  }

  const reset = () => {
    if (customLogoTouched.current) {
      setLogo('')
      customLogoTouched.current = false
    }
  }

  const save = (action: SaveAction) => saveSettings('/adminsettings/general', settingsData, action, 'General settings', reset)

  return (
    <Settings isSaving={isSaving} save={save} initialLoading={props.loading}>
      {settingsData && (
        <>
          <Box>
            <Checkbox
              disabled={isSaving}
              checked={!!settingsData.customGaId}
              onChange={toggleCustomCodeSettingsChange}
              name="customCode"
              label="Use custom Google Analytics 4 measurement ID"
            />
            <Box className={subsection}>
              <TextField
                size="small"
                disabled={isSaving}
                style={{ width: 124, textAlign: 'center' }}
                placeholder="G-XXXXXXXXXX"
                onChange={customCodeSettingsChange}
                defaultValue={settingsData.customGaId}
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center" className={itemWrapper}>
            <Checkbox
              disabled={isSaving}
              checked={settingsData.useCustomLogo}
              name="customLogo"
              onChange={handleShowCustomLogo}
              label="White label by showing my firm's logo instead of DotAlign's on the top of the app"
            />
            <HelpLinkButton
              hint="Learn more about showing my firm's logo"
              to={{ pathname: 'https://help.dotalign.com/article/numfsbvf1v-white-labeling-your-dot-align-deployment' }}
              classes={{ root: iconButton }}
            />
          </Box>
        </>
      )}
    </Settings>
  )
}

export default GeneralSettings
