import React, { HTMLAttributes, SyntheticEvent, useState } from 'react'

import { FormControlLabel, Grid, Radio, Box, createFilterOptions } from '@mui/material'
import { Controller, useWatch, useFormContext } from 'react-hook-form'
import { makeStyles } from 'tss-react/mui'

import Combobox from '_shared/forms/Combobox'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import { SidepanelWide, SidepanelNarrow } from '_core/components/layout'

import { SalesforceFormData } from '_core/hooks/useSalesforce'
import useSuggestOptionCreation from '_core/hooks/useSuggestOptionCreation'

import { formatDateFromNow, getLocal } from 'utils/Utils'

const useStyles = makeStyles()((theme) => ({
  time: {
    justifyContent: 'flex-end',
    width: '100%',
    fontSize: 12,
    lineHeight: '15px',
    fontWeight: 600,
    marginLeft: 28,
    maxWidth: 'calc(100% - 28px)',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 'unset'
    }
  },
  formLabel: {
    alignItems: 'start',
    width: 'calc(100% + 11px)',
    marginRight: 0,
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.up('sm')]: {
      marginBottom: 'unset',
      maxWidth: 250
    },
    '& .MuiTypography-root': {
      flexGrow: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: 'calc(100vw - 69px)'
    }
  },
  secondaryText: {
    marginBottom: theme.spacing(2)
  }
}))

const FORMAT = 'MMM DD, YYYY'

const formatCreationDate = (date: moment.MomentInput) => {
  return formatDateFromNow(date, 5, FORMAT)
}

type OptionProps = { field: keyof SalesforceFormData; source: SalesforceValue['source']; options: SalesforceData[] }

const Option = ({ field, source, options }: OptionProps) => {
  const { classes } = useStyles()
  const {
    control,
    setValue,
    formState: { errors }
  } = useFormContext<SalesforceFormData>()

  const customFilterOptions = createFilterOptions<SalesforceData>({
    stringify: (option) => option.value || ''
  })

  const filterWithSuggest = useSuggestOptionCreation<SalesforceData, SalesforceData & { label: string }>({
    loading: false,
    filterFn: customFilterOptions,
    transformOption: (inputValue: string) => ({
      value: inputValue,
      label: `Add "${inputValue}"`,
      lastEvidenceDate: getLocal().utcOffset(0, true).utcOffset(0, true).format()
    })
  })

  const [optionsArray, setOptions] = useState<SalesforceData[]>(options)

  const { value, lastEvidenceDate } =
    useWatch<SalesforceFormData, `${typeof field}.selectedValue.${typeof source}`>({
      control,
      name: `${field}.selectedValue.${source}`
    }) || {}

  const handleChange = async (newValue: string | (SalesforceData & { label?: string }) | null, callback: (value: SalesforceData) => void) => {
    if (newValue) {
      if (typeof newValue === 'string') {
        const lastEvidenceDate = getLocal().utcOffset(0, true).utcOffset(0, true).format()
        setValue(`${field}.selectedSource`, source, { shouldValidate: true })
        const isValueDublicate = options.some((option) => option.value === newValue)
        isValueDublicate || setOptions((prev) => [...prev, { value, lastEvidenceDate }])
        callback({ value, lastEvidenceDate })
      } else {
        const { value, lastEvidenceDate = getLocal().utcOffset(0, true).format() } = newValue
        setValue(`${field}.selectedSource`, source, { shouldValidate: true })
        const isValueDublicate = options.some((option) => option.value === value)
        isValueDublicate || setOptions((prev) => [...prev, { value, lastEvidenceDate }])
        callback({ value, lastEvidenceDate })
      }
    } else {
      callback({ value: '' })
    }
  }

  const renderOption = (props: HTMLAttributes<HTMLLIElement>, option: SalesforceData & { label?: string }) => {
    const label = option.label || option.value
    if (label) {
      return (
        <li {...props}>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Tooltip title={label}>
              <Typography noWrap>{label}</Typography>
            </Tooltip>
            <Typography color="text.secondary">{formatCreationDate(option.lastEvidenceDate)}</Typography>
          </Box>
        </li>
      )
    }
  }

  const isSelector = source === 'DotAlign'
  const { message: errorMessage } = (errors[field] as any)?.selectedValue[source]?.value || {}

  return (
    <Grid item>
      <SidepanelWide>
        <FormControlLabel
          classes={{ root: classes.formLabel }}
          value={source}
          disabled={!isSelector && !value}
          control={<Radio />}
          label={
            isSelector ? (
              <Controller
                name={`${field}.selectedValue.${source}`}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Combobox
                    value={value}
                    autoHighlight
                    freeSolo
                    clearOnBlur
                    onChange={(e: SyntheticEvent<Element, Event>, newValue: string | (SalesforceData & { label?: string }) | null) =>
                      handleChange(newValue, onChange)
                    }
                    errorMessage={errorMessage}
                    placeholder="Enter or choose value"
                    options={optionsArray}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.value || '')}
                    renderOption={renderOption}
                    filterOptions={filterWithSuggest}
                    forcePopupIcon={!!optionsArray.length}
                  />
                )}
              />
            ) : (
              <>
                <Controller
                  name={`${field}.selectedValue.${source}`}
                  control={control}
                  render={({ field: { value, onChange } }) => <input style={{ display: 'none' }} value={value.value} onChange={onChange} />}
                />
                <Tooltip placement="bottom-start" title={value}>
                  <Typography
                    color={`text.${!isSelector && !value ? 'disabled' : 'primary'}`}
                    style={{ height: '40px', display: 'inline-flex', alignItems: 'center' }}
                  >
                    {value || 'Not Specified'}
                  </Typography>
                </Tooltip>
              </>
            )
          }
        />
      </SidepanelWide>
      <SidepanelNarrow>
        {isSelector ? (
          <FormControlLabel
            classes={{ root: classes.formLabel }}
            value={source}
            control={<Radio />}
            label={
              <Controller
                name={`${field}.selectedValue.${source}`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Combobox
                    value={value}
                    autoHighlight
                    freeSolo
                    clearOnBlur
                    onChange={(e: SyntheticEvent<Element, Event>, newValue: string | (SalesforceData & { label?: string }) | null) =>
                      handleChange(newValue, onChange)
                    }
                    errorMessage={errorMessage}
                    placeholder="Enter or choose value"
                    options={optionsArray}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.value || '')}
                    renderOption={renderOption}
                    filterOptions={filterWithSuggest}
                    forcePopupIcon={!!optionsArray.length}
                  />
                )}
              />
            }
          />
        ) : (
          <Box marginBottom={-2}>
            <FormControlLabel
              value={source}
              control={<Radio />}
              disabled={!value}
              label={
                <>
                  <Controller
                    name={`${field}.selectedValue.${source}`}
                    control={control}
                    render={({ field: { value, onChange } }) => <input style={{ display: 'none' }} value={value.value} onChange={onChange} />}
                  />
                  <Tooltip placement="bottom-start" title={value}>
                    <Typography color={`text.${!value ? 'disabled' : 'primary'}`}>{value || 'Not Specified'}</Typography>
                  </Tooltip>
                </>
              }
              classes={{ root: classes.formLabel }}
            />
          </Box>
        )}
        <Typography
          classes={{ root: classes.time, body1: isSelector ? '' : classes.secondaryText }}
          color={`text.${!value ? 'disabled' : 'secondary'}`}
        >
          {source}
          {!!lastEvidenceDate && `, ${formatCreationDate(lastEvidenceDate)}`}
        </Typography>
      </SidepanelNarrow>
    </Grid>
  )
}

export default Option
