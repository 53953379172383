import { useState, ChangeEvent, useEffect } from 'react'

import { Box } from '@mui/material'

import Checkbox from '_shared/forms/Checkbox'
import TextField from '_shared/forms/TextField'
import { HelpIconPopover } from '_shared/popovers/IconPopover'
import Typography from '_shared/Typography'

import Settings, { useStyles } from '_core/components/settings'

import useSaveSettings, { SaveAction } from '_core/hooks/useSaveSettings'

type SettingsData = {
  prioritizeHistoicalScan: boolean
  blockNonBizPersonPush: boolean
  ensurePushPersonNames: boolean
  minP2pScore: number | string
  pushOnlyIfRelevant: boolean
  relevantAnyMsgDaysAgo: number | string
  relevantInteractionDaysAgo: number | string
}

type DataProcessingSettingsProps = {
  loading: boolean
  items?: SettingsData
}

const DataProcessingSettings = (props: DataProcessingSettingsProps) => {
  const [settingsData, setSettingsData] = useState<SettingsData>()
  const {
    classes: { field, filtersContent, subItem, tiny, subItemWrapper, icon }
  } = useStyles()

  useEffect(() => {
    if (!settingsData && props.items) {
      setSettingsData(props.items)
    }
  }, [props.items, settingsData])

  const { isSaving, save: saveSettings } = useSaveSettings()

  const save = (action: SaveAction) => {
    saveSettings('/adminsettings/dataprocessing', settingsData, action, 'Admin data processing settings')
  }

  const handlePrioritizeHistoicalScan = (e: ChangeEvent<HTMLInputElement>) => {
    setSettingsData((prevState) => (prevState ? { ...prevState, prioritizeHistoicalScan: e.target.checked } : prevState))
  }

  const handleSharePersonalContacts = (e: ChangeEvent<HTMLInputElement>) => {
    setSettingsData((prevState) => (prevState ? { ...prevState, blockNonBizPersonPush: e.target.checked } : prevState))
  }

  const handleIsFiltersAppliedToggle = (e: ChangeEvent<HTMLInputElement>) => {
    setSettingsData((prevState) => (prevState ? { ...prevState, pushOnlyIfRelevant: e.target.checked } : prevState))
  }

  const handleMinScoreChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSettingsData((prevState) => (prevState ? { ...prevState, minP2pScore: e.target.value ? +e.target.value : '' } : prevState))
  }

  const handleNoReciprocityDurationChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSettingsData((prevState) => (prevState ? { ...prevState, relevantAnyMsgDaysAgo: e.target.value ? +e.target.value : '' } : prevState))
  }

  const handleRelationshipDurationChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSettingsData((prevState) => (prevState ? { ...prevState, relevantInteractionDaysAgo: e.target.value ? +e.target.value : '' } : prevState))
  }

  return (
    <Settings isSaving={isSaving} save={save} initialLoading={props.loading}>
      {settingsData ? (
        <Box width="100%">
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={settingsData.prioritizeHistoicalScan}
              name="prioritize_historical_scan"
              onChange={handlePrioritizeHistoicalScan}
              label="Prioritize historical scan of mailbox data"
            />
            <HelpIconPopover
              title="Check this box to have the system give priority
                to indexing historical mailbox data. This does not mean newly
                incoming mailbox data will not be indexed, rather slighly more
                batches of historical data will be indexed in a given unit of time"
              className={icon}
            />
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={settingsData.blockNonBizPersonPush}
              name="share_personal_contacts_enabled"
              onChange={handleSharePersonalContacts}
              label="Don't process contacts with only personal email addresses"
            />
            <HelpIconPopover
              title="If a contact has personal email addresses,
                but no work related email addresses, they should
                not be processed and surfaced in the app."
              className={icon}
            />
          </Box>
          <Box display="flex" alignItems="center" mb="-9px">
            <Checkbox
              checked={settingsData.pushOnlyIfRelevant}
              name="filters_enabled"
              onChange={handleIsFiltersAppliedToggle}
              label="Apply filters to what contributors share with the team"
            />
            <HelpIconPopover
              title="Data gathered for a contributor is analyzed in an area specific to that
                contributor and then shared with the team area to create a composite
                across all contributors. Along with the preferences of the contributor,
                these settings control what is shared, with an eye toward reducing noise
                at the team composite level."
              className={icon}
            />
          </Box>
          <Box marginLeft={4} className={filtersContent}>
            <Box className={subItemWrapper}>
              <Box className={subItem}>
                <Typography color="text.secondary">Stale relationship (in days)</Typography>
                <TextField
                  size="small"
                  disabled={!settingsData.pushOnlyIfRelevant}
                  inputProps={{
                    className: tiny
                  }}
                  classes={{ root: field }}
                  type="number"
                  value={settingsData.relevantInteractionDaysAgo}
                  onChange={handleRelationshipDurationChange}
                />
                <HelpIconPopover
                  title="Stale relationship (in days) - the number of days of no interactions before a person is removed from the team view.
                    Imagine you've had some back-and-forth interactions with a person, but the last of those was over 3 years ago. After that time
                    of no interaction, that person will be removed from view."
                  className={icon}
                />
              </Box>
            </Box>
            <Box className={subItemWrapper}>
              <Box className={subItem}>
                <Typography color="text.secondary">No reciprocity (in days)</Typography>
                <TextField
                  size="small"
                  disabled={!settingsData.pushOnlyIfRelevant}
                  classes={{ root: field }}
                  inputProps={{
                    className: tiny
                  }}
                  type="number"
                  value={settingsData.relevantAnyMsgDaysAgo}
                  onChange={handleNoReciprocityDurationChange}
                />
                <HelpIconPopover
                  title="No reciprocity (in days) – the number of days after which a person with whom there has been no reciprocity
                    (i.e., either they send an email that was not replied to, or a contributor send them an email and they did not respond to it),
                    is removed from the team level view. Imagine a person sending a contributor a marketing or sales email that the contributor has
                    never responded to. If no subsequent interaction has happened, that person will be removed from the team view after one year."
                  className={icon}
                />
              </Box>
            </Box>
            <Box className={subItemWrapper}>
              <Box className={subItem}>
                <Typography color="text.secondary">Minimum score setting</Typography>
                <TextField
                  size="small"
                  disabled={!settingsData?.pushOnlyIfRelevant}
                  classes={{ root: field }}
                  inputProps={{
                    className: tiny
                  }}
                  type="number"
                  value={settingsData?.minP2pScore}
                  onChange={handleMinScoreChange}
                />
                <HelpIconPopover
                  title="Minimum score setting - the minimum scored relationship that should be pushed to the team composite."
                  className={icon}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <p>Data processing settings were not found</p>
      )}
    </Settings>
  )
}

export default DataProcessingSettings
