import React, { SyntheticEvent, useState } from 'react'

import { IconName, IconPrefix } from '@fortawesome/fontawesome-common-types'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Fab } from '@mui/material'
import { Link } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import FloatingButton from '_shared/buttons/FloatingButton'
import Typography from '_shared/Typography'

import SidepanelLink from '_core/components/SidepanelLink'

const useStyles = makeStyles<void, 'action' | 'cover'>()((theme, _params, classes) => ({
  open: {
    [`& .${classes.action}`]: {
      right: 0,
      opacity: 1
    },
    [`& .${classes.cover}`]: {
      height: '100%'
    }
  },
  cover: {
    position: 'fixed',
    width: '100%',
    top: 0,
    left: 0,
    zIndex: 1100,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  actions: {
    position: 'absolute',
    right: 30,
    bottom: 92,
    maxWidth: 'calc(100% - 60px)'
  },
  action: {
    position: 'relative',
    opacity: 0,
    right: '-100%',
    marginTop: theme.spacing(1),
    '&:hover svg': {
      color: theme.palette.secondary.main
    }
  },
  label: {
    color: theme.palette.background.light,
    marginRight: theme.spacing(1),
    maxWidth: '100%'
  },
  fab: {
    minWidth: '56px'
  }
}))

export interface IMenuAction {
  label: string
  icon: IconProp
  link: string
  sidepanel?: SidepanelType
}

const Action = ({ label, icon }: Pick<IMenuAction, 'label' | 'icon'>) => {
  const { classes } = useStyles()
  return (
    <Box display="flex" justifyContent="end" alignItems="center">
      <Typography bold className={classes.label} noWrap>
        {label}
      </Typography>
      <Fab color="default" classes={{ root: classes.fab }}>
        <FontAwesomeIcon icon={icon} size="lg" />
      </Fab>
    </Box>
  )
}

const FloatingMenuButton = ({ actions, menuIcon }: { actions: IMenuAction[]; menuIcon?: [IconPrefix, IconName] }) => {
  const [open, setOpen] = useState(false)
  const { classes, cx } = useStyles()

  const toggleOpen = () => setOpen((prevState) => !prevState)

  const handleToggleOpen = (e: SyntheticEvent) => {
    e.stopPropagation()
    toggleOpen()
  }

  const actionButtons = actions.map((action, index) => {
    const delay = 100 * (actions.length - index)

    return (
      <Box className={classes.action} key={action.label} style={{ transition: 'right ' + delay + 'ms' + ', opacity ' + delay * 2.5 + 'ms' }}>
        {action.link && action?.sidepanel ? (
          <SidepanelLink linkProps={{ to: action.link, style: { width: '100%' } }} sidepanel={action.sidepanel} onClick={toggleOpen}>
            <Action label={action.label} icon={action.icon} />
          </SidepanelLink>
        ) : (
          <Link to={action.link} onClick={toggleOpen}>
            <Action label={action.label} icon={action.icon} />
          </Link>
        )}
      </Box>
    )
  })

  return (
    <Box className={cx({ [classes.open]: open })}>
      <Box className={classes.cover} onClick={toggleOpen}>
        <Box className={classes.actions}>{actionButtons}</Box>
        <FloatingButton onClick={handleToggleOpen}>
          <FontAwesomeIcon icon={open ? ['far', 'times'] : menuIcon ? menuIcon : ['far', 'ellipsis-v']} size="lg" />
        </FloatingButton>
      </Box>
    </Box>
  )
}

export default FloatingMenuButton
