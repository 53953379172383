import React, { useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/material'
import { useSnackbar } from 'notistack'
import { makeStyles } from 'tss-react/mui'

import IconPopover from '_shared/popovers/IconPopover'
import Typography from '_shared/Typography'

import Empty from '_core/components/Empty'
import { Wide } from '_core/components/layout'

import useSidepanelPayloads from '_core/hooks/useSidepanelPayloads'

import { formatDate } from 'utils/Utils'

import ContactTableHeader from './components/ContactTableHeader'
import DotCard from './components/DotCard'
import { DotTable, DotTableRow, RightAlignedTableCell } from './components/DotTable'
import { FileSourceTitle } from './components/FileSourceTitle'
import { TableHeader } from './components/TableHeader'

const useStyles = makeStyles()(() => ({
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 24
  }
}))

const DataSources = ({
  data,
  reload,
  initOpen,
  loading
}: {
  data?: IDataSources
  reload: (val: boolean) => void
  initOpen: boolean
  loading: boolean
}) => {
  const { classes } = useStyles()
  const { payloads } = useSidepanelPayloads()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    if (payloads && payloads.action === 'RELOAD_LIST' && payloads.value.includes('data-sources')) {
      enqueueSnackbar("We're processing your upload, this page will refresh shortly", {
        variant: 'default',
        autoHideDuration: 12000,
        onClose: () => {
          closeSnackbar()
          reload(true)
        }
      })
    }
  }, [payloads])

  return (
    <>
      <Wide>
        <div className={classes.heading}>
          <Typography variant="h1">Data Sources</Typography>
        </div>
      </Wide>
      <DotCard>
        <Box>
          <TableHeader title="Mailboxes" />
          {!data?.emailSources?.length && <Empty title="No mailboxes found" subTitle="We cant find any item for this section" />}
          {!!data?.emailSources?.length && (
            <DotTable
              rowComponent={DotTableRow}
              columns={dataSourceColumns()}
              data={data!.emailSources}
              headerComponent={<ContactTableHeader icon="/image/data-source/icons8-office-365.png" name={data?.name} email={data?.email} />}
            />
          )}
        </Box>
      </DotCard>
      <br />
      <DotCard>
        <Box paddingTop={5}>
          <FileSourceTitle initOpen={initOpen} loading={loading} />
          {!!data?.fileSources?.length === false && <Empty title="No file sources found" />}

          {!!data?.fileSources?.length && (
            <DotTable
              rowComponent={DotTableRow}
              columns={fileSourceColumns()}
              data={data!.fileSources}
              headerComponent={
                <ContactTableHeader
                  iconComponent={<FontAwesomeIcon icon={['fab', 'linkedin']} color="#0077B7" size="lg" />}
                  name={data?.name}
                  email={data?.email}
                />
              }
            />
          )}
        </Box>
      </DotCard>
    </>
  )
}

export default DataSources

export const extractDataSourceStats = (response: any) => {
  if (!response) {
    return undefined
  }

  const data = response

  function buildRow(data: any, { category, status, rangeStart, rangeEnd, count }: EmailSourceExtractionKeys): IEmailSourceStat | undefined {
    /*
        @TODO Debug code, remove when feature had done
    return {
        category: 'Email' + Math.random(),
        status: 'Done',
        range: '123',
        count: '123'
    };*/
    if (data[status] == null) {
      return undefined
    }

    return {
      category: category,
      status: data[status] === true ? 'Done' : 'In progress',
      range: [formatDate(data[rangeStart]), formatDate(data[rangeEnd])].join(' - '),
      count: data[count]
    }
  }

  const dirtyEmailData = [
    buildRow(data, {
      category: 'Email messages',
      status: 'retrievedAllEmailMessages',
      rangeStart: 'emailMessageMinTime',
      rangeEnd: 'emailMessageMaxTime',
      count: 'emailMessageCount'
    }),
    buildRow(data, {
      category: 'Calendar events',
      status: 'retrievedAllCalendarEvents',
      rangeStart: 'calendarEventMinTime',
      rangeEnd: 'calendarEventMaxTime',
      count: 'calendarEventCount'
    }),
    buildRow(data, {
      category: 'Contact cards',
      status: 'retrievedAllContactCards',
      rangeStart: 'contactCardMinTime',
      rangeEnd: 'contactCardMaxTime',
      count: 'contactCardCount'
    })
  ]

  const dirtyFileData = (data.fileSources || []).map((file: any) => {
    return {
      category: file.fileName,
      status:
        file.indexingComplete === true ? (
          <Box display="flex" alignItems="center">
            <Box mr={1}>Upload complete</Box>
            <IconPopover
              icon={['far', 'info-circle']}
              size="sm"
              title="Your LinkedIn connections will show in the application once analytics run (usually within an hour)"
            />
          </Box>
        ) : (
          'In progress'
        ),
      uploadTime: formatDate(file.uploadTimeUtc),
      count: file.count
    } as IFileSourceStat
  })

  return {
    emailSources: dirtyEmailData.filter((it: any) => it !== undefined),
    fileSources: dirtyFileData as IFileSourceStat[],
    email: data.email,
    name: data.name
  } as IDataSources
}

export interface IDataSources {
  emailSources: IEmailSourceStat[]
  fileSources: IFileSourceStat[]
  email: string
  name: string
}

interface EmailSourceExtractionKeys {
  category: string
  status: string
  rangeStart: string
  rangeEnd: string
  count: string
}
interface IEmailSourceStat {
  category: string
  status: string
  range: string
  count: string
}
interface IFileSourceStat {
  category: string
  status: string
  uploadTime: string
  count: string
}

const dataSourceColumns = () => [
  {
    id: 'category',
    label: 'Category'
  },
  {
    id: 'status',
    label: 'Status'
  },
  {
    id: 'range',
    label: 'Range'
  },
  {
    id: 'count',
    label: 'Count',
    component: RightAlignedTableCell
  }
]

const fileSourceColumns = () => [
  {
    id: 'category',
    label: 'File name'
  },
  {
    id: 'status',
    label: 'Status'
  },
  {
    id: 'uploadTime',
    label: 'Date uploaded'
  },
  {
    id: 'count',
    label: 'Count',
    component: RightAlignedTableCell
  }
]
