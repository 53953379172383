import React, { ComponentProps, ReactElement, ReactNode } from 'react'

import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { AvatarWrapper } from '_shared/Avatar'
import Skeleton from '_shared/Skeleton'
import Tooltip from '_shared/Tooltip'
import Typography from '_shared/Typography'

import { AddTagTriggerEl, ExtraTagsPopover, InternalTagPopover, ShowAllTagsLink } from '_core/components/InternalTag'
import { Wide, Narrow, useWide } from '_core/components/layout'
import SidepanelLink from '_core/components/SidepanelLink'
import TagsGroup from '_core/components/TagsGroup'

import { groupTags } from '_core/hooks/useTagsManager'

import { ellipsis } from 'AppTheme'

export const useStyles = makeStyles<{ flip?: boolean } | void>()((theme, props) => ({
  name: {
    fontSize: 18,
    marginBottom: 0,
    wordBreak: 'break-word'
  },
  nameWrapper: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'auto auto 1fr'
  },
  byline: {
    width: '100%'
  },
  actions: {
    width: '100%',
    minWidth: '70px',
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'repeat(auto-fit, minmax(0px, max-content))',
    gridTemplateRows: '1fr',
    marginTop: theme.spacing(2),
    marginBottom: 0
  },
  action: {
    display: 'flex',
    marginRight: '22px',
    '& > a, & > p': {
      ...ellipsis
    },
    '&:last-of-type': {
      marginRight: 0
    }
  },
  wrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: props?.flip ? 'row-reverse' : 'row',
    margin: '0px auto',
    maxWidth: '90%',
    [theme.breakpoints.up('phone')]: {
      maxWidth: '80%'
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '100%',
      justifyContent: 'flex-start',
      position: 'relative',
      minWidth: '32%',
      '&::after': {
        content: '""',
        position: 'absolute',
        width: '0.5px',
        height: '100%',
        background: '#ECEEF0',
        left: props?.flip ? 'inherit' : 100,
        right: props?.flip ? 100 : 'inherit'
      }
    }
  },
  container: {
    marginLeft: props?.flip ? 0 : theme.spacing(3),
    marginRight: props?.flip ? theme.spacing(3) : 0,
    paddingTop: theme.spacing(2),
    maxWidth: `calc(100% - 80px - ${theme.spacing(3)})`,
    '& > p': {
      paddingTop: '4px'
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '60%',
      marginLeft: props?.flip ? 0 : theme.spacing(5)
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: 0
    }
  },
  avatar__wrapper: {
    width: '80px',
    display: 'flex',
    justifyContent: props?.flip ? 'flex-end' : 'flex-start',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: '100px',
      marginTop: 0
    }
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '80%',
    margin: '16px auto 0',
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(1),
      marginTop: 0,
      position: 'absolute',
      right: props?.flip ? 'auto' : -8,
      left: props?.flip ? -8 : 'auto',
      bottom: 0
    },
    '& .MuiButton-root': {
      minWidth: 'auto',
      [theme.breakpoints.up('tablet')]: {
        marginRight: theme.spacing(1)
      }
    }
  }
}))

export const ProfileContainer = (
  props: { children: ReactNode; className: string } & Pick<
    ProfileSummaryProps,
    'title' | 'loading' | 'budge' | 'editTagsLink' | 'showAllTagsLink' | 'profileLink' | 'score' | 'userKey' | 'url' | 'tags' | 'flip'
  >
) => {
  const wide = useWide()
  const { flip, title, loading, budge, editTagsLink, showAllTagsLink, profileLink, score, userKey, url, children, className } = props
  const { classes } = useStyles({ flip })

  return (
    <>
      <Box className={classes.avatar__wrapper}>
        <SidepanelLink linkProps={{ to: title && profileLink ? profileLink : '' }}>
          <AvatarWrapper name={loading ? '' : title} score={score} userKey={userKey} size="lg" mode="dark" logoUrl={url} hideName />
        </SidepanelLink>
      </Box>

      <Box className={className}>
        <Box className={classes.nameWrapper}>
          {(loading || title) && (
            <Skeleton condition={!title} width="100%">
              <Tooltip title={title || ''} placement="bottom-start" disabled={wide}>
                <Typography variant="h4" className={classes.name} noWrap semiBold>
                  {title || 'Placeholder Name'}
                </Typography>
              </Tooltip>
            </Skeleton>
          )}
          {!loading && budge}
          {!loading && editTagsLink && showAllTagsLink && (
            <Wide>
              <Box ml={1.5}>
                <TagsGroup<ComponentProps<typeof InternalTagPopover>['tagData']>
                  items={props.tags}
                  tagComponent={InternalTagPopover}
                  renderShowAll={({ extraTagsAmount }: { extraTagsAmount: number }) => (
                    <ExtraTagsPopover
                      triggerElement={<ShowAllTagsLink extraTagsAmount={extraTagsAmount} link={showAllTagsLink} />}
                      items={props.tags ? groupTags(props.tags) : props.tags}
                    />
                  )}
                  addTagTriggerEl={<AddTagTriggerEl link={editTagsLink} hasAny={!!props.tags?.length} />}
                />
              </Box>
            </Wide>
          )}
        </Box>
        {!loading && editTagsLink && showAllTagsLink && (
          <Narrow>
            <Box mt={1}>
              <TagsGroup<ComponentProps<typeof InternalTagPopover>['tagData']>
                items={props.tags}
                tagComponent={InternalTagPopover}
                renderShowAll={({ extraTagsAmount }: { extraTagsAmount: number }) => (
                  <ExtraTagsPopover
                    triggerElement={<ShowAllTagsLink extraTagsAmount={extraTagsAmount} link={showAllTagsLink} />}
                    items={props.tags ? groupTags(props.tags) : props.tags}
                  />
                )}
                addTagTriggerEl={<AddTagTriggerEl link={editTagsLink} hasAny={!!props.tags?.length} />}
              />
            </Box>
          </Narrow>
        )}
        {children}
      </Box>
    </>
  )
}

const DefaultSummary = ({ loading, byline, byline2 }: { loading: boolean; byline?: string | JSX.Element; byline2?: JSX.Element }) => {
  const { classes } = useStyles()

  return (
    <>
      {(loading || byline) && (
        <Skeleton condition={loading} width="80%" height={20}>
          <Tooltip title={typeof byline === 'string' ? byline : ''} placement="bottom-start">
            <Typography variant="body1" color="text.secondary" noWrap className={classes.byline}>
              {byline}
            </Typography>
          </Tooltip>
        </Skeleton>
      )}

      {(loading || byline2) && (
        <Skeleton condition={loading} width="70%" height={20}>
          {byline2}
        </Skeleton>
      )}
    </>
  )
}

const DefaultActions = ({ actions }: { actions: JSX.Element[] }) => {
  const { classes } = useStyles()

  return (
    <Box className={classes.actions}>
      {actions.map((action, index) => (
        <Box className={classes.action} key={index}>
          {action}
        </Box>
      ))}
    </Box>
  )
}

const DefaultProfileSummaryLayout = ({ children, buttons, flip }: { children: ReactNode; buttons?: ReactElement; flip: boolean }) => {
  const { classes } = useStyles({ flip })

  return (
    <>
      <Box className={classes.wrapper}>
        {children}
        <Wide>
          <Box className={classes.buttons}>{buttons}</Box>
        </Wide>
      </Box>
      {buttons && (
        <Narrow>
          <Box className={classes.buttons} alignItems="center">
            {buttons}
          </Box>
        </Narrow>
      )}
    </>
  )
}

const DefaultProfileSummary = ({
  title,
  score,
  userKey,
  url,
  budge,
  loading,
  editTagsLink,
  showAllTagsLink,
  profileLink,
  tags,
  byline,
  byline2,
  buttons,
  actions,
  flip
}: ProfileSummaryProps) => {
  const { classes } = useStyles({ flip })

  return (
    <DefaultProfileSummaryLayout buttons={buttons} flip={!!flip}>
      <ProfileContainer
        className={classes.container}
        title={title}
        loading={loading}
        budge={budge}
        editTagsLink={editTagsLink}
        showAllTagsLink={showAllTagsLink}
        score={score}
        userKey={userKey}
        url={url}
        tags={tags}
        flip={!!flip}
        profileLink={profileLink}
      >
        <DefaultSummary byline={byline} byline2={byline2} loading={loading} />
        <DefaultActions actions={actions} />
      </ProfileContainer>
    </DefaultProfileSummaryLayout>
  )
}

export default DefaultProfileSummary
