import { ComponentProps, ReactElement } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Chip, ChipProps, useTheme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { Button, IconButton } from '_shared/buttons'
import Popover from '_shared/popovers/Popover'
import Skeleton from '_shared/Skeleton'
import Typography from '_shared/Typography'

import SidepanelLink from '_core/components/SidepanelLink'
import { TagsGroupedList } from '_core/components/TagsList'

const useStyles = makeStyles()((theme) => ({
  root: {
    maxWidth: '100%'
  },
  popoverContent: {
    minWidth: 200
  },
  byLineIcon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
    fontSize: 16,
    width: '16px'
  },
  label: {
    fontSize: 14,
    lineHeight: '22px',
    paddingTop: 2,
    paddingBottom: 2
  },
  cropped: {
    maxWidth: 38,
    [theme.breakpoints.up('phone')]: {
      maxWidth: 60
    }
  },
  filled: {
    borderRadius: theme.spacing(0.5)
  }
  // backgroundColor: theme.palette.secondary.light
}))

export const AddTagTriggerEl = ({ link, sidepanel, hasAny }: { link: string; sidepanel?: SidepanelType; hasAny: boolean }) => {
  return (
    <Box className="hoveredRowActions">
      {hasAny && (
        <SidepanelLink linkProps={{ to: link }} sidepanel={sidepanel}>
          <IconButton icon={['far', 'plus']} hint="Add another tag" size="small" disablePY />
        </SidepanelLink>
      )}
      {!hasAny && (
        <SidepanelLink linkProps={{ to: link }} sidepanel>
          <Button
            startIcon={<FontAwesomeIcon icon={['far', 'plus']} style={{ fontSize: 11 }} />}
            size="small"
            style={{ paddingTop: 0, paddingBottom: 0 }}
          >
            Tag
          </Button>
        </SidepanelLink>
      )}
    </Box>
  )
}

export const ShowAllTagsLink = ({ link, sidepanel, extraTagsAmount }: { link: string; sidepanel?: SidepanelType; extraTagsAmount: number }) => {
  return (
    <SidepanelLink linkProps={{ to: link }} sidepanel={sidepanel}>
      <InternalTag label={`+ ${extraTagsAmount}`} clickable />
    </SidepanelLink>
  )
}

export const ExtraTagsPopover = (
  props: Pick<ComponentProps<typeof TagsGroupedList>, 'items'> & Pick<ComponentProps<typeof Popover>, 'triggerElement'>
) => {
  const { classes } = useStyles()
  const { triggerElement, items } = props

  return (
    <Popover placement="bottom-start" triggerElement={triggerElement}>
      <Box className={classes.popoverContent}>
        <TagsGroupedList items={items} />
      </Box>
    </Popover>
  )
}

export const TagCategoryHeader = ({ categoryName }: { categoryName: string | ReactElement }) => {
  const { classes } = useStyles()

  return (
    <Box display="flex" alignItems="center" mb={0.5}>
      <FontAwesomeIcon icon={['fas', 'tag']} className={classes.byLineIcon} />
      <Skeleton condition={!categoryName} width={80} height={22}>
        <Typography color="text.secondary" variant="body1" semiBold>
          {categoryName}
        </Typography>
      </Skeleton>
    </Box>
  )
}

export const InternalTagPopover = ({ tagData: { categoryName, tagName } }: { tagData: UngroupedTag }) => {
  const { classes } = useStyles()

  return (
    <Popover key={tagName} triggerElement={<InternalTag label={tagName} cropped />}>
      <Box className={classes.popoverContent}>
        <TagCategoryHeader categoryName={categoryName} />
        <Box display="flex" mt={1} flexWrap="wrap">
          <InternalTag label={tagName} />
        </Box>
      </Box>
    </Popover>
  )
}

const InternalTag = ({ label, cropped, onClick, clickable, sx }: ChipProps & { cropped?: boolean }) => {
  const { classes, cx } = useStyles()
  const theme = useTheme()

  return (
    <Skeleton condition={!label} width="60px" height="23px" variant="rounded">
      <Chip
        onClick={onClick}
        variant="filled"
        size="small"
        label={label}
        clickable={clickable}
        classes={{ root: cx(classes.root, classes.filled), label: cx(classes.label, { [classes.cropped]: cropped }) }}
        sx={sx || { backgroundColor: theme.palette.secondary.light }}
      />
    </Skeleton>
  )
}

export default InternalTag
