import React, { useContext, useEffect, useMemo } from 'react'

import { useParams } from 'react-router-dom'

import Page from '_shared/Page'

import AddCollaborators from '_core/components/introductions/AddCollaborators'
import { Narrow } from '_core/components/layout'
import Topbar from '_core/components/Topbar'

import DynamicEntity from '_core/DynamicEntity'

import { LayoutContext } from 'Layout/LayoutContextProvider'

const AddRequestCollaboratorsPage = () => {
  const { id } = useParams<{ id: string }>()
  const { setSubHeader } = useContext(LayoutContext)

  useEffect(() => {
    setSubHeader('Add collaborators')
    return () => setSubHeader('')
  }, [setSubHeader])

  const memoUrls = useMemo(() => [{ key: 'request', url: `/prospecting/plandetail?planUid=${id}` }], [id])

  return (
    <Page>
      <Narrow>
        <Topbar nativeBack />
        <DynamicEntity urls={memoUrls} id="request_collaborators" component={AddCollaborators} keepMounted />
      </Narrow>
    </Page>
  )
}

export default AddRequestCollaboratorsPage
