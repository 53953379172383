import { useState, useContext, useEffect } from 'react'

import { CircularProgress } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'

import { TeamContext } from '_core/context/TeamContext'

import CreateTeam from '_core/components/CreateTeam'

import useAdmin from '_core/hooks/useAdmin'

import { LayoutContext } from 'Layout/LayoutContextProvider'

import Paths from 'Paths'

const useStyles = makeStyles()((theme) => ({
  placeholder: {
    width: '100%',
    height: '75vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
      cursor: 'pointer'
    }
  },
  text: {
    textAlign: 'center',
    padding: `0 ${theme.spacing(2)}`
  }
}))

const Welcome = () => {
  const { classes } = useStyles()
  const history = useHistory()
  const {
    teamContextValue: { teamNumber }
  } = useContext(TeamContext)
  const { setMobileHeader } = useContext(LayoutContext)
  const admin = useAdmin()

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setMobileHeader('Welcome')
  }, [setMobileHeader])

  useEffect(() => {
    if (teamNumber > 0 && !loading) {
      history.replace(Paths._home)
    }
  }, [history, teamNumber, loading])

  return (
    <div className={classes.placeholder}>
      {loading && <CircularProgress color="secondary" />}
      {!loading && (
        <>
          <img src="/image/team/no-team-placeholder.svg" alt="no teams" />
          <p className={classes.text}>You do not have access to any team on DotAlign</p>
          {admin && <CreateTeam setLoading={setLoading} />}
        </>
      )}
    </div>
  )
}

export default Welcome
